import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NorightsComponent} from './modules/auth-config/norights/norights.component';
import {AuthGuard} from "./modules/auth/guards/auth.guard";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {ServerErrorPageComponent} from './components/server-error-page/server-error-page.component';
import {SystemRedirectComponent} from "./modules/auth/components/system-redirect/systemRedirect.component";
import {
  EsiaTokenAndScopeComponent
} from "./modules/auth/components/esia-token-and-scope/esia-token-and-scope.component";
import {LogoutAllClientsComponent} from "./modules/auth/components/logout-all-clients/logout-all-clients.component";
import {LogoutComponent} from "./modules/auth/components/logout/logout.component";
import {ProfileMainComponent} from "./components/profileMain/profileMain.component";
import { ConfirmSuccessInfoComponent } from './components/confirm-success-info/confirm-success-info.component';
import { EsiaErrorPageComponent } from './components/esia-error-page/esia-error-page.component';
import { ActivateAccountErrorComponent } from './modules/auth/components/activate-account-error/activate-account-error.component';
import { EmailConfirmSuccessComponent } from './modules/auth/components/modals/email-confirm-success/email-confirm-success.component';

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  {path: 'profile', component: ProfileMainComponent, canActivate: [AuthGuard]},
  {path: 'systemRedirect', component: SystemRedirectComponent, canActivate: [AuthGuard]},
  {path: 'esia-token-and-scope', component: EsiaTokenAndScopeComponent},
  {path: 'logout-all', component: LogoutAllClientsComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'norights', component: NorightsComponent},
  {path: 'confirm-success-info', component: ConfirmSuccessInfoComponent},
  {path: 'email-confirm', component: EmailConfirmSuccessComponent},
  {path: 'activate-account-error', component: ActivateAccountErrorComponent},
  {path: 'server-error', component: ServerErrorPageComponent},
  {path: 'esia-error', component: EsiaErrorPageComponent},
  {path: '', redirectTo: '/profile', pathMatch: 'full'},
  {path: 'not-found', pathMatch: 'full', component: PageNotFoundComponent},
  {path: '**', pathMatch: 'full', component: ServerErrorPageComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
