import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SharedService } from "../../core/services/shared.service";
import moment from "moment";
import { accountFileType } from 'src/app/core/models/card/accountFileType';
import IFileMeta from 'src/app/core/models/card/IFileMeta';

@Component({
  selector: 'app-add-doc-dialog',
  templateUrl: './add-doc-dialog.component.html',
  styleUrls: ['./add-doc-dialog.component.scss']
})
export class AddDocDialogComponent implements OnInit, OnDestroy {
  private subs:Subscription = new Subscription();
  form: UntypedFormGroup;
  docTypes: accountFileType[] = [];
  get docType(): accountFileType {
    const docType = this.form.value.docType;
    return {
      id: docType,
      name: this.docTypes.find(x => x.id === docType)?.name
    };
  }
  isDocTypeSelected = false;
  fileMeta: any = null;
  fileValidationErrors: string[] = [];
  canAddFile = false;
  file: File | null = null;
  private validFileExtensions = ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png', 'zip'];

  constructor(
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private host: ElementRef<HTMLInputElement>,
    private dialogRef: MatDialogRef<AddDocDialogComponent>
  ) {
    this.sharedService.getDocTypes().subscribe(docTypes => this.docTypes = docTypes);
    this.form = fb.group({
      docType: null
    });
    const docTypeSub = this.form.get('docType')!.valueChanges.subscribe(_ => {
      if (!this.isDocTypeSelected) {
        this.isDocTypeSelected = true;
      }
    });
    this.subs.add(docTypeSub);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onAddClick(uploadFileElement: HTMLInputElement) {
    uploadFileElement.click();
  }

  onFileChange(evt: any) {
    if (evt.target?.files.length) {
      this.clearErrors();
      const [f] = evt.target.files;

      this.validateFile(f);
      this.file = f;

      if (this.fileValidationErrors.length) {
        this.canAddFile = false;
        return;
      }

      this.canAddFile = true;
    }
  }

  onRemoveFileClick() {
    this.file = null;
    this.canAddFile = false;
    this.clearErrors();
  }

  private validateFile(file: File) {
    this.validateFileSize(file.size);
    this.validateFileExtension(file.name);
  }

  private clearErrors() {
    if (this.fileValidationErrors.length) {
      this.fileValidationErrors = [];
    }
  }

  private validateFileSize(bytes: number) {
    const maxFileSize = 1024 * 1024 * 5;
    if (bytes <= 0) {
      this.fileValidationErrors.push('Недопустимо прикрепить пустой файл');
    }
    if (bytes > maxFileSize) {
      this.fileValidationErrors.push('Превышен допустимый размер файла');
    }
  }

  private validateFileExtension(fileName: string) {
    const items = fileName.split('.');
    const len = items.length
    if (len > 1) {
      const extension = items[len - 1].toLowerCase();
      if (!this.validFileExtensions.includes(extension.toLowerCase())) {
        this.fileValidationErrors.push(`Недопустимое расширение файла: .${extension}`);
      }
    } else {
      this.fileValidationErrors.push('Недопустимое расширение файла');
    }
  }

  onAddFileClick() {
    const f = this.file;
    const fileMeta: IFileMeta = {
      id: -1,
      accountId: -1,
      docType: this.docType,
      fileName: f!.name,
      size: f!.size,
      uploadDate: moment().toISOString(),
      actions: null, isUploaded: false
    };
    this.dialogRef.close({ fileMeta, file: f });
  }
}
