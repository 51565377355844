import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { AccountTypeEnum } from "../shared/enums/account-type.enum";
import { HttpClient } from "@angular/common/http";
import { OrgTypeEnum } from '../shared/enums/type-of-subject.enum';
import { StrangersOwnerType, StrangersType } from '../shared/enums/strangersType';
import { accountFileType } from '../models/card/accountFileType';
import { OrganizationType } from '../shared/enums/organizationType';
import IStrangersType from 'src/app/modules/auth/models/interfaces/IStrangersType';
import ISharedClientInfoResponse from 'src/app/modules/auth/models/interfaces/ISharedClientInfoResponse';
import IItem from 'src/app/modules/auth/models/interfaces/IItem';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { emailRuRegexp } from '../models/custom-validators.model';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { CodeEnum } from '../shared/enums/codeEnum';

export type AccountType = { id: AccountTypeEnum, name: string };

export interface AccountStatus {
  id: any,
  name: string,
  code: string
}

export default interface ICode {
  code: string;
  name: string;
  codeNum: string;
}

export class RecordModel {
  constructor(public id: number, public name: string) { }
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public checkRuEmailDomain: boolean;
  baseUrl: string;
  backendUrl: string;

  constructor(
    private http: HttpClient,
    private appsettings: AppsettingsConfig,
    configService: ConfigService
    ) {
    this.baseUrl = '';
    this.backendUrl = appsettings.backendUrl;
    this.checkRuEmailDomain = configService.customization.getOptionBool(CodeEnum.EMAIL_CHECK_RU_DOMAIN);
  }

  getAccountTypes(): AccountType[] {
    return [
      { id: AccountTypeEnum.Custom, name: 'Самозарегистрированная' },
      { id: AccountTypeEnum.Esia, name: 'Пользователь ЕСИА' }
    ];
  }

  getStrangersTypes(): IStrangersType[] {
    return [
      { name: "Российское ИП", inEnglish: "Russian sole proprietorship", id: StrangersType.PERSONRUS },
      { name: "Российское ЮЛ", inEnglish: "Russian legal entity", id: StrangersType.ORGANIZATIONRUS },
      { name: "Иностранный гражданин", inEnglish: "Foreign citizen", id: StrangersType.PERSON },
      { name: "Иностранная организация", inEnglish: "Foreign organization", id: StrangersType.ORGANIZATION }
    ].filter(x => this.appsettings.availableStrangerTypes.includes(x.id));;
  }

  getStrangersOwnerTypes(): IStrangersType[] {
    return [
      { name: "Иностранный гражданин", inEnglish: "Foreign citizen", id: StrangersOwnerType.PERSON },
      { name: "Иностранная организация", inEnglish: "Foreign organization", id: StrangersOwnerType.LEGAL }
    ];
  }


  getOrgTypeName(type: OrganizationType): string {
    switch (type) {
      case OrganizationType.DRAFT: return "Черновик";
      case OrganizationType.ON_VERIFICATION: return "Проверка";
      case OrganizationType.NOT_VERIFIED: return "Проверка не пройдена";
      case OrganizationType.VERIFIED: return "Проверка пройдена";
      default: return "";
    }
  }
  getOrgStatusIcon(type: OrganizationType): string {
    switch (type) {
      case OrganizationType.DRAFT: return "orgDraft";
      case OrganizationType.ON_VERIFICATION: return "orgOnVerification";
      case OrganizationType.NOT_VERIFIED: return "orgNotVerified";
      case OrganizationType.VERIFIED: return "orgVerified";
      default: return "";
    }
  }

  buildErrors(errorObj: any): string {
    var errorsString = "";
    if (!!errorObj?.errors)
    {
      const errors = errorObj.errors;

      for (var prop in errors) {
        if (errors.hasOwnProperty(prop) && Array.isArray(errors[prop])) {
          errorsString += errors[prop] + "\r\n";
        }
      }
    }
    else
      errorsString = errorObj?.Message;

    return errorsString.replace(/[\r\n]+$/g, '');;
  }

  getOrgTypesForRegistration(): IItem[] {
    return [
      {
        id: OrgTypeEnum.Physical,
        name: 'Физическое лицо',
      },
      { id: OrgTypeEnum.Legal, name: 'Юридическое лицо' },
      {
        id: OrgTypeEnum.LegalBranch,
        name: 'Филиал юридического лица',
      },
      {
        id: OrgTypeEnum.Business,
        name: 'Индивидуальный предприниматель',
      },
      {
        id: OrgTypeEnum.Agency,
        name: 'Орган исполнительной власти',
      },
    ];
  }

  /**Получение справочника Общероссийский классификатор организационно-правовых форм (ОКОПФ) */
  getOrgAndLegalForms(): Observable<ICode[]> {
    return this.http.get<ICode[]>(`${this.backendUrl}/shared/GetOrgAndLegalForms`);
  }

  /**Получение списка типов документов */
  getDocTypes(): Observable<accountFileType[]> {
    return this.http.get<accountFileType[]>(`${this.backendUrl}/documents/doc-types`);
  }

  /**Поиск информационной системы по мнемонике */
  getClientByMnemonic(clientMnemonic: string | undefined): Observable<ISharedClientInfoResponse | undefined> {
    if (!clientMnemonic)
      return of(undefined);
    return this.http.get<ISharedClientInfoResponse>(`${this.backendUrl}/shared/client/mnemonic?clientMnemonic=${clientMnemonic}`);
  }

  /**
   * Валидация почты
   *
   * @param {string} text проверяемая строка
   * @returns {boolean} валидная почта
   */
  isEmailRegular(text: string): boolean {
    let regexp = this.checkRuEmailDomain ?
      emailRuRegexp :
      /^[\w._-]*[+]?[\w._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/;

    return regexp.test(text);
  }

  /**
   * Валидация Налогового номера
   *
   * @param {string} text проверяемая строка
   * @returns {boolean} валидный ИНН
   */
  isValidForeignInn(text: string): boolean {
    let regexp =  /^[\w]{8,20}$/;
    return regexp.test(text);
  }
}
