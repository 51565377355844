import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ok-cansel-dialog',
  templateUrl: './ok-cancel-dialog.component.html',
  styleUrls: ['./ok-cancel-dialog.component.scss']
})
export class OkCancelDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, text: string },
    private dialogRef: MatDialogRef<OkCancelDialogComponent>) {
  }
  onCloseClick(isOk: boolean) {
    this.dialogRef.close(isOk);
  }
}
