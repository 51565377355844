<div class="dialog-title">
  <h3 mat-dialog-title>Выбран отказ в подтверждении учётной записи, укажите причину отказа</h3>
  <button class="close-btn" mat-dialog-close></button>
</div>

<mat-dialog-content>
  <form [formGroup]="textAreaForm">
    <textarea cols="64" rows="10" class="w-100 border-0 " formControlName="textArea" 
      placeholder="Укажите причину отказа в подтверждении учётной записи (до 400 символов)">

    </textarea>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="secondary xl me-2" disableRipple [mat-dialog-close]="false">Отменить</button>
  <button class="primary xl" mat-raised-button disableRipple (click)="sendMail()">Отправить сообщение</button>
</mat-dialog-actions>