<div class="page-reesrt wrapper">
  <div class="wrapper-container main-header mb-3">
    <div class="header">
      <div class="d-flex mb-2">
        <svg-icon data-test="card_btn-back" key="arrowLeft" (click)="onBack()" class="me-3"></svg-icon>
        <h2 class="mb-0">Мой профиль</h2>

        <!-- Удалить УЗ -->
        <app-zit-auto-open-menu class="ms-3" *ngIf="!isEsiaUser && !isRemovedAccount()">
          <svg-icon trigger role="button" key="buttonRemove" data-test="card_btn-remove-account"
            (click)="onDeleteAccount(userUid)"></svg-icon>
          <div content>Удалить учётную запись</div>
        </app-zit-auto-open-menu>
      </div>


      <div class="d-flex">
        <!-- Статус -->
        <div data-test="card_btn-get-status-history" class="link ring-status ms-2"
             *ngIf="accStatus as accStatus" (click)="getStatusHistory()">
          <div class="ring-status">
            <i [ngClass]="getStatusClass(accStatus.status)"></i> {{accStatus.name}}
          </div>
        </div>
        <!-- Отправить повторное письмо на активацию УЗ -->
        <ng-container *ngIf="!isEsiaUser && !isRemovedAccount()">
          <div *ngIf="isActivated" class="link cursor-default ms-3">
            <svg-icon key="statusOk" class="m"></svg-icon> Активирована
          </div>
          <div *ngIf="!isActivated" class="link cursor-default ms-3">
            <svg-icon key="attention" class="m"></svg-icon> Не активирована
          </div>
          <div data-test="card_btn-send-confirm-mail" *ngIf="!isActivated && (accStatus?.status === accStatusEnum.NotApproved || accStatus?.status === accStatusEnum.MailNotConfirmed)" class="link mail d-flex ms-3" (click)="sendConfirmMail()">
            <svg-icon key="sendMail" class="mt-0 me-2"></svg-icon>Письмо активации
          </div>
        </ng-container>
      </div>
    </div>
  </div>



  <form [formGroup]="form" *ngIf="data" (ngSubmit)="onSubmit()" class="wrapper-container mb-0">
    <h4 class="mb-0">Данные пользователя</h4>
    <div formGroupName="personalData" class="row pt-3 g-3">
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Идентификатор</mat-label>
        <input data-test="card_input-identifier" matInput formControlName="identifier">
        <svg-icon data-test="card_btn-copy-identifier" role='button' matSuffix key="copy" class="ms-3 me-0" (click)="copyIdentifier()"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Последнее посещение</mat-label>
        <input data-test="card_input-lastLogin" matInput formControlName="lastLogin">
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4 mat-form-field-disabled">
        <mat-label>Тип учетной записи</mat-label>
        <input data-test="card_input-accType" matInput matInput [value]='_accType.name' name="accType">
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Фамилия <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-lastName" matInput formControlName="lastName">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'lastName'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('personalData.lastName')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Имя <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-firstName" matInput formControlName="firstName">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'firstName'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('personalData.firstName')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Отчество</mat-label>
        <input data-test="card_input-middleName" matInput formControlName="middleName">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'middleName'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('personalData.middleName')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>

      <mat-radio-group formControlName="gender">
        <mat-radio-button [attr.data-test]="'card_rb-gender-' + i" [value]="g.id"
          *ngFor="let g of genderList; let i = index" class="pe-3" color="primary"
          disableRipple>{{g.name}}</mat-radio-button>
        <mat-error *ngIf="form.get('personalData.gender')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-radio-group>

      <mat-form-field class="col-4 calendar-icon-wrap" appearance="fill"
        [ngClass]="form.get('dob')?.value ? 'selected' : ''">
        <mat-label>Дата рождения <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-dob" matInput [matDatepicker]="$any(dobDatepicker)" type="text" formControlName='dob' [max]='maxBirthDate'>
        <div matSuffix class="m-suffix">
          <app-check-status *ngIf="!isEsiaUser" class="me-1" [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'dob'.toUpperCase()"></app-check-status>
          <mat-datepicker-toggle class="calendar-icon-wrap" #dobDatepickerToggle [for]="dobDatepicker">
            <svg-icon data-test="card_btn-calendar-dob-toggle" class="calendar-icon" matDatepickerToggleIcon></svg-icon>
          </mat-datepicker-toggle>
        </div>
        <mat-datepicker panelClass="custom-ui" #dobDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('personalData.dob')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" [ngClass]="{'required': !isEsiaUser }" class="col-4">
        <mat-label>Мобильный телефон <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-mobPhone" matInput formControlName="mobPhone" prefix="+7 " [mask]="MASKS.mobPhone" autocomplete="off">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'mobPhone'.toUpperCase()"></app-check-status>
          <ng-container [ngTemplateOutlet]="accStatusFieldIcon"
            [ngTemplateOutletContext]="{$accStatus: accStatus?.status}">
          </ng-container>
        </div>
        <mat-error *ngIf="form.get('personalData.mobPhone')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error
          *ngIf="form.get('personalData.mobPhone')?.hasError('mobPhoneLen')">{{ERR_MSG.mobPhoneLen}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" [ngClass]="{'required': !isEsiaUser }" class="col-4">
        <mat-label>Электронная почта <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-email" matInput formControlName="email">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'personalData'.toUpperCase() + '.' + 'email'.toUpperCase()"></app-check-status>
          <ng-container [ngTemplateOutlet]="accStatusFieldIcon"
            [ngTemplateOutletContext]="{$accStatus: accStatus?.status}">
          </ng-container>
        </div>
        <mat-error *ngIf="form.get('personalData.email')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error *ngIf="form.get('personalData.email')?.hasError('email')"> {{ERR_MSG.email}} </mat-error>
        <mat-error *ngIf="form.get('personalData.email')?.hasError('emailRu')"> {{ERR_MSG.emailRu}} </mat-error>
      </mat-form-field>
    </div>



    <div class="d-flex pt-4">
      <svg-icon data-test="card_btn-show-address" key="arrowDown" (click)="showBlock.address = !showBlock.address" class="button-dropdown me-3"
        [ngClass]="{'active': showBlock.address}"></svg-icon>
      <h4 class="mb-0">Адрес регистрации</h4>
    </div>
    <div class="row g-3 pt-3" *ngIf="showBlock.address" formGroupName="registrationAddress">
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Город</mat-label>
        <input data-test="card_input-city" matInput formControlName="city">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'registrationAddress'.toUpperCase() + '.' + 'city'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('registrationAddress.city')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Улица</mat-label>
        <input data-test="card_input-street" matInput formControlName="street">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'registrationAddress'.toUpperCase() + '.' + 'street'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('registrationAddress.street')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2" appearance="fill">
        <mat-label>Дом, корпус</mat-label>
        <input data-test="card_input-house" matInput formControlName="house">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'registrationAddress'.toUpperCase() + '.' + 'house'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('registrationAddress.house')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2" appearance="fill">
        <mat-label>Квартира</mat-label>
        <input data-test="card_input-apartment" matInput formControlName="apartment">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'registrationAddress'.toUpperCase() + '.' + 'apartment'.toUpperCase()"></app-check-status>
        </div>
        <mat-error
          *ngIf="form.get('registrationAddress.apartment')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
    </div>


    <div class="d-flex pt-4">
      <svg-icon data-test="card_btn-show-identity-document" key="arrowDown" (click)="showBlock.identityDocument = !showBlock.identityDocument"
        class="button-dropdown me-3" [ngClass]="{'active': showBlock.identityDocument}"></svg-icon>
      <h4 class="mb-0"> Документ подтверждающий личность</h4>
    </div>
    <div class="row g-3 pt-3" *ngIf="showBlock.identityDocument" formGroupName="identityDocument">
      <mat-form-field appearance="fill" formGroupName="docType" class="col-4">
        <mat-label>Тип документа</mat-label>
        <input data-test="card_input-name" matInput formControlName="name">
        <div matSuffix class="m-suffix" *ngIf="!isEsiaUser">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'name'.toUpperCase()"></app-check-status>
        </div>
      </mat-form-field>
      <mat-form-field class="col-2" appearance="fill">
        <mat-label>Серия</mat-label>
        <input data-test="card_input-series" matInput formControlName="series" [mask]="MASKS.identityDocument.series" autocomplete="off">
        <div matSuffix class="m-suffix" *ngIf="!isEsiaUser">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'series'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('identityDocument.series')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error
          *ngIf="form.get('identityDocument.series')?.hasError('passportSeriesLen')">{{ERR_MSG.identityDocument.series}}</mat-error>
        <mat-error
          *ngIf="form.get('identityDocument.series')?.hasError('pattern')">{{ERR_MSG.pattern.onlyNum}}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2" appearance="fill">
        <mat-label>Номер</mat-label>
        <input data-test="card_input-number" matInput formControlName="number" [mask]="MASKS.identityDocument.num" autocomplete="off">
        <div matSuffix class="m-suffix" *ngIf="!isEsiaUser">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'number'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('identityDocument.num')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error
          *ngIf="form.get('identityDocument.num')?.hasError('passportNumLen')">{{ERR_MSG.identityDocument.num}}</mat-error>
        <mat-error *ngIf="form.get('identityDocument.num')?.hasError('pattern')">{{ERR_MSG.pattern.onlyNum}}</mat-error>
      </mat-form-field>
      <mat-form-field class="datepicker-field col-2" appearance="fill"
        [ngClass]="form.get('issueDate')?.value ? 'selected' : ''">
        <mat-label>Дата выдачи</mat-label>
        <input data-test="card_input-issueDate" matInput [matDatepicker]="$any(issueDateDatepicker)" type="text" formControlName='issueDate'
          [max]='maxDate'>
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data" class="me-1"
            [name]="'identityDocument'.toUpperCase() + '.' + 'issueDate'.toUpperCase()"></app-check-status>
          <mat-datepicker-toggle class="calendar-icon-wrap" #issueDateDatepickerToggle [for]="issueDateDatepicker">
            <svg-icon class="calendar-icon" matDatepickerToggleIcon></svg-icon>
          </mat-datepicker-toggle>
        </div>
        <mat-datepicker panelClass="custom-ui" #issueDateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('identityDocument.issueDate')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2">
        <mat-label>Код подразделения</mat-label>
        <input data-test="card_input-issueId" matInput formControlName="issueId" [mask]="MASKS.identityDocument.code" autocomplete="off">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'issueId'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('identityDocument.code')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error
          *ngIf="form.get('identityDocument.code')?.hasError('passportCodeLen')">{{ERR_MSG.identityDocument.code}}</mat-error>
        <mat-error
          *ngIf="form.get('identityDocument.code')?.hasError('pattern')">{{ERR_MSG.pattern.onlyNum}}</mat-error>
      </mat-form-field>

      <mat-form-field class="col-8" appearance="fill">
        <mat-label>Кем выдан</mat-label>
        <input data-test="card_input-issuedBy" matInput formControlName="issuedBy">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'issuedBy'.toUpperCase()"></app-check-status>
        </div>
        <mat-error *ngIf="form.get('identityDocument.issuer')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2">
        <mat-label>Место рождения</mat-label>
        <input data-test="card_input-birthplace" matInput formControlName="birthplace">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'birthplace'.toUpperCase()"></app-check-status>
        </div>
        <mat-error
          *ngIf="form.get('identityDocument.birthplace')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2">
        <mat-label>Гражданство</mat-label>
        <input data-test="card_input-citizenship" matInput formControlName="citizenship">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'identityDocument'.toUpperCase() + '.' + 'citizenship'.toUpperCase()"></app-check-status>
        </div>
        <mat-error
          *ngIf="form.get('identityDocument.citizenship')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
      </mat-form-field>
    </div>


    <div class="d-flex pt-4">
      <svg-icon data-test="card_btn-show-other-documents" key="arrowDown" (click)="showBlock.otherDocuments = !showBlock.otherDocuments"
        class="button-dropdown me-3" [ngClass]="{'active': showBlock.otherDocuments}"></svg-icon>
      <h4 class="mb-0"> Другие документы</h4>
    </div>
    <div class="row g-3 pt-3" *ngIf="showBlock.otherDocuments" formGroupName="otherDocuments">
      <mat-form-field class="col-2" [ngClass]="{'required': !isEsiaUser }" appearance="fill">
        <mat-label>СНИЛС <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-snils" matInput formControlName="snils" [mask]="MASKS.snils" autocomplete="off">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'otherDocuments'.toUpperCase() + '.' + 'snils'.toUpperCase()"></app-check-status>
          <ng-container [ngTemplateOutlet]="accStatusFieldIcon"
            [ngTemplateOutletContext]="{$accStatus: accStatus?.status}">
          </ng-container>
        </div>
        <mat-error *ngIf="form.get('otherDocuments.snils')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error *ngIf="form.get('otherDocuments.snils')?.hasError('pattern')">{{ERR_MSG.pattern.onlyNum}}</mat-error>
        <mat-error *ngIf="form.get('otherDocuments.snils')?.hasError('snilsLen')">{{ERR_MSG.snils}}</mat-error>
      </mat-form-field>
      <mat-form-field class="user-org-position-field col-2" [ngClass]="{'required': !isEsiaUser }" appearance="fill">
        <mat-label>ИНН <span *ngIf="!isEsiaUser">*</span></mat-label>
        <input data-test="card_input-inn" matInput formControlName="inn" [mask]="MASKS.inn12" autocomplete="off">
        <div *ngIf="!isEsiaUser" matSuffix class="m-suffix">
          <app-check-status [data]="data"
            [name]="'otherDocuments'.toUpperCase() + '.' + 'inn'.toUpperCase()"></app-check-status>
          <ng-container [ngTemplateOutlet]="accStatusFieldIcon"
            [ngTemplateOutletContext]="{$accStatus: accStatus?.status}">
          </ng-container>
        </div>
        <mat-error *ngIf="form.get('otherDocuments.inn')?.hasError('required')">{{ERR_MSG.required}}</mat-error>
        <mat-error *ngIf="form.get('otherDocuments.inn')?.hasError('innLen')">{{ERR_MSG.personInn}}</mat-error>
        <mat-error *ngIf="form.get('otherDocuments.inn')?.hasError('pattern')">{{ERR_MSG.pattern.onlyNum}}</mat-error>
      </mat-form-field>
    </div>



    <div class="d-flex mt-4" *ngIf="!isEsiaUser">
      <button data-test="card_btn-cancel" class="secondary me-3" type="button" (click)="loadData()"> Отменить </button>
      <button data-test="card_btn-save" class="primary" type="submit" [disabled]="!updateAllowed()"> Сохранить </button>
    </div>


  </form>



  <app-organization4card [isEsiaUser]="isEsiaUser" [accStatus]="accStatus"></app-organization4card>



  <div class="wrapper-container-flex doc-info " *ngIf="!!showProfileDocumentsTable">
    <div class="d-flex w-100">
      <svg-icon data-test="card_btn-show-eDocCopy" key="arrowDown" (click)="showBlock.eDocCopy = !showBlock.eDocCopy" class="button-dropdown me-3"
        [ngClass]="{'active': showBlock.eDocCopy}"></svg-icon>
      <h4 class="mb-0">Электронные копии документов</h4>
      <button data-test="card_btn-add-doc" class="primary xs py-1 px-2 ms-auto" (click)="onAddDocClick()" *ngIf="!!showProfileAddDocumentBtn && accStatus?.status != accStatusEnum.AccountDeleted">
        <svg-icon key="addSmall" class="s"></svg-icon>
        Добавить документ
      </button>
    </div>
    <!-- <div class="row g-3 pt-3" -->
    <div *ngIf="showBlock.eDocCopy && !!form.get('file')" class="w-100">
      <table mat-table [dataSource]="ds" width="100%" class="bordered mt-3" *ngIf="ds.length">
        <ng-container [matColumnDef]="c" *ngFor="let c of displayedColumns">
          <ng-container *ngIf="c === 'docType'">
            <th mat-header-cell *matHeaderCellDef class="hed">
              <div class="m-start">
                <div>Документ</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"> {{ element[c]?.name }} </td>
          </ng-container>

          <ng-container *ngIf="c === 'fileName'">
            <th mat-header-cell *matHeaderCellDef class="hed">
              <div class="m-start">
                <div>Файл</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <span [attr.data-test]="'card_btn-file-view-file-' + element.id" class="view-file-link" role='button'
                (click)="onViewFile(element)">{{ element[c] }}</span>
            </td>
          </ng-container>

          <ng-container *ngIf="c === 'size'">
            <th mat-header-cell *matHeaderCellDef class="hed">
              <div class="m-start">
                <div>Размер</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <span [attr.data-test]="'card_btn-file-size-view-file-' + element.id" class="view-file-link" role='button' (click)="onViewFile(element)">{{ element[c] | fileSize
                }}</span>
            </td>
          </ng-container>

          <ng-container *ngIf="!['actions', 'fileName', 'docType'].includes(c)">
            <th mat-header-cell *matHeaderCellDef class="hed">
              <div class="m-start">
                <div>Дата изменения</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"> {{ element[c] | date: 'dd.MM.YYYY HH:mm:ss' }} </td>
          </ng-container>

          <ng-container *ngIf="c === 'actions'">
            <th mat-header-cell *matHeaderCellDef class="hed">Действие</th>
            <td mat-cell *matCellDef="let element">
              <svg-icon [attr.data-test]="'card_btn-actions-remove-file-' + element.id" class="m" key="buttonRemove" *ngIf="!!showProfileDeleteDocumentBtn && accStatus?.status != accStatusEnum.AccountDeleted" role='button'
                (click)="onRemoveFileClick($event, element)"></svg-icon>
            </td>
          </ng-container>
        </ng-container>

        <tr class="default-th mat-header-row-sticky" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>



  <div class="wrapper-container-flex" *ngIf="!!showProfileVehiclesTable && !!vehicles?.length">
    <div class="d-flex w-100">
      <svg-icon data-test="card_btn-show-vehicles" key="arrowDown" (click)="showBlock.vehicles = !showBlock.vehicles" class="button-dropdown me-3"
        [ngClass]="{'active': showBlock.vehicles}"></svg-icon>
      <h4 class="mb-0">Транспортные средства пользователя</h4>
    </div>
    <div *ngIf="showBlock.vehicles" class="row pt-3 pb-3 g-3">
      <ng-container *ngFor="let v of vehicles; let i = index">
        <mat-form-field appearance="fill" class="col-4">
          <mat-label>Имя ТС</mat-label>
          <input [attr.data-test]="'card_input-vehicle-name-' + i" matInput value="{{v.name}}" [disabled]="true">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
          <mat-label>Номер ТС</mat-label>
          <input [attr.data-test]="'card_input-vehicle-number-plate-' + i" matInput value="{{v.numberPlate}}" [disabled]="true">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-4">
          <mat-label>Свидетельство ТС</mat-label>
          <input [attr.data-test]="'card_input-vehicle-reg-certificate-' + i" matInput value="{{v.regCertificateSeries}} {{v.regCertificateNumber}}" [disabled]="true">
        </mat-form-field>
      </ng-container>
    </div>
  </div>

</div>


<!-- ШАБЛОН иконки статуса  -->
<ng-template #accStatusFieldIcon let-$accStatus="$accStatus">
  <svg-icon *ngIf="accStatus?.status === accStatusEnum.Approved" key="statusOk" class="mb-1"></svg-icon>
  <svg-icon *ngIf="accStatus?.status === accStatusEnum.RequestDataChange" key="attention" class="mb-1"></svg-icon>
  <!-- <svg-icon *ngIf="accStatus?.status === accStatusEnum.NotApproved" key="statusVarn" class="mb-1"></svg-icon> -->
</ng-template>
