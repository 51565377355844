


<div class="mini-wrapper">
  <div class="sub-wrapper">
    <h1>{{'activateAccountError.title' | transloco}}</h1>
    <div class="mt-3 mb-4">
          <div>{{'activateAccountError.description1' | transloco}}</div>
          <div>{{'activateAccountError.description2' | transloco}}</div>
          <div>{{'activateAccountError.description3' | transloco}}</div>
    </div>
    <app-contacts-info></app-contacts-info>
    <div class="mt-2" >
      <button [attr.data-test]="'confirm-success-info_btn-goto-profile'" class="primary xl me-3 w-100" disableRipple mat-button [routerLink]="['/logout']">{{'confirmSuccessInfo.goTo' | transloco}} </button>
    </div>
  </div>
</div>
