<div class="header-info" [ngClass]="{'header-maxi-info': headerMaxi }">
  <div class="support" *ngIf="!headerMaxi">
    <div [attr.data-test]="'user-card_btn-toggle-support'" class="support-prev text-header"
      (click)="toggleSupport = !toggleSupport; toggleAccount = false;" [ngClass]="{'active': toggleSupport }">
      <svg-icon key="support2" class="filled header me-1"></svg-icon>
      <span>{{'user-card.support' | transloco}}</span>
    </div>
    <div class="support-detail">
      <span class="support-detail-title">{{'user-card.support' | transloco}}</span>
      <a data-test="user-card_link-config-service-localize-contacts-phone1" class="support-detail-tel" *ngIf="this.configService.localizeContacts?.phone1?.isShow" href="{{this.configService.localizeContacts.phone1?.href}}">{{this.configService.localizeContacts.phone1?.text}}</a>
      <a data-test="user-card_link-config-service-localize-contacts-phone2" class="support-detail-tel support-detail-tel2" *ngIf="this.configService.localizeContacts?.phone2?.isShow" href="{{this.configService.localizeContacts.phone2?.href}}">{{this.configService.localizeContacts.phone2?.text}}</a>
      <a data-test="user-card_link-config-service-localize-contacts-phone3" class="support-detail-tel support-detail-tel2" *ngIf="this.configService.localizeContacts?.phone3?.isShow" href="{{this.configService.localizeContacts.phone3?.href}}">{{this.configService.localizeContacts.phone3?.text}}</a>
      <a data-test="user-card_link-config-service-localize-contacts-mail" class="support-detail-mail" *ngIf="this.configService.localizeContacts?.mail?.isShow" href="{{this.configService.localizeContacts.mail?.href}}">{{this.configService.localizeContacts.mail?.text}}</a>
      <a data-test="user-card_link-config-service-localize-contacts-telegram" class="support-detail-bot" *ngIf="this.configService.localizeContacts?.telegram?.isShow" href="{{this.configService.localizeContacts.telegram?.href}}" target="_blank">{{this.configService.localizeContacts.telegram?.text}}</a>
      <a data-test="user-card_link-go-to-auth-signin" class="support-detail-signin" [routerLink]="['/auth/signin']" [queryParams]="{is_specialist: true}" *ngIf="showSpecialistButton()" queryParamsHandling="merge">
        Вход для специалистов
      </a>
    </div>
  </div>

  <div class="account" *ngIf="authService.isAuthenticated()">
    <div [attr.data-test]="'user-card_btn-toggle-account'"
         class="account-prev" (click)="toggleAccount = !toggleAccount; toggleSupport = false;"
         [ngClass]="{'active': toggleAccount }">
      <span class="s1 text-header">{{ this.authService.userName}}</span>
      <svg-icon key="account" class="filled header ms-1 l"></svg-icon>
      <svg-icon key="arrowDown" class="filled header ms-2 mt-1" ></svg-icon>
    </div>

    <div class="account-detail">
      <div class="account-detail-info">
        <h3 [attr.data-test]="'user-card_btn-profile-name'"
             class="account-detail-info-name" (click)="onProfileNameClick()">
            {{ this.authService.userName}}
        </h3>
        <svg-icon [attr.data-test]="'user-card_btn-close-account'" key="close" class="close" (click)="toggleAccount = !toggleAccount"></svg-icon>
      </div>
      <div class="account-detail-info-role">{{userInfo.roles.length ? userInfo.roles.join(", ") : 'Роль не задана' }}</div>
      <div [attr.data-test]="'user-card_link-system-redirect'" class="account-detail-info-link account-detail-info-link-system" [routerLink]="" (click)="onSystemsRedirectClick()" queryParamsHandling="merge">{{'user-card.IS' | transloco}}</div>
      <div [attr.data-test]="'user-card_link-profile-name'" class="account-detail-info-link account-detail-info-link-profile" [routerLink]="" (click)="onProfileNameClick()" queryParamsHandling="merge">{{'user-card.profile' | transloco}}</div>
      <div [attr.data-test]="'user-card_btn-logout'" class="account-detail-info-link account-detail-info-link-logout" (click)="logout()">{{'user-card.out' | transloco}}</div>
    </div>

  </div>
</div>
