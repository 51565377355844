export class SystemModel{
  constructor(public id: number, public name: string, public roles: RoleModel[], public isCollapse = false, public isESIACollapse = false){
      
  }
}

export class RoleModel{
  constructor(public id: number, public name: string, public clientRoleId: number, public permissionId: number, public clientId?: number, 
    public organizationId?: number, public check: boolean = true, public isInherit: boolean = false, public isAuto: boolean = false) {      
  }
}
