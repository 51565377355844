import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../modules/auth/services/auth.service";
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';
import { ThemeEnum } from 'src/app/core/shared/enums/ThemeEnum';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Шлюз ЕСИА - ESIA Gate';
  panelOpenState = false;
  toggleTechnik = false;
  toggleReports = false;
  showMini = false;

  // При нажатии F2 менять темы angular
  @HostListener('document:keydown', ['$event'])
  handleTheKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'F2') {
      this.changeTheme();
    }
  }

  constructor(@Inject(DOCUMENT) private document: Document,
    router: Router,
    public authService: AuthService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private configService: ConfigService) {

    this.translocoService.selectTranslate('pageTitle')
      .subscribe(value => { this.titleService.setTitle(value); });

    router.events.subscribe((val) => {
      this.toggleTechnik = false;
      this.toggleReports = false;
    });

    // применим angular тему из конфига
    let theme = this.configService.customization.getOptionString(CodeEnum.THEME);
    if (!!theme)
      this.document.body.classList.add(theme);
    else
      this.document.body.classList.add(ThemeEnum.BLUE);
  }

  ngOnInit(): void {
  }

  changeTheme() {
    if (this.document.body.classList.contains(ThemeEnum.GREY)) {
      this.document.body.classList.remove(ThemeEnum.GREY);
      this.document.body.classList.add(ThemeEnum.BLUE);
      console.info('Выбрана тема ЭПД');
    }
    else {
      this.document.body.classList.remove(ThemeEnum.BLUE);
      this.document.body.classList.add(ThemeEnum.GREY);
      console.info('Выбрана тема ЭОПП');
    }
  }
}
