import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { I18nService, LANG_PROPERTIES } from 'src/app/modules/auth/services/i18n.service';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {
  copyrightYear: number | undefined = new Date().getFullYear();
  rulesPersonalUrl: string = '';

  constructor(
    private appsettings: AppsettingsConfig,
    private route: ActivatedRoute,
    private i18nService: I18nService,
  ) { }
  getRulesPersonalUrl(): string {
    return this.i18nService.getValueByLang(LANG_PROPERTIES.rulesPersonalUrl);
  }
  showSpecialistButton() {
    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    return (
      this.appsettings.customization.showSpecialistSignInPage_ClientMnemonics.lastIndexOf(
        client
      ) != -1
    );
  }
}
