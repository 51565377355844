import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { AppsettingsConfig } from './modules/client-config/models/client-config';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    // Добавлена соль, для решения проблем с кэшироавнием на проде
    const salt = (new Date()).getTime();
    return this.http.get<Translation>(`/assets/shared/i18n/${lang}.json?${salt}`);
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader };

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: (config: AppsettingsConfig) => (translocoConfig({
        availableLangs: ['ru', 'en'],
        defaultLang: 'ru',
        fallbackLang: 'ru',
        missingHandler: {
          allowEmpty: true,
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: config.isProduction
        },
        reRenderOnLangChange: true,
        prodMode: config.isProduction
      })),
      deps: [AppsettingsConfig],
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule { }
