import { CodeEnum } from "./enums/codeEnum";

// Represents a customization option
export interface ICustomization {
  // Section code to which this option belongs (e.g., "profile")
  section: string;
  // Brief description of what the option does
  name: string;
  // Detailed description of what will be done on the frontend and backend (to be displayed in a tooltip)
  description: string;
  // Code word for the developer, essentially an identifier
  code: string;
  // Indicates if the option is checked/enabled
  type: string;
  // Indicates if the option is checked/enabled
  value: string;
}

export class Customization {

  /**
   * Кастомизации функционала и интерфейса проекта
   *
   * @constructor
   * @param {ICustomization[]} customization Список опций для кастомизации
   */
  constructor(public customization: ICustomization[]) { }

  public getOptionBool(key: CodeEnum): boolean {
    let value = this.customization.find(x => x.code == key)?.value;
    if (value == undefined) throw new Error(`Не найдена опция ${key} в конфиге Customization`);
    return JSON.parse(value);
  }

  public getOptionString(key: CodeEnum): string {
    let value = this.customization.find(x => x.code == key)?.value;
    if (value == undefined) throw new Error(`Не найдена опция ${key} в конфиге Customization`);
    return value;
  }

  public getOptionNumber(key: CodeEnum): number {
    let value = this.customization.find(x => x.code == key)?.value;
    if (value == undefined) throw new Error(`Не найдена опция ${key} в конфиге Customization`);
    return JSON.parse(value);
  }
}
