import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UntypedFormBuilder} from "@angular/forms";
import {Observable} from "rxjs";
import { Filter4HistoryStatus, HistoryStatusList, HistoryStatusModel } from '../models/HistoryStatusModel';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { strangerInfo } from '../models/strangerProfile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  backendUrl;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private appsettings: AppsettingsConfig
  ) {
    this.backendUrl = this.appsettings.backendUrl;
  }

  getHistoryStatus(filter: Filter4HistoryStatus): Observable<HistoryStatusModel> {
    return this.http.get<HistoryStatusModel>(`${this.backendUrl}/account/status/list?count=${filter.count}&offset=${filter.offset}`);
  }

  public getStranger(): Observable<strangerInfo> {
    return this.http.get<strangerInfo>(`${this.backendUrl}/account/get-stranger`);
  }

  getStrangerHistoryStatus(filter: Filter4HistoryStatus): Observable<HistoryStatusList[]> {
    return this.http.get<HistoryStatusList[]>(`${this.backendUrl}/account/strangerHistoryStatus?count=${filter.count}&offset=${filter.offset}`);
  }
}
