import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from "../../client-config/services/config.service";
import { LocalizeContacts } from "../../../core/shared/localize-config";
import { AuthService } from '../../auth/services/auth.service';
import { Subscription } from 'rxjs';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

@Component({
  selector: 'norights',
  templateUrl: './norights.component.html',
  styleUrls: ['./norights.component.scss']
})
export class NorightsComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();
  contacts: LocalizeContacts;
  isLoading = true;
  isPhoneConfirmed: boolean = true;
  isMailConfirmed: boolean = false;

  constructor(
    private configService: ConfigService,
    public authService: AuthService,
  ) {
    this.contacts = configService.localizeContacts;
  }

  ngOnInit(): void {
    if (!this.authService.isAuthenticated()) return;

    this.subs.add(this.authService.getUserInfo().subscribe(userInfo => {
      let needConfirmPhone = this.configService.customization.getOptionBool(CodeEnum.NEED_CONFIRM_PHONE)
      this.isPhoneConfirmed = userInfo.isPhoneConfirmed || !needConfirmPhone;
      this.isMailConfirmed = userInfo.isEmailConfirmed == true;
      this.isLoading = false;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
