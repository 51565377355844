import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'layout-with-nav',
  templateUrl: './layout-with-nav.component.html',
  styleUrls: ['./layout-with-nav.component.scss'],
})
export class LayoutWithNavComponent implements OnInit {
  @Input('title') title: string = '';

  widthScreen: number = 0;
  showMaxiHeader = false;

  constructor(private titleService: Title, private location: Location) {
    this.location.onUrlChange((url) => this.updateHeader(url));
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.widthScreen = window.innerWidth;
    console.info('location', this.location.path());
  }

  updateHeader(currentUrl: string) {
    if (
      currentUrl.indexOf('/auth/signin') != -1 ||
      currentUrl.indexOf('/systemRedirect') != -1 ||
      currentUrl.indexOf('/auth/registration') != -1 ||
      currentUrl.indexOf('/auth/restore-pwd') != -1 ||
      currentUrl.indexOf('/norights') != -1 ||
      currentUrl.indexOf('/email-confirm') != -1 ||
      currentUrl.indexOf('/activate-account-error') != -1 ||
      currentUrl.indexOf('/server-error') != -1 ||
      currentUrl.indexOf('/esia-error') != -1 ||
      currentUrl.indexOf('/error') != -1 ||
      currentUrl.indexOf('/auth/choose-actor') != -1 ||
   // currentUrl.indexOf('/profile') != -1 ||
      currentUrl.indexOf('/esia-token-and-scope') != -1 ||
   // currentUrl.indexOf('/logout-all') != -1 ||
   // currentUrl.indexOf('/logout') != -1 ||
    currentUrl.indexOf('/confirm-success-info') != -1
   // currentUrl.indexOf('/not-found') != -1 ||
   // currentUrl.indexOf('/auth/specialists-signin') != -1 ||
   // currentUrl.indexOf('/auth/registrationStrangers') != -1 ||
   // currentUrl.indexOf('/auth/confirm') != -1 ||
   // currentUrl.indexOf('/auth/password-recovery-link-expired') != -1 ||
   // currentUrl.indexOf('/auth/') != -1 ||
     ) {
      this.showMaxiHeader = true;
    } else {
      this.showMaxiHeader = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.widthScreen = event.target.innerWidth;
  }
}
