import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsInfoComponent } from 'src/app/components/contacts-info/contacts-info.component';
import { ZitAutoOpenMenuComponent } from 'src/app/components/zit-auto-open-menu/zit-auto-open-menu.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';


@NgModule({
  declarations: [
    ContactsInfoComponent,
    ZitAutoOpenMenuComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
  ],
  exports: [
    ContactsInfoComponent,
    ZitAutoOpenMenuComponent
  ]
})
export class SharedModule { }
