<div mat-dialog-title>
  <h1 *ngIf="!isConfirmSuccess">Подтвердите номер телефона</h1>
  <button (click)="onCloseClick()" class="close-btn ms-auto"></button>
</div>


<div mat-dialog-content *ngIf="!isConfirmSuccess" class="p-3">
  <ng-container *ngIf="callTimerVal > 0 && !isLoading">
    <p class="text-xl">Для подтверждения введенного контактного телефона {{data.phone}} необходимо совершить с него звонок на номер телефона</p>
    <div class="phone"><span class="text-big">{{flashCallPhone | phone:'RU'}}</span></div>
    <mat-progress-bar class="mb-3 m-auto" [value]="callTimerVal*100/callExpireSeconds"></mat-progress-bar>
    <div class="text-xl text-second text-center">
      Сделать повторный звонок можно через
      <span [countdown]="callExpireSeconds" (value)="callTimerVal = $event">{{ callTimerVal }}</span> сек.
    </div>
  </ng-container>


  <div *ngIf="callTimerVal == 0" class="text-center">
    <div class="text-xl mb-3">Истек срок действия телефонного номера для проверочного вызова</div>
    <button (click)="onRetryClick()" class="primary xl mt-3 confirm-button" mat-raised-button disableRipple
      data-test="activate-phone-dialog_btn-retry">Повторить звонок</button>
  </div>


  <div *ngIf="isLoading" class="col-12">
    <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
  </div>
</div>


<div mat-dialog-content *ngIf="isConfirmSuccess" class="p-3 d-flex flex-column text-center">
  <div><svg-icon key="statusOk" class="xxl m-4"></svg-icon></div>
  <div class="text-big mb-4">Спасибо</div>
  <p class="text-xl mb-3">Номер вашего телефона успешно подтвержден</p>
</div>
