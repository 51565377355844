import { Component } from '@angular/core';
import { ConfigService } from "../../../client-config/services/config.service";
import { LocalizeContacts } from "../../../../core/shared/localize-config";
import { Router } from '@angular/router';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

@Component({
  selector: 'app-confirm-error-dialog',
  templateUrl: './activate-account-error.component.html',
  styleUrls: ['./activate-account-error.component.scss']
})
export class ActivateAccountErrorComponent {
  contacts:LocalizeContacts;

  constructor(
    configService: ConfigService,
    router: Router
  ) {
    var activateAccountErrorPage = configService.customization.getOptionBool(CodeEnum.MAIL_EXPIRED);
    if (!activateAccountErrorPage) {
      router.navigate(['/auth/signin']);
    }

    this.contacts = configService.localizeContacts;
  }
}
