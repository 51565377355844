export interface CreatePermission {
  typeCode: PermissionTypeCode;
  accountId?: string;
  organizationId?: number;
  clientRoleId?: number;
  isInherit?: boolean;
}
export interface CreatePermissionClientRole {
  clientId?: number;
  roleId?: number;
}

export interface CreatePermissionResponse {
  typeCode: PermissionTypeCode;
  account: Account4Permission;
  organization: Organization4Permission;
  permissionId: number;
  isInherit?: boolean;
  clientRoles: ClientRoles;
}
export interface Account4Permission {
  id: number;
  login: string;
}
export interface Organization4Permission {
  id: number;
  inn: string;
  fullName: string;
}
export interface ClientRoles {
  id: number;
  client: ClientRole;
  role: ClientRole;
}
export interface ClientRole {
  id: number;
  name: string;
}


export interface PermissionAvailable {
  permissionTypeCode: PermissionTypeCode;
  accountId?: number;
  organizationId?: number;
  clientId?: number;
}

export interface PermissionOrganizationAvailable {
  organizationIds: number[];
  clientIds: number[];
}

export interface PermissionAvailableResponse {
  uuid:number;
  clientId?: number;
  organizationId?: number;
  roleId: number;
  name: string;
  isInherit: boolean;
}

export interface PermissionRoleListResponse {
  permissionId:number;
  clientRoleId:number;
  clientId?: number;
  organizationId?: number;
  roleId: number;
  name: string;
  isInherit: boolean;
  isAuto: boolean;
}

export interface PermissionResponse {
  typeCode: number;
  organization: Organization4Permission;
}


export interface PermissionAccountResponse {
  clientRoles: ClientRoles;
  isInherit?: boolean;
  organization: Organization4Permission;
  permissionId: number;
  typeCode: PermissionTypeCode;
  isAuto: boolean;
}


export interface  Organizations4Permission {
  name: string;
  permissions: PermissionAccountResponse[];
}

export enum PermissionTypeCode {
  // Доступ пользователя к ролям и их подсистемам
  AccessUser2Role = 1,
  // Доступ пользователя к организации
  AccessUser2Organization = 2,
  // Доступ организаций к ролям и их подсистемам
  AccessOrganization2Role = 3
}
