import {Validators} from "@angular/forms";

export default class RegistrationAddress {
  constructor(
    public city: any = ['', Validators.required],
    public street: any = ['', Validators.required],
    public house: any = ['', Validators.required],
    public apartment: any = ['', Validators.required]
  ) {
  }
}
