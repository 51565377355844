import {AccountStatusEnum} from "./account-status.enum";

export default class AccountStatus {
  constructor(
    public status: AccountStatusEnum,
    public name: string,
    public nameEn?: string,
  ) {
  }
}

// Получаем класс для выбранного статуса УЗ пользователя
export function getStatusClass(s: number): string {
  if (s === AccountStatusEnum.RequestPasswordChange
    || s === AccountStatusEnum.RequestDataChange
    || s === AccountStatusEnum.EmailConfirmed
    || s === AccountStatusEnum.PhoneConfirmed
    || s === AccountStatusEnum.AwaitingEmailConfirmation
    || s === AccountStatusEnum.AwaitingConfirmationController
    || s === AccountStatusEnum.AwaitingPhoneConfirmation
    || s === AccountStatusEnum.AccountRestored) {
    return 'bg-warning';
  }
  if (s === AccountStatusEnum.Approved) {
    return 'bg-success';
  }
  if (s === AccountStatusEnum.ConfirmationCanceled
    || s === AccountStatusEnum.AccountDeleted
    || s === AccountStatusEnum.FinalConfirmationDenied
    || s === AccountStatusEnum.MailNotConfirmed) {
    return 'bg-error';
  }
  if (s === AccountStatusEnum.NotApproved) {
    return 'bg-info';
  }
  return '';
}
