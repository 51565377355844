import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexContainerComponent } from './components/flex-container/flex-container.component';
import { FlexItemComponent } from './components/flex-item/flex-item.component';

@NgModule({
  declarations: [FlexContainerComponent, FlexItemComponent],
  exports: [FlexContainerComponent, FlexItemComponent],
  imports: [CommonModule]
})
export class GridModule {}
