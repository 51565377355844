import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import ISystem from "../../models/interfaces/ISystem";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslocoService } from '@ngneat/transloco';
import AccountUserInfo from "src/app/core/models/AccountUserInfo";
import { CodeEnum } from "src/app/core/shared/enums/codeEnum";
import { ConfigService } from "src/app/modules/client-config/services/config.service";

@Component({
  selector: 'app-system-redirect',
  templateUrl: './systemRedirect.component.html',
  styleUrls: ['./systemRedirect.component.scss']
})
export class SystemRedirectComponent implements OnInit {
  systems: ISystem[] = [];
  img: SafeUrl[] = [];
  signinUntrustedUsersOauth: boolean;
  isLoading = true;

  constructor(
    private authService: AuthService,
    public userInfo: AccountUserInfo,
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private translocoService: TranslocoService) {
    this.signinUntrustedUsersOauth = this.configService.customization.getOptionBool(CodeEnum.SIGNIN_UNTRUSTED_USERS_OAUTH);
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated() && !this.userInfo.trusted && !this.signinUntrustedUsersOauth) {
      this.router.navigate(['norights']); return;
    }
    this.getSystems();
  }

  getSystems() {
    this.isLoading = true;
    this.authService.getSystemsList().subscribe(list => {
      this.systems = list;
      list.forEach(x => this.getImage(x));
      this.checkSystems();
    });
  }

  getActiveLang() {
    return this.translocoService.getActiveLang();
  }

  getName(system: ISystem) {
    if (this.getActiveLang() == "ru")
      return system.fullName
    else return system.fullNameEn
  }

  getDescription(system: ISystem) {
    if (this.getActiveLang() == "ru")
      return system.description
    else return system.descriptionEn
  }

  getImage(system: ISystem) {
    this.authService
      .getImage(system.id)
      .subscribe(file => {
        let fileURL = window.URL.createObjectURL(file.body as Blob);
        this.img[system.id] = this.sanitizer.bypassSecurityTrustUrl(fileURL);
      });

  }

  redirect(system: ISystem) {
    window.location.href = system.url;
  }

  /***
   * Делает редирект если системы не найдены.
   * @private
   */
  private checkSystems() {
    if (this.systems.length == 0) {
      this.router.navigate(['norights'])
        .then();
    }
    else {
      this.isLoading = false;
    }
  }
}
