import {Validators} from "@angular/forms";
import {USERINFO_FORM_CONTROL_VALIDATORS} from "./userinfo-form-control-validators.const";

export default class OrgData {
  userOrgPosition = ['', Validators.required];
  inn = ['', USERINFO_FORM_CONTROL_VALIDATORS.inn];
  ogrn = ['', USERINFO_FORM_CONTROL_VALIDATORS.ogrn];
  orgAndLegalForm = ['', Validators.required];
  orgName = ['', Validators.required];
  orgShortName = ['', Validators.required];
  postalAddress = ['', Validators.required];
  legalAddress = ['', Validators.required];
  phone = ['', Validators.required];
  email = ['', USERINFO_FORM_CONTROL_VALIDATORS.email];
}
