import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';

export const FIO_COOKIE = "fio";
export const LANGUAGE_COOKIE = "language";
export const ESIARETRY_COUNT_COOKIE = "esiaRetryCount";

export const CAPTCHA_LOGIN_RETRY_COOKIE = "captchaLoginRetry";

// сохранить куки в корневой домен

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(
    private cookieService: CookieService,
    private window: Window
  ) { }

  // получить корневой домен
  getBaseDomain() {
    const hostname = this.window.location.hostname;
    const subdomains = hostname.split('.');
    if (subdomains.length > 2) {
      subdomains.shift();
    }
    const baseDomain = subdomains.join('.');
    return baseDomain;
  }

  public saveCookie(key: string, value: string, options?: CookieOptions) {
    if(!!options)
      options.domain = this.getBaseDomain();
    else
      options = { domain: this.getBaseDomain() }

    this.cookieService.put(key, value, options);
  }

  public getCookie(key: string) {
    return this.cookieService.get(key);
  }

  public removeCookie(key: string) {
    return this.cookieService.remove(key, { domain: this.getBaseDomain() });
  }
}
