export function getFileSize(size: number) {
  return `${(size / 1000).toFixed()} кб`;
}

// Проверяет, есть ли в массиве дубликаты
export function hasDuplicates<T>(arr: T[]): boolean {
  return new Set(arr).size < arr.length;
}

// Глубокое копирование объекта, включая вложенные объекты
export function deepCopy(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;  // Return the value if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item));  // Recursively deep copy array elements
  }

  const clonedObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepCopy(obj[key]);  // Recursively deep copy object properties
    }
  }

  return clonedObj;
}

/**
   * Удалить из массива все повторяющиеся элементы
   */
export function distinctArray<T>(array: T[]): T[] {
  return array.filter((value, index) => array.indexOf(value) === index);
}
/**
 * Получение уникальных значений массива по выбранному полю
 * @param arr исходный массив
 * @param key по какому полю делать дистинкт
 * @returns массив с уникальными значений
 */
export function distinctBy(arr:any, key:string) {
  return [...new Map(arr.map((item: { [x: string]: any; }) => [item[key], item])).values()]
}
