import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AppsettingsConfig, IAppsettingsConfig } from './app/modules/client-config/models/client-config';
import AccountUserInfo from './app/core/models/AccountUserInfo';


// Добавлена соль, для решения проблем с кэшироавнием
const salt = (new Date()).getTime();

fetch(`assets/appsettings.json?${salt}`, { cache: 'no-store' }) 
  .then((res) => res.json())
  .then((config: IAppsettingsConfig) => {
    console.info(config);
    if (config.isProduction) {
      enableProdMode();
    }

    const authUrl = config.backendUrl;
    const getUserInfoUrl = authUrl + '/account/UserInfo';

    fetch(getUserInfoUrl, { method: 'POST', credentials: 'include' })
      .then((res) => {
        if (res.status === 401) {
          return null;
        } else {
          return res.json();
        }
      })
      .then((result: AccountUserInfo) => {
        platformBrowserDynamic([
          { provide: AppsettingsConfig, useValue: config },
          { provide: AccountUserInfo, useValue: result }
        ])
          .bootstrapModule(AppModule)
          .catch((err) => console.error(err));
      }).catch(
        (error) => {
          const currentLocation = window.location.origin;
          //window.location.href = `/auth/signin?returnUrl=${currentLocation}`;
        }
      );
  });
