import {Component, OnInit} from '@angular/core';
import {ConfigService} from "../../../client-config/services/config.service";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import LogoutUrl from "../../models/interfaces/ILogoutUrl";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-logout-all-clients',
  templateUrl: './logout-all-clients.component.html',
  styleUrls: ['./logout-all-clients.component.scss']
})
export class LogoutAllClientsComponent implements OnInit {
  isLoading = true;
  urls:LogoutUrl[] = [];
  constructor(public configService: ConfigService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              matIconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('custom-icon-success', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/success.svg'));
  }

  ngOnInit(): void {
    this.logoutAll();
  }

  // - получить список урлов на выход из ИС
  // - открыть все урлы во всплывашках
  logoutAll(): void {
    this.isLoading = true;
    this.authService.getLogoutUrls()
      .subscribe(urls => {
          this.urls = urls;
          this.isLoading = false;
          if (this.urls.length > 0) {
            this.urls.forEach(x=> x.isLogouted = false);
            this.popupLogout(0);
          } else {
            this.authService.logout();
          }
        },
        (err: HttpErrorResponse) => {
          console.error(err);
        }
      );
  }
  // Во всплывашке откроем страницу выхода из ИС, рекурсивно каждые popupCloseTimeout мс
  popupLogout(index: number) {
    var popup = window.open(this.urls[index].url, '', 'width=,height=,resizable=no');
    popup?.resizeTo(0, 0);
    popup?.moveTo(0, window.screen.availHeight + 10);
    setTimeout(() => {
      this.urls[index].isLogouted = true;
      popup?.close();
      if (index == (this.urls.length - 1)) {
        // конец, перейти на страницу входа
        this.authService.logout();
      } else {
        this.popupLogout(++index);
      }
    }, this.authService.popupCloseTimeout);
  }
}
