export enum AccountStatusEnum {
  NotApproved = 1,
  Approved = 2,
  RequestPasswordChange = 3,
  RequestDataChange = 4,
  ///
  /// Подтверждение отменено
  ///
  ConfirmationCanceled = 5,
  ///
  /// Запись удалена
  ///
  AccountDeleted = 6,
  ///
  /// Почта подтверждена
  ///
  EmailConfirmed = 7,
  ///
  /// Ожидает подтверждения электронной почты
  ///
  AwaitingEmailConfirmation = 8,
  ///
  /// Ожидает подтверждения контролером
  ///
  AwaitingConfirmationController = 9,
  ///
  /// УЗ восстановлена
  ///
  AccountRestored = 10,
  ///
  /// Отказано в подтверждении
  ///
  FinalConfirmationDenied = 11,
  ///
  /// Не подтвержден (истек срок подтверждения почты)
  ///
  MailNotConfirmed = 12,
  ///
  /// Ожидает подтверждения телефона
  ///
  AwaitingPhoneConfirmation = 13,
  ///
  /// Телефон подтвержден
  ///
  PhoneConfirmed = 14
}
