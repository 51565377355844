import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

export interface IActivatePhoneDialogModel {
  accountId: number,
  accountType: string,
  phone: string
}
/**
 * @title Окно активации телефона через сервис FlashCall от Плюсофона
 * @callExpireSeconds время жизни сессии, в течении которого можно позвонить пока телефон не устарел(по умолчанию 120 секунд)
 * @callTimerVal оставшееся время сессии
 */
@Component({
  selector: 'app-activate-phone-dialog',
  templateUrl: './activate-phone-dialog.component.html',
  styleUrls: ['./activate-phone-dialog.component.scss']
})
export class ActivatePhoneDialogComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  callExpireSeconds: number;
  callTimerVal: number;
  monitorPhoneConfirmedInterval = 5;
  isConfirmSuccess = false;
  isLoading = true;
  flashCallPhone: string = "000000000";

  constructor(
    private dialogRef: MatDialogRef<ActivatePhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IActivatePhoneDialogModel,
    private authService: AuthService,
    configService: ConfigService
  ) {
    this.callExpireSeconds = this.callTimerVal = configService.customization.getOptionNumber(CodeEnum.FLASHCALL_EXPIRE_SECONDS);
  }

  ngOnInit(): void {
    this.isPhoneConfirmed();
    this.onRetryClick()
    this.monitorPhoneConfirmation();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onCloseClick() {
    this.dialogRef.close(this.isConfirmSuccess);
  }

  onRetryClick() {
    this.getFlashCallPhone();
    this.callTimerVal = this.callExpireSeconds;
  }

  /**
   * Запрос номера FlashCall для обратного звонка
   */
  getFlashCallPhone(): void {
    this.isLoading = true;

    let sub = this.authService.getFlashCall().subscribe(res => {
      this.flashCallPhone = res;
    }, _ => { }, () => { this.isLoading = false; });

    this.subs.add(sub);
  }

  /**
   * По таймеру проверять на бэке, прошло ли подтверждение телефона
   */
  monitorPhoneConfirmation(): void {
    let monitoringTimer = timer(this.monitorPhoneConfirmedInterval * 1000, this.monitorPhoneConfirmedInterval * 1000);
    let sub = monitoringTimer.subscribe(t => {
      if (this.callTimerVal > 0 && !this.isConfirmSuccess) this.isPhoneConfirmed();
    });
    this.subs.add(sub);
  }
  isPhoneConfirmed() {
    let sub = this.authService.isPhoneConfirmed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.isConfirmSuccess = true;
      }
    });
    this.subs.add(sub);
  }

}
