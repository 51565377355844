import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  backendUrl: string;

  constructor(
    private http: HttpClient,
    appsettings: AppsettingsConfig
  ) {
    this.backendUrl = appsettings.backendUrl;
  }

  getSystems(id: number): Observable<any> {
    return this.http.post(`${this.backendUrl}/settings/GetSystems`, id);
  }
}
