/**
 * Общая информация по пользователю для шлюза есиа
 */
export default class AccountUserInfo {
  /**
   * Фамилия пользователя
   */
  surname: string | null | undefined;
  /**
   * Имя пользователя
   */
  name: string | null | undefined;
  /**
   * Отчество пользователя
   */
  patronymic: string | null | undefined;
  /**
   * Выбранная организация пользователем(либо организация иностранца)
   */
  organizationName: string | null | undefined;
  /**
   * Тип аккаунта
   */
  accountType: string | null | undefined;
  /**
   * Учетка является тестовой
   */
  isTest: boolean | null | undefined;
  /**
   * Учетка подтверждена
   */
  trusted: boolean | null | undefined;
  /**
   * Доступные пользователю роли
   */
  roles: (string | null | undefined)[] = [];
  /**
   * Идентификатор ИС
   */
  clientId: number | null | undefined;
  /**
   * Идентификатор организации
   */
  orgId: number | null | undefined;
  /**
   * Идентификатор роли
   */
  roleId: number | null | undefined;
  /**
   * Почта подтверждена
   */
  isEmailConfirmed: boolean | null | undefined;
  /**
   * Телефон подтвержден
   */
  isPhoneConfirmed: boolean | null | undefined;
}
