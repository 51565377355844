import { Component,  Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OrganizationService } from '../../services/organization.service';
import { MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG } from "@angular/material/legacy-select";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { debounceTime } from 'rxjs/operators';
import { MASKS } from "../../../../core/models/masks.const";
import { SharedService } from 'src/app/core/services/shared.service';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { OrganizationType } from 'src/app/core/shared/enums/organizationType';
import { Contacts, OrgCreateModel, OrganizationListFilter, OrganizationListPaginationResponse, OrganizationModel, OrganizationsModel, Status } from '../../models/OrganozationModel';
import { deepCopy } from 'src/app/core/shared/shared';
import { ERR_MSG } from 'src/app/core/models/error-messages.const';

export interface data {
  organization?: OrganizationModel;
  type: number;
  status?: Status;
  ids: number[];
}

@Component({
  selector: 'app-organization2user-dialog',
  styleUrls: ['addOrganization2User.component.scss'],
  templateUrl: 'addOrganization2User.component.html',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: 'add-role-panel'
      }
    }
  ]
})

export class AddOrganization2UserComponent   {
  private readonly _matDialogRef: MatDialogRef<AddOrganization2UserComponent>;
  title: string = "";
  titleButton: string = "";
  check: boolean = false;
  oldOrganization?: OrganizationModel;
  organization: OrganizationModel;
  organizations: OrganizationsModel[];
  filter: data;
  orgTypeEnum = OrgTypeEnum;
  organizationType = OrganizationType;
  ERR_MSG = ERR_MSG;


  message: string = "";
  isShowNotification: boolean = false;
  isSuccess: boolean = false;

  MASKS = MASKS;
  minLenAutocomlete = 3;
  maxCountAutocomlete = 5;
  isAutocompleteLoading = false;
  isCreateMode = false;

  obligatory = "Обязательное поле";

  constructor(
    public dialogRef: MatDialogRef<AddOrganization2UserComponent>,
    public sharedService: SharedService,
    private organizationService: OrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: data
  ) {
    this._matDialogRef = dialogRef;
    this.filter = data;
    this.organizations = [];

    this.isCreateMode = !data.organization;
    this.oldOrganization = this.isCreateMode ? new OrganizationModel() : data.organization;
    this.organization = deepCopy(this.oldOrganization);

    this.title = this.isCreateMode ? "Добавить организацию" : "Редактирование данных организации";
    this.titleButton = this.isCreateMode ? "Добавить" : "Сохранить";
  }

  onSave() {
    console.info('onSave', this.organization);
    if (this.CheckFields())
      this.isCreateMode ? this.addOrganiization() : this.updateOrganiization();
  }

  CheckFields(): boolean {
    if (JSON.stringify(this.organization) === JSON.stringify(this.oldOrganization)) {
      this.setInfo(true, false, 'Данные не поменялись');
      return false;
    }

    this.setInfo(false, true, '');
    return true;
  }

  inputFilter(event: KeyboardEvent) {
    var keyCode = event.keyCode || event.which;
    if (!/[0-9]/.test(String.fromCharCode(keyCode)))
      return false;
    return true;
  }

  doFilter() {
    if (this.organization?.inn?.length >= this.minLenAutocomlete) {
      this.isAutocompleteLoading = true;

      let filter: OrganizationListFilter = {
        offset: 0,
        count: this.maxCountAutocomlete,
        search: this.organization?.inn,
        type: this.filter.type
      };

      this.organizationService.getOrganizations(filter).pipe(debounceTime(700)).subscribe((response: OrganizationListPaginationResponse) => {
        this.organizations = response.data.filter(x => !this.filter.ids.includes(x.organizationId) && this.filter.type == x.type);
        this.isAutocompleteLoading = false;
      });
    }
    else if (this.organization?.inn?.length < this.minLenAutocomlete) {
      this.organizations = [];
    }
  }

  onSelectedAutoInn(event: MatAutocompleteSelectedEvent) {
    this.organization.inn = event.option.value ? event.option.value : '';
    let org = this.organizations.find(x => x.inn == this.organization.inn);

    this.organization.kpp = org?.kpp ? org.kpp : '';
    this.organization.kppBranch = org?.kppBranch ? org.kppBranch : '';
  }

  modalClose() {
    this._matDialogRef.close();
  }

  addOrganiization() {
    let orgId = this.organization.organizationId;

    var findInn = this.organizations.filter(x => x.organizationId == this.organization.organizationId);
    if (findInn.length === 0) {
      let con: Contacts = {
        email: this.organization.email,
        phone: this.organization.phone
      };
      let org: OrgCreateModel = {
        accountId: 0,
        inn: this.organization.inn,
        contacts: con,
        type: this.filter.type,
        kpp: this.organization.kpp,
        kppBranch: this.organization.kppBranch,
        userOrgPosition: this.organization.userOrgPosition,
        chief: this.organization.chief        
      };

      this.organizationService.createOrganization(org).subscribe(id => {
        this._matDialogRef.close(id);
      }, err => {
        this.setInfo(true, false, this.sharedService.buildErrors(err.error));
      });
    }
    else
      this._matDialogRef.close(orgId);
  }

  updateOrganiization() {
    this.organizationService.updateOrganization(this.organization).subscribe(_ => {
      this._matDialogRef.close();
    });
  }

  setInfo(isShowNotification: boolean, isSuccess: boolean, message: string) {
    this.isShowNotification = isShowNotification;
    this.isSuccess = isSuccess;
    this.message = message;
  }

}
