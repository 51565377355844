import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UntypedFormBuilder } from "@angular/forms";
import { Observable } from "rxjs/internal/Observable";
import CardForm from "../models/card/card.form";
import IAccountRecoveredDto from "../models/card/IAccountStatus.model";
import { IChangeUserInfo, ProfileAccount, StatusResponseData } from 'src/app/components/card/models/userInfoModel';
import { Filter4HistoryStatus, HistoryStatusModel } from '../models/HistoryStatusModel';
import { MailModel } from '../models/card/MailModel';
import { OrganizationsModel } from 'src/app/modules/organizations/models/OrganozationModel';
import { PermissionAccountResponse } from 'src/app/modules/organizations/models/PermissionModels';
import IFileMeta from '../models/card/IFileMeta';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';


@Injectable({
  providedIn: 'root'
})
export class CardService {
  form;
  backendUrl: string;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private appsettings: AppsettingsConfig,
    private configService: ConfigService
  ) {
    // TODO:
    this.form = fb.group(new CardForm(fb, configService));
    this.backendUrl = appsettings.backendUrl;
  }

  getUserinfo(uid: string): Observable<ProfileAccount> {
    return this.http.get<ProfileAccount>(`${this.backendUrl}/card/userinfo?accountId=${uid}`);
  }

  getHistoryStatus(filter: Filter4HistoryStatus): Observable<HistoryStatusModel> {
    return this.http.get<HistoryStatusModel>(`${this.backendUrl}/account/status/list?Count=${filter.count}&Offset=${filter.offset}`);
  }

  createChangeAccountDataRequest(data: IChangeUserInfo): Observable<boolean> {
    return this.http.post<boolean>(`${this.backendUrl}/card/change-account-data`, data);
  }

  public deleteAccount(accountId: string): Observable<IAccountRecoveredDto> {
    return this.http.delete<IAccountRecoveredDto>(`${this.backendUrl}/card/manage/delete-account`);
  }

  sendActivateMail(isStranger: boolean): Observable<StatusResponseData> {
    return this.http.get<StatusResponseData>(`${this.backendUrl}/card/sendActivateMail?isStranger=${isStranger}`);
  }

  getUserOrganization(): Observable<OrganizationsModel[]> {
    return this.http.get<OrganizationsModel[]>(`${this.backendUrl}/settings/organization/account?accountId=${-1}&fullName=${""}&INN=${""}&count=${1000}&offset=${0}`);
  }

  getPermission(): Observable<PermissionAccountResponse[]> {
    return this.http.get<PermissionAccountResponse[]>(`${this.backendUrl}/settings/permission/account`);
  }

  removePermission(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.backendUrl}/settings/permission?permissionId=${id}`);
  }
}
