import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Location } from '@angular/common';
import { CookiesService, LANGUAGE_COOKIE } from 'src/app/core/services/cookies.service';
import { TranslocoService } from '@ngneat/transloco';
import { AppsettingsConfig } from '../../client-config/models/client-config';

export interface IPropertiesByLang {
  rulesPersonalUrl: string;
}

// Переменные, зависящие от выбранного языка
export const LANG_PROPERTIES: IPropertiesByLang = {
  rulesPersonalUrl: "rulesPersonalUrl"
}
@Injectable({
  providedIn: 'root',
})
export class I18nService {

  constructor(
    private appsettings: AppsettingsConfig,
    private authService: AuthService,
    private cookieService: CookiesService,
    private translocoService: TranslocoService,
    private location: Location,
    private route: ActivatedRoute,
  ) { }

  // доступна ли кнопка выбора языка
  canShowLocalizationButton() {
    if (this.location.path().indexOf('/auth/registrationStrangers') != -1 ||
      this.location.path().indexOf('/activate-account-error') != -1) {
      return true;
    }

    // Для иностранцев доступна кнопка выбора языка
    else if (this.authService.isAuthenticated() && this.authService.GetUserAccountType() == "stranger") {
      return true;
    }

    // Также если в урле указана ИС из showLocalization_ClientMnemonics на странице авторизации
    else {
      const client = this.route.snapshot.queryParamMap.get('client') ?? '';
      let isClientSupportLangs = (this.appsettings.customization.showLocalization_ClientMnemonics.lastIndexOf(client) != -1);
      return isClientSupportLangs;
    }
  }

  // Если все же не доступна кнопка, то сбрасываем куки и язык в ru
  resetLang() {
    let cookieLang = this.cookieService.getCookie(LANGUAGE_COOKIE);
    if (cookieLang != 'ru') {
      this.cookieService.saveCookie(LANGUAGE_COOKIE, 'ru');
      this.translocoService.setActiveLang('ru');
    }
  }

  /** @summary получение переменных из конфига, зависящих от языка. См. файл appsettings.json IPropertiesByLang **/
  public getValueByLang(propertyName: string): string {
    var currentLang = this.translocoService.getActiveLang();
    var val = this.appsettings.availableLangs[currentLang][propertyName] ?? '';
    return val;
  }
}
