export const MASKS = {
  inn10: '0000000000',
  kpp: '000000000',
  email:[/[a-z]/,'@', /[a-z]/,'.',/[a-z]/],
  mobPhone: '(000) 000-00-00',
  identityDocument: {
    series: '00-00',
    num: '000000',
    code: '000-000'
  },
  snils: '000-000-000 00',
  inn12: '000000000000'
}
