<div class="dialog l">
  <div class="dialog-title">
    <h3>{{'historyStatus.header' | transloco}}</h3>
    <button data-test="statusHistory_btn-close" class="close-btn" mat-dialog-close></button>
  </div>
  <div class="zit-scrollbar">
    <mat-table [dataSource]="historyStatus">
      <ng-container matColumnDef="status">
        <mat-header-cell class="mx-2" *matHeaderCellDef>{{'historyStatus.status' | transloco}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="ring-status">
            <i [ngClass]="getStatusClass(element.statusId)"></i> {{getActiveLang() == 'ru' ? element.name :
            element?.nameEn}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="data">
        <mat-header-cell *matHeaderCellDef class="td-createdat">{{'historyStatus.data' | transloco}}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="td-createdat">{{element.createdAt | date: 'dd.MM.YYYY HH:mm:ss'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="izm" *ngIf="!!editedby">
        <mat-header-cell *matHeaderCellDef class="td-editedby">Кто изменил</mat-header-cell>
        <mat-cell *matCellDef="let element" class="td-editedby">{{element.accountName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>{{'historyStatus.komment' | transloco}}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.reason}}</mat-cell>
      </ng-container>
      <mat-header-row class="mat-header-row-sticky" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator data-test="statusHistory_paginator" [ngClass]="{'d-none':total <= minPageSize}" showFirstLastButtons [pageIndex]="pageIdx"
      [pageSize]="pageSize" [pageSizeOptions]='defaultPageSizeOptions' (page)="getData($event)"></mat-paginator>
  </div>
</div>