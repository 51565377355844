import RegistrationAddress from "../userinfo/RegistrationAddress";
import IdentityDocument from "../userinfo/IdentityDocument";
import OtherDocuments from "../userinfo/OtherDocuments";
import { UntypedFormBuilder } from "@angular/forms";
import OrgData from "../userinfo/OrgData";
import CardPersonalDataModel from "./card-personal-data.model";
import { AppsettingsConfig } from "src/app/modules/client-config/models/client-config";
import { ConfigService } from "src/app/modules/client-config/services/config.service";

export default class CardForm {
  personalData;
  registrationAddress;
  identityDocument;
  otherDocuments;
  orgData;
  file;
  files;
  constructor(fb: UntypedFormBuilder, configService: ConfigService) {
    this.registrationAddress = fb.group(new RegistrationAddress());
    this.identityDocument = fb.group(new IdentityDocument());
    this.otherDocuments = fb.group(new OtherDocuments());
    this.personalData = fb.group(new CardPersonalDataModel(fb, configService));
    this.file = fb.control(null);
    this.files = fb.control([]);
    this.orgData = fb.group(new OrgData());
  }
}
