<!-- mat-stretch-tabs -->
<mat-tab-group class="mb-3"
  (selectedTabChange)="orgsCollapse = false; permissionCollapse = false; roleCollapse = false; esiaCollapse = true">
  <!--Физическое лицо-->
  <mat-tab>
    <ng-template mat-tab-label>
      <div data-test="organization4card_tab-Physical" class="col">Физическое лицо</div>
    </ng-template>
    <ng-container [ngTemplateOutlet]="organizationContent"
      [ngTemplateOutletContext]="{orgType: orgTypeEnum.Physical}"></ng-container>
  </mat-tab>
  <!--Юридическое лицо-->
  <mat-tab>
    <ng-template mat-tab-label>
      <div data-test="organization4card_tab-legal" class="col">Юридическое лицо</div>
      <span class="ms-1 org-count"
        *ngIf="getOrganizationCount(orgTypeEnum.Legal) > 0">{{getOrganizationCount(orgTypeEnum.Legal)}}</span>
      <svg-icon data-test="organization4card_btn-add-organization-legal" key="addSmallBlue" *ngIf="!!showProfileAddOrganizationBtn && accStatus?.status != accStatusEnum.AccountDeleted" 
        class="ms-2" (click)="addOrganization(orgTypeEnum.Legal)" class="pointer"></svg-icon>
    </ng-template>
    <ng-container [ngTemplateOutlet]="organizationContent"
      [ngTemplateOutletContext]="{orgType: orgTypeEnum.Legal}"></ng-container>
  </mat-tab>
  <!--Филиал юридического лица-->
  <mat-tab>
    <ng-template mat-tab-label>
      <div data-test="organization4card_tab-LegalBranch" class="col">Филиал ЮЛ</div>
      <span class="ms-1 org-count" 
        *ngIf="getOrganizationCount(orgTypeEnum.LegalBranch) > 0">{{getOrganizationCount(orgTypeEnum.LegalBranch)}}</span>
      <svg-icon data-test="organization4card_btn-add-organization-legal-brunch" key="addSmallBlue" *ngIf="!!showProfileAddOrganizationBtn && accStatus?.status != accStatusEnum.AccountDeleted" 
        class="ms-2" (click)="addOrganization(orgTypeEnum.LegalBranch)" class="pointer"></svg-icon>
    </ng-template>
    <ng-container [ngTemplateOutlet]="organizationContent"
      [ngTemplateOutletContext]="{orgType: orgTypeEnum.LegalBranch}">
    </ng-container>
  </mat-tab>
  <!--Индивидуальный предприниматель-->
  <mat-tab>
    <ng-template mat-tab-label>
      <div data-test="organization4card_tab-Business" class="col">ИП</div>
      <span class="ms-1 org-count" 
        *ngIf="getOrganizationCount(orgTypeEnum.Business) > 0">{{getOrganizationCount(orgTypeEnum.Business)}}</span>
      <svg-icon data-test="organization4card_btn-add-organization-business" key="addSmallBlue" *ngIf="!!showProfileAddOrganizationBtn && accStatus?.status != accStatusEnum.AccountDeleted" 
        class="ms-2" (click)="addOrganization(orgTypeEnum.Business)" class="pointer"></svg-icon>
    </ng-template>
    <ng-container [ngTemplateOutlet]="organizationContent"
      [ngTemplateOutletContext]="{orgType: orgTypeEnum.Business}"></ng-container>
  </mat-tab>
  <!--Орган исполнительной власти-->
  <mat-tab>
    <ng-template mat-tab-label>
      <div data-test="organization4card_tab-Agency" class="col">Орган исполнительной власти</div>
      <span class="ms-1 org-count"
        *ngIf="getOrganizationCount(orgTypeEnum.Agency) > 0">{{getOrganizationCount(orgTypeEnum.Agency)}}</span>
      <svg-icon data-test="organization4card_btn-add-organization-agency" key="addSmallBlue" *ngIf="!!showProfileAddOrganizationBtn && accStatus?.status != accStatusEnum.AccountDeleted" 
      class="ms-2" (click)="addOrganization(orgTypeEnum.Agency)" class="pointer"></svg-icon>
    </ng-template>
    <ng-container [ngTemplateOutlet]="organizationContent"
      [ngTemplateOutletContext]="{orgType: orgTypeEnum.Agency}"></ng-container>
  </mat-tab>
</mat-tab-group>


<!-- ШАБЛОН вкладок с организациями -->
<ng-template #organizationContent let-orgType="orgType">
  <div class="wrapper-container">
    <div data-test="organization4card_btn-update-organization-list" class="all-collapse" (click)="updateOrganizationList()" (click)="orgsCollapse = !orgsCollapse">
      <span>Свернуть всё</span>
      <svg-icon key="arrowDown" [ngClass]="{ active: !orgsCollapse }" class="ms-2 button-dropdown"></svg-icon>
    </div>
    <div class="header-line"></div>
    <div *ngFor="let organization of getOrgsByType(orgType); let i = index">
      <div *ngIf="!orgsCollapse">
        <div class="header-title mt-3" [class]="organization.status.internalCode">
          <svg-icon data-test="organization4card_btn-organization-is-collapse" key="arrowDown" (click)="organization.isCollapse = !organization.isCollapse"
            [ngClass]="{ active: !organization.isCollapse }" class="me-3 button-dropdown"></svg-icon>
          <h3 class="mb-0">
            {{ organizationService.getOrgTypeName(orgType) }}
            <span *ngIf="orgType != orgTypeEnum.Physical">{{i+1}}</span>
          </h3>
          <mat-icon *ngIf="orgType != orgTypeEnum.Physical" class="ms-3 me-2"
            [svgIcon]="getOrgStatusIcon(organization.status.internalCode)"></mat-icon>
          <div *ngIf="orgType != orgTypeEnum.Physical">{{this.getOrgTypeName(organization.status.internalCode)}}</div>

          <app-zit-auto-open-menu class="ms-2"
            *ngIf="organization.status.internalCode == organizationType.NOT_VERIFIED">
            <svg-icon trigger key="question" class="s"></svg-icon>
            <div content>
              <h5 class="mb-1">Проверка не пройдена</h5>
              <div>Не удалось получить данные из {{ orgType == orgTypeEnum.Business ? "ЕГРИП" : "ЕГРЮЛ" }}.
                Проверьте правильность и скорректируйте данные по ИНН
                {{ orgType == orgTypeEnum.Business
                ? "" : orgType == orgTypeEnum.LegalBranch ? "или КПП филиала" : "или КПП" }}
              </div>
            </div>
          </app-zit-auto-open-menu>
          <div class="ms-auto">
            <svg-icon data-test="organization4card_btn-edit-organization" key="edit" 
              *ngIf="!!showProfileEditOrganizationBtn && organization.type != orgTypeEnum.Physical && accStatus?.status != accStatusEnum.AccountDeleted"
              (click)="onEditOrganization(organization)" class="me-3 pointer"></svg-icon>
            <svg-icon data-test="organization4card_btn-remove-organization" key="buttonRemove" (click)="onRemoveOrganization(organization)"
              *ngIf="!!showProfileDeleteOrganizationBtn && accStatus?.status != accStatusEnum.AccountDeleted" class="pointer"></svg-icon>
          </div>
        </div>

        <div *ngIf="!organization.isCollapse && orgType != orgTypeEnum.Physical" class="d-flex flex-row mb-3">
          <mat-slide-toggle data-test="organization4card-toggle-is-chief" class="w-auto opacity-100" [checked]="getPropertyBoolean(organization.permissionProperties, permissionPropertyNameEnum.Chief)" [disabled]="true">
            <div>Руководитель организации</div>
          </mat-slide-toggle>
          <app-zit-auto-open-menu class="ms-2 ps-0">
            <svg-icon trigger key="question" class="s"></svg-icon>
            <div content>
              <p>Сведения о том, является ли сотрудник руководителем организации</p>
            </div>
          </app-zit-auto-open-menu>
        </div>

        <div *ngIf="!organization.isCollapse && orgType != orgTypeEnum.Physical" class="row g-3">
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Должность пользователя в организации</mat-label>
            <input data-test="organization4card_input-position" matInput required disabled
              [value]="getProperty(organization.permissionProperties, permissionPropertyNameEnum.Position)" />
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>ИНН</mat-label>
            <input data-test="organization4card_input-inn" matInput [(ngModel)]="organization.inn" [ngModelOptions]="{ standalone: true }" required disabled />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill"
            *ngIf="organization.type == orgTypeEnum.Legal || organization.type == orgTypeEnum.Agency ">
            <mat-label>КПП</mat-label>
            <input data-test="organization4card_input-kpp" matInput [(ngModel)]="organization.kpp" required disabled />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill" *ngIf="organization.type === orgTypeEnum.LegalBranch">
            <mat-label>КПП</mat-label>
            <input data-test="organization4card_input-kpp-brunch" matInput [(ngModel)]="organization.kppBranch" required disabled />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>ОГРН</mat-label>
            <input data-test="organization4card_input-ogrn" matInput [(ngModel)]="organization.ogrn" [ngModelOptions]="{ standalone: true }" required
              [disabled]="true" />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-5" appearance="fill">
            <mat-label>Организационно-правовая форма</mat-label>
            <input data-test="organization4card_input-organization-opf" matInput required [disabled]="true" />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-4" appearance="fill">
            <mat-label>Полное наименование</mat-label>
            <input data-test="organization4card_input-organization-full-name" matInput [(ngModel)]="organization.fullName" [ngModelOptions]="{ standalone: true }" required
              [disabled]="true" />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Краткое наименование</mat-label>
            <input data-test="organization4card_input-organization-short-name" matInput [(ngModel)]="organization.shortName" [ngModelOptions]="{ standalone: true }" required
              [disabled]="true" />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Юридический адрес</mat-label>
            <input data-test="organization4card_input-organization-address" matInput [(ngModel)]="organization.address" [ngModelOptions]="{ standalone: true }" [disabled]="true"
              required />
            <ng-container matSuffix class="m-suffix" [ngTemplateOutlet]="orgStatusFieldIcon"
              [ngTemplateOutletContext]="{$orgStatus: organization.status.internalCode}">
            </ng-container>
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Телефон пользователя в организации</mat-label>
            <input data-test="organization4card_input-organization-phone" matInput [value]="getProperty(organization.permissionProperties, permissionPropertyNameEnum.Phone)"
              required disabled />
          </mat-form-field>
          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Электронная почта пользователя в организации</mat-label>
            <input data-test="organization4card_input-organization-email" matInput [value]="getProperty(organization.permissionProperties, permissionPropertyNameEnum.Email)"
              required disabled />
          </mat-form-field>
        </div>


        <div *ngIf="!organization.isCollapse" class="header-title my-3 p-0">
          <svg-icon data-test="organization4card_btn-permission-collapse" key="arrowDown" (click)="permissionCollapse = !permissionCollapse" class="button-dropdown ms-2 me-3"
            [ngClass]="{ active: !permissionCollapse }"></svg-icon>
          <h4 class="mb-0">Роли</h4>
          <app-zit-auto-open-menu class="ms-2">
            <svg-icon trigger key="question" class="s"></svg-icon>
            <div content>
              <div class="pathBit isAuto mb-3">Группы ЕСИА</div><br>
              <div class="pathBit mb-3">Роли, присвоенные администратором</div><br>
              <div class="pathBit isInherit">Роли наследуемые</div>
            </div>
          </app-zit-auto-open-menu>
        </div>

        <div *ngIf="!permissionCollapse && !organization.isCollapse">
          <div *ngFor="let client of getClientsByOrg(organization); let i = index" class="bordered r-1 p-3">
            <div class="d-flex">
              <svg-icon [attr.data-test]="'organization4card_btn-client-is-collapse' + i" key="arrowDown" (click)="client.isCollapse = !client.isCollapse" class="button-dropdown me-3"
                [ngClass]="{ active: !client.isCollapse }"></svg-icon>
              <h5>{{ client.name }}</h5>
            </div>
            <div *ngIf="!client.isCollapse" class="mt-2 d-flex flex-wrap gap-3">
              <div *ngFor="let role of getRolesByClientOrg(client, organization)" class="pathBit"
                [ngClass]="{'isInherit':role.isInherit, 'isAuto':role.isAuto}">
                {{ role.name }}
              </div>
            </div>
          </div>
          <div *ngIf="!getClientsByOrg(organization)?.length" class="bordered p-3">Роли отсутствуют</div>
        </div>
      </div>
    </div>
    <div *ngIf="!hasOrgs(orgType)" class="mt-2">
      Нет данных
    </div>
  </div>
</ng-template>




<!-- ШАБЛОН иконки статуса  -->
<ng-template #orgStatusFieldIcon let-$orgStatus="$orgStatus">
  <svg-icon matTooltip="Проверка пройдена" *ngIf="$orgStatus == organizationType.VERIFIED" matSuffix hint
    key="statusOk"></svg-icon>
  <svg-icon matTooltip="Проверка не пройдена" *ngIf="$orgStatus == organizationType.NOT_VERIFIED" matSuffix hint
    key="attention"></svg-icon>
  <svg-icon matTooltip="На проверке" *ngIf="$orgStatus == organizationType.ON_VERIFICATION" matSuffix hint
    key="statusVarn"></svg-icon>
</ng-template>