import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import CustomValidators, {
  pwdValidator,
} from '../../../core/models/custom-validators.model';
import RegistrationAddress from 'src/app/core/models/userinfo/RegistrationAddress';
import { GenderEnum } from 'src/app/core/shared/enums/gender.enum';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { IdentityDocumentTypeEnum } from 'src/app/core/models/userinfo/identity-document-type.enum';
import IdentityDocumentTypeConverter from 'src/app/core/models/userinfo/identity-document-type.converter';
import { USERINFO_FORM_CONTROL_VALIDATORS } from 'src/app/core/models/userinfo/userinfo-form-control-validators.const';
import { ConfigService } from '../../client-config/services/config.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

let CV = CustomValidators;
let V = Validators;

export class IdentityDocument {
  docType = new UntypedFormGroup(
    {
      id: new UntypedFormControl(IdentityDocumentTypeEnum.RussianPassport),
      name: new UntypedFormControl({
        value: IdentityDocumentTypeConverter.convert(
          IdentityDocumentTypeEnum.RussianPassport
        ),
        disabled: true,
      }),
    },
    V.required
  );
  series = ['', [V.required, CV.onlyNum, CV.passportSeriesLen]];
  number = ['', [V.required, CV.onlyNum, CV.passportNumLen]];
  issuedBy = ['', V.required];
  issueDate = ['', V.required];
  issueId = ['', [V.required, CV.onlyNum, CV.passportCodeLen]];
  birthplace = ['', V.required];
  citizenship = ['', V.required];
}
export class RegServiceData {
  password = [
    '',
    [
      V.required,
      CV.pwdLength,
      CV.pwdNumbers,
      CV.pwdSymbol,
      CV.pwdLowercase,
      CV.pwdUppercase,
    ],
  ];
  verifyPwd = ['', V.required];
  isAcceptPrivacyPolicy = [false, V.requiredTrue];
}

export class RegPersonalData {
  constructor(
    fb: UntypedFormBuilder, 
    configService: ConfigService,
    public email: any = ['', configService.customization.getOptionBool(CodeEnum.EMAIL_CHECK_RU_DOMAIN) ? USERINFO_FORM_CONTROL_VALIDATORS.emailRu : USERINFO_FORM_CONTROL_VALIDATORS.email],
    public surname: any = ['', V.required],
    public name: any = ['', V.required],
    public patronymic: any = [''],
    public gender: any = [GenderEnum.Male, V.required],
    public birthdate: any = ['', V.required],
    public phoneNumber: any = ['', [V.required, CV.onlyNum, CV.mobPhoneLen]],
    public registrationAddress = fb.group(new RegistrationAddress()),
    public identityDocument = fb.group(new IdentityDocument()),
    public snils = ['', [V.required, CV.onlyNum, CV.snilsLen]],
    public inn = ['', [V.required, CV.onlyNum, CV.personInnLen]]
   ){}
}

export default class RegistrationForms {
  public organizations: UntypedFormArray;
  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigService,
    public personalData = fb.group(new RegPersonalData(fb, configService)),
    public serviceData = fb.group(new RegServiceData(), {
      validators: pwdValidator,
    })
  ) {
    this.organizations = fb.array([]);
  }
}
export interface IRegOrganization {
  organizaitionId: number;
  orgType: OrgTypeEnum;
  fullName:string;
  inn: number;
  kpp: number;
  kppBranch: number;
}
export function createOrg(fb: UntypedFormBuilder, orgType: OrgTypeEnum): UntypedFormGroup {
  return fb.group({
    orgType: [orgType, V.required],
    userOrgPosition: [
      '',
      hasField('userOrgPosition', orgType) ? V.required : [],
    ],
    inn: [
      '',
      hasField('inn', orgType)
        ? [
            V.required,
            CV.onlyNum,
            orgType == OrgTypeEnum.Business
              ? CV.personInnLen
              : CV.legalEntityInnLen,
          ]
        : [],
    ],
    kpp: ['', hasField('kpp', orgType) ? [V.required, CV.kppLen] : []],
    phoneNumber: ['', hasField('phoneNumber', orgType) ? V.required : []],
    email: ['', hasField('email', orgType) ? USERINFO_FORM_CONTROL_VALIDATORS.email : []],
    kppBranch: [
      '',
      hasField('kppBranch', orgType) ? [V.required, CV.kppLen] : [],
    ],
    chief: [false, []],
  });
}

class fieldsByOrgType {
  public static userOrgPosition = [
    OrgTypeEnum.Business,
    OrgTypeEnum.Legal,
    OrgTypeEnum.LegalBranch,
    OrgTypeEnum.Agency,
  ];
  public static inn = [
    OrgTypeEnum.Business,
    OrgTypeEnum.Legal,
    OrgTypeEnum.LegalBranch,
    OrgTypeEnum.Agency,
  ];
  public static kpp = [
    OrgTypeEnum.Legal,
    OrgTypeEnum.LegalBranch,
    OrgTypeEnum.Agency,
  ];
  public static phoneNumber = [
    OrgTypeEnum.Business,
    OrgTypeEnum.Legal,
    OrgTypeEnum.LegalBranch,
    OrgTypeEnum.Agency,
  ];
  public static email = [
    OrgTypeEnum.Business,
    OrgTypeEnum.Legal,
    OrgTypeEnum.LegalBranch,
    OrgTypeEnum.Agency,
  ];
  public static kppBranch = [OrgTypeEnum.LegalBranch];
}

/**
 * @summary Проверка должно ли быть поле, для данного типа организации
 */
export function hasField(field: string, orgType: OrgTypeEnum) {
  return (
    (fieldsByOrgType[field as keyof fieldsByOrgType] as OrgTypeEnum[]).indexOf(
      orgType
    ) != -1
  );
}
