import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';

@NgModule({
  imports: [MatToolbarModule, TranslocoRootModule, RouterModule, BrowserModule],
  exports: [AppFooterComponent],
  declarations: [AppFooterComponent],
})
export class AppFooterModule {}
