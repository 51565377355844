import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { AlignItems } from '../../sharedTypes/alignItems';

enum Direction {
  horizontal,
  vertical
}

enum DirectionOrder {
  straight,
  reverse
}

enum JustifyContent {
  start,
  end,
  center,
  spaceBetween,
  spaceArround
}

@Component({
  selector: 'app-flex-container',
  templateUrl: './flex-container.component.html',
  styleUrls: ['./flex-container.component.scss']
})
export class FlexContainerComponent {
  @Input() isHorizontal = true;
  @Input() isVertical = false;

  @Input() isReverse = false;
  @Input() isStraight = true;

  @Input() isJustifyStart = false;
  @Input() isJustifyEnd = false;
  @Input() isJustifyCenter = false;
  @Input() isJustifySpaceBetween = true;
  @Input() isJustifySpaceArround = false;

  @Input() isAlignItemsStart = false;
  @Input() isAlignItemsEnd = false;
  @Input() isAlignItemsCenter = false;
  @Input() isAlignItemsStretch = true;
  @Input() isAlignItemsBaseline = false;

  get direction(): Direction {
    return this.isVertical ? Direction.vertical : Direction.horizontal;
  }
  get directionOrder(): DirectionOrder {
    return this.isReverse ? DirectionOrder.reverse : DirectionOrder.straight;
  }

  get justifyContent(): JustifyContent {
    return this.isJustifyStart
      ? JustifyContent.start
      : this.isJustifyEnd
      ? JustifyContent.end
      : this.isJustifyCenter
      ? JustifyContent.center
      : this.isJustifySpaceArround
      ? JustifyContent.spaceArround
      : JustifyContent.spaceBetween;
  }

  get alignItems(): AlignItems {
    return this.isAlignItemsStart
      ? AlignItems.start
      : this.isAlignItemsEnd
      ? AlignItems.end
      : this.isAlignItemsCenter
      ? AlignItems.center
      : this.isAlignItemsBaseline
      ? AlignItems.baseline
      : AlignItems.stretch;
  }

  get classes(): string[] {
    const result = ['flex-container'];
    result.push(
      this.direction === Direction.horizontal
        ? 'horizontal'
        : 'vertical'
    );
    if (this.directionOrder === DirectionOrder.reverse) {
      result.push('reverse');
    }
    result.push(
      this.justifyContent === JustifyContent.start
        ? 'justify-start'
        : this.justifyContent === JustifyContent.end
        ? 'justify-end'
        : this.justifyContent === JustifyContent.center
        ? 'justify-center'
        : this.justifyContent === JustifyContent.spaceArround
        ? 'justify-space-around'
        : 'justify-space-between'
    );
    result.push(
      this.alignItems === AlignItems.start
        ? 'align-items-start'
        : this.alignItems === AlignItems.end
        ? 'align-items-end'
        : this.alignItems === AlignItems.center
        ? 'align-items-center'
        : this.alignItems === AlignItems.baseline
        ? 'align-items-baseline'
        : 'align-items-stretch'
    );
    return result;
  }
}
