<div class="page-error">
  <h1 class="landing">{{'server-error.title' | transloco}}</h1>
  <h2 class="text-center translate">{{'server-error.secondTitle' | transloco}}</h2>

  <div class="text">
    <p class="text-bold mb-2 translate">{{'server-error.try' | transloco}}</p>
    <ul>
      <li class="translate">{{'server-error.listLine1' | transloco}}</li>
      <li class="translate">{{'server-error.listLine2' | transloco}}</li>
      <li class="translate">{{'server-error.listLine3' | transloco}}</li>
      <li class="translate">{{'server-error.listLine4' | transloco}}</li>
    </ul>
  </div>

  <button class="primary xl text-center w-100 mb-4" mat-stroked-button disableRipple
    [routerLink]="['/profile']">{{'server-error.button' | transloco}}</button>

  <p class="text-bold mb-4">{{'server-error.notFound' | transloco}}</p>

  <app-contacts-info></app-contacts-info>

  <div class="error translate">{{'server-error.error' | transloco}} {{this.code}} {{this.error}}. {{this.message}}</div>
</div>