<header class="header">
  <div routerLink="/" class="header-logo">
    <img src="assets/shared/img/wappen.svg">
    <div class="header-logo-text">
      <h1 class="header-logo-text-first">{{'header-mini.title-1' | transloco}}<br>{{'header-mini.title-2' | transloco}}</h1>
      <h1 class="header-logo-text-second">{{'header-mini.title-3' | transloco}}</h1>
      <h1 class="header-logo-text-third">{{'header-mini.title-4' | transloco}}</h1>
    </div>
  </div>
  <app-i18n class="i18n"></app-i18n>
  <app-user-card class="app-user-card" [headerMaxi]="true"></app-user-card>
</header>
