export const ERR_MSG = {
  required: 'Обязательное поле',
  email: 'Введите корректный электронный адрес',
  emailRu: 'Почта должна быть зарегистрированной в российских сервисах',
  inn: 'Введите 10 или 12 цифр ИНН',
  personInn: 'Введите 12 цифр ИНН',
  legalEntityInn: 'Введите 10 цифр ИНН',
  snils: 'Введите 11 цифр СНИЛС',
  ogrn: 'Введите 13 цифр ОГРН или 15 цифр ОГРНИП',
  pattern: {
    onlyNum: 'Может содержать только цифры'
  },
  identityDocument: {
    series: 'Введите 4 цифры серии паспорта',
    num: 'Введите 6 цифр номера паспорта',
    code: 'Введите 6 цифр кода подразделения'
  },
  mobPhoneLen: 'Введите 10 цифр мобильного телефона',
  phoneLen: 'Введите от 5 до 11 цифр телефона',
  kppLen: 'Введите 9 знаков КПП',
  kppFilLen: 'Введите 9 знаков КПП филиала'
}
