import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from "../../../../../../auth/services/auth.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ConfigService } from "../../../../../../client-config/services/config.service";
import { OAuthService } from 'src/app/modules/auth/services/oauth.service';
import AccountUserInfo from 'src/app/core/models/AccountUserInfo';


@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {
  faSignIn = faSignInAlt;
  faSignOut = faSignOutAlt;
  faUserCircle = faUserCircle;

  toggleSupport: boolean = false;
  toggleAccount: boolean = false;

  @Input() headerMaxi: boolean = false;

  constructor(
    public configService: ConfigService,
    public authService: AuthService,
    public userInfo: AccountUserInfo,
    public oAuthService: OAuthService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.toggleSupport = false;
      this.toggleAccount = false;
    }
  }

  logout() {
    this.authService.logout();
  }

  onProfileNameClick() {
    this.router.navigate(['/profile']).then();
  }
  onSystemsRedirectClick() {
    this.router.navigate(['/systemRedirect']).then();
  }
  showSpecialistButton() {
    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    return this.oAuthService.isClientCanSpecialistSingIn(client);
  }
}
