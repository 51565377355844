import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ERR_MSG } from "../../../core/models/error-messages.const";
import { genderList } from "../../../core/shared/gender-list.const";
import { CardService } from "../../../core/services/card.service";
import { RecordModel, SharedService } from 'src/app/core/services/shared.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { POINTED_DATE_FORMATS } from "../../../core/models/pointed-date-formats.const";
import { AccountTypeEnum } from "../../../core/shared/enums/account-type.enum";
import { MASKS } from "../../../core/models/masks.const";
import { SettingsService } from "../../../core/services/settings.service";
import { SystemModel, RoleModel } from '../../../modules/organizations/models/SystemModel';
import { PermissionAccountResponse, PermissionRoleListResponse, PermissionTypeCode } from '../../../modules/organizations/models/PermissionModels';
import { OrganizationService } from '../../../modules/organizations/services/organization.service';
import { AccountStatusEnum } from 'src/app/core/models/account-status.enum';
import { AddOrganization2UserComponent } from 'src/app/modules/organizations/components/addOrganization2User/addOrganization2User.component';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { OrganizationModel, OrganizationsModel, PermissionProperty, PermissionPropertyNameEnum } from 'src/app/modules/organizations/models/OrganozationModel';
import { OrganizationType } from 'src/app/core/shared/enums/organizationType';
import IFileMeta from 'src/app/core/models/card/IFileMeta';

import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

interface Memory {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dob: string;
  mobPhone: string;
  email: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  docType: number;
  series: string;
  num: string;
  issueDate: string;
  code: string;
  issuer: string;
  birthplace: string;
  citizenship: string;
  snils: string;
  inn: string;
}

interface SettingsSystems {
  id: number;
  identifier?: string;
  clientSecret?: string;
  name: string;
  url?: string;
  redirectUrls: string[];
  signoutUrls: string[];
  mnemonic?: string;
  autoApproval: boolean;
  redirectOnError: boolean;
  fullName?: string;
  file: []
}

export default class AccountStatus {
  constructor(
    public status: AccountStatusEnum,
    public name: string
  ) {
  }
}

@Component({
  selector: 'app-organization4card',
  templateUrl: './organization4card.component.html',
  styleUrls: ['./organization4card.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: POINTED_DATE_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru'
    }
  ]
})
export class Organization4cardComponent implements OnInit {
  orgsCollapse: boolean = false;
  permissionCollapse: boolean = false;
  roleCollapse: boolean = false;
  roleESIACollapse: boolean = false;
  esiaCollapse: boolean = false;

  isLoading = true;
  isAccountConfirmationBtnActive = false;
  userUid = '0';
  maxDate = new Date();
  form;
  ERR_MSG = ERR_MSG;
  MASKS = MASKS;
  orgTypeEnum = OrgTypeEnum;
  permissionPropertyNameEnum = PermissionPropertyNameEnum;
  organizationType = OrganizationType
  accTypeEnum = AccountTypeEnum;
  accTypes$;
  orgAndLegalForms$;
  genderList = genderList;
  @Input() isEsiaUser: boolean = false;
  roles: PermissionRoleListResponse[] = [];
  addedRoles: RoleModel[] = [];
  deletedRoles: RoleModel[] = [];
  memory: Memory | null = null;
  displayedColumns: string[] = [
    'docType',
    'fileName',
    'size',
    'uploadDate',
    'actions'
  ];
  ds: IFileMeta[] = [];
  isUploadedFiles = false;
  @Input() accStatus: AccountStatus | null = null;
  accStatusEnum = AccountStatusEnum;
  accRole: RecordModel | null = null;
  isTest = false;
  isApproved = false;
  isActivated = false;
  changeAccountDataDate: string | null = null;
  getOrgTypeName: Function;
  userOrganization: OrganizationsModel[] = [];
  clients: SystemModel[] = [];
  permissions: PermissionAccountResponse[] = [];

  showProfileAddOrganizationBtn = false;
  showProfileEditOrganizationBtn = false;
  showProfileDeleteOrganizationBtn = false;

  fakeSys = true;
  setFakeSys() {
    this.fakeSys = false;
  }

  constructor(
    private dialog: MatDialog,
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private cardService: CardService,
    private sharedService: SharedService,
    public organizationService: OrganizationService,
    private settingsService: SettingsService,
    configService: ConfigService
  ) {
    this.getOrgTypeName = sharedService.getOrgTypeName;
    matIconRegistry.addSvgIcon('orgOnVerification',
      sanitizer.bypassSecurityTrustResourceUrl('assets/platform-zit-material/img/org-on-verification.svg'));
    matIconRegistry.addSvgIcon('orgNotVerified',
      sanitizer.bypassSecurityTrustResourceUrl('assets/platform-zit-material/img/org-not-verified.svg'));
    matIconRegistry.addSvgIcon('orgVerified',
      sanitizer.bypassSecurityTrustResourceUrl('assets/platform-zit-material/img/ogr-verified.svg'));
    matIconRegistry.addSvgIcon('orgDraft',
      sanitizer.bypassSecurityTrustResourceUrl('assets/platform-zit-material/img/org-draft.svg'));

    this.accTypes$ = sharedService.getAccountTypes();
    this.orgAndLegalForms$ = this.sharedService.getOrgAndLegalForms();
    this.form = cardService.form;
    this.showProfileAddOrganizationBtn = configService.customization.getOptionBool(CodeEnum.ADD_ORG);
    this.showProfileEditOrganizationBtn = configService.customization.getOptionBool(CodeEnum.EDIT_ORG);
    this.showProfileDeleteOrganizationBtn = configService.customization.getOptionBool(CodeEnum.DEL_ORG);
  }

  ngOnInit(): void {
    this.settingsService.getSystems(0).subscribe((res) => {
        this.clients = res.map((x: SettingsSystems) => new SystemModel(x.id, x.name, []) );
        this.updateOrganizationList();
    });
  }

  getOrganizationCount(type: OrgTypeEnum) {
    var count = this.getOrgsByType(type)?.length;
    return count ?? 0;
  }

  // TODO переписать метод, надо удалять все связи с организацией на стороне бэка, а не посылать связь с фронта(тут удаляется только первая связь, а не все)!
  onRemoveOrganization(organization: OrganizationsModel) {
    var perm = this.permissions.filter(x => x.organization.id == organization.organizationId && x.typeCode === PermissionTypeCode.AccessUser2Organization);
    if (perm.length > 0) {
      this.cardService.removePermission(perm[0].permissionId).subscribe(_ => {
        this.updateOrganizationList();
      });
    }
  }

  onEditOrganization(organization: OrganizationsModel) {
    let org = new OrganizationModel(organization.organizationId,
      organization.inn,
      this.getProperty(organization.permissionProperties, this.permissionPropertyNameEnum.Position),
      this.getProperty(organization.permissionProperties, this.permissionPropertyNameEnum.Email),
      this.getProperty(organization.permissionProperties, this.permissionPropertyNameEnum.Phone),
      organization.kpp,
      organization.kppBranch,
      this.userUid,
      organization.type,
      this.getPropertyBoolean(organization.permissionProperties, this.permissionPropertyNameEnum.Chief)
    );

    const dialogRef = this.dialog.open(AddOrganization2UserComponent, {
      data: {
        organization: org,
        type: organization.type,
        status: organization.status,
        ids: this.userOrganization.filter(x => x.type == organization.type).map(x => x.organizationId)
      },
      panelClass: "l"
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.updateOrganizationList();
    });

    return dialogRef.afterClosed();
  }
  getOrgStatusIcon(orgStatus: string | undefined): string {
    return this.sharedService.getOrgStatusIcon(orgStatus as OrganizationType);
  }
  updateOrganizationList() {
    this.cardService.getUserOrganization().subscribe((response: OrganizationsModel[]) => {
      this.userOrganization = response;

      this.cardService.getPermission().subscribe((response: PermissionAccountResponse[]) => {
        this.permissions = response;
        this.getRoles();
      });
    });
  }

  getProperty(propertyList: PermissionProperty[], name: string) {
    return propertyList?.find(x => x.name == name)?.value;
  }

  getPropertyBoolean(propertyList: PermissionProperty[], name: string): boolean {
    return propertyList?.find(x => x.name == name)?.booleanValue ?? false;
  }

  addOrganization(type: number) {
    const dialogRef = this.dialog.open(AddOrganization2UserComponent, {
      data: {
        type: type,
        ids: this.userOrganization.filter(x => x.type == type).map(x => x.organizationId)
      },
      panelClass: "l"
    });

    dialogRef.afterClosed().subscribe(_ => {
      this.cardService.getUserOrganization().subscribe(response => {
        this.userOrganization = response;
        this.updateOrganizationList();
      });
    });
  }

  getRolesOrganization(client: SystemModel, organizationId: number, isAuto: boolean) {
    return client.roles.filter(x => x.organizationId == organizationId && x.isAuto == isAuto);
  }

  getInheritedOrganizationRoles(client: SystemModel, organizationId: number, isAuto: boolean) {
    return this.roles.filter(x => x.organizationId == organizationId && x.clientId == client.id && x.isInherit && x.isAuto == isAuto);
  }

  getRoles() {
    this.roles = this.permissions.filter(x => x.clientRoles.id != null)
        .map((p: PermissionAccountResponse) => {
          return {
            permissionId: p.permissionId,
            clientRoleId: p.clientRoles.id,
            clientId: p.clientRoles?.client.id,
            organizationId: p.organization.id,
            roleId: p.clientRoles.role.id,
            name: p.clientRoles.role.name,
            isInherit: p.isInherit ?? false,
            isAuto: p.isAuto }
          });
  }

  //есть хотя организации для выбранного типа
  hasOrgs(type: number) {
    return !!this.userOrganization?.find(x => x.type == type);
  }

  /// Фильтруем для организации ИС у которых есть связи
  getClientsByOrg(organization: OrganizationsModel) {
    let clientIds = this.roles.filter(x => x.organizationId == organization.organizationId).map(x => x.clientId);
    return this.clients?.filter(s => !!clientIds?.filter(x => x == s.id).length);
  }

  // список ролей для организации внутри системы
  getRolesByClientOrg(client: SystemModel, organization: OrganizationsModel,) {
    return this.roles.filter(x => x.organizationId == organization.organizationId && x.clientId == client.id);
  }
  getOrgsByType(orgType: number) {
    return this.userOrganization?.filter(x => x.type == orgType);
  }
}
