<div class="dialog-title">
  <h2>Добавить копию электронного документа</h2>
  <button data-test="add-doc-dialog_btn-app-close" class="close-btn" mat-dialog-close></button>
</div>
<mat-dialog-content>

  <form class="d-flex flex-column mt-2 mb-4" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Тип документа</mat-label>
      <mat-select data-test="add-doc-dialog_select-doc-type" formControlName="docType">
        <mat-option [attr.data-test]="'add-doc-dialog_select-doc-type-option-' + j"
          *ngFor="let i of docTypes; let j = index" [value]="i.id">
          {{i.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <input data-test="add-doc-dialog_upload-file" class="upload-file" #uploadFile
      (change)="onFileChange($event)" type='file' accept=".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip" class="d-none" />
  </form>


  <div class="file-info" *ngIf="file">
    <div [ngClass]="{'fail': fileValidationErrors.length}">
      {{file.name}}
    </div>
    <svg-icon (click)="onRemoveFileClick()" key="buttonRemove" class="remove-icon"
      data-test="add-doc-dialog_btn-remove-file"></svg-icon>
    <div>
      <small class="file-info-error mb-3" *ngFor="let err of fileValidationErrors">
        {{err}}
      </small>
    </div>
  </div>


  <button class="primary xs py-1 px-2" disableRipple (click)="onAddClick(uploadFile)" [disabled]="!isDocTypeSelected"
    data-test="add-doc-dialog_btn-add-attachment">
    <svg-icon key="addSmall" class="s"></svg-icon>
    Добавить документ
  </button>
  <div *ngIf="isDocTypeSelected" class="mt-2">
    Необходимо приложить файл весом до 5МБ в формате doc, docx, pdf, jpg, jpeg, png, zip.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button data-test="add-doc-dialog_btn-add" class="primary me-3" disableRipple (click)="onAddFileClick()"
    [disabled]="!canAddFile">Добавить</button>
  <button data-test="add-doc-dialog_btn-cancel" class="secondary" mat-stroked-button disableRipple
    [mat-dialog-close]="false">Отменить</button>
</mat-dialog-actions>
