import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';

/*
* Страница, что удачно зарегистрирована УЗ
*/
@Component({
  selector: 'app-confirm-success-info',
  templateUrl: './confirm-success-info.component.html',
  styleUrls: ['./confirm-success-info.component.scss']
})
export class ConfirmSuccessInfoComponent {

  needConfirmPhone = false;
  confirmSuccessInfoPage = false;

  constructor(private translocoService: TranslocoService,
    private router: Router,
    configService: ConfigService
  ) {

    this.needConfirmPhone = configService.customization.getOptionBool(CodeEnum.NEED_CONFIRM_PHONE);
    this.confirmSuccessInfoPage = configService.customization.getOptionBool(CodeEnum.REG_SUCCESS);

    if (!this.confirmSuccessInfoPage) {
      this.router.navigate(['/auth/signin']);
    }
  }
}
