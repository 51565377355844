import {Validators} from "@angular/forms";
import {USERINFO_FORM_CONTROL_VALIDATORS} from "./userinfo-form-control-validators.const";
import CustomValidators from "../custom-validators.model";

export default class PersonalData {
  constructor(
    public email: any = ['', USERINFO_FORM_CONTROL_VALIDATORS.email],
    public accType: any = ['', Validators.required],
    public lastName: any = ['', Validators.required],
    public firstName: any = ['', Validators.required],
    public middleName: any = [''],
    public gender: any = [null, Validators.required],
    public dob: any = ['', Validators.required],
    public mobPhone: any = ['', [Validators.required, CustomValidators.onlyNum, CustomValidators.mobPhoneLen]]
  ) {

  }

}
