import { ClientRoles } from '../models/PermissionModels';
import { RoleModel } from './SystemModel';


export class OrganizationModel {
  constructor(public organizationId: number = 0, public inn: string = "", public userOrgPosition: string = "", public email: string = "", public phone: string = "", public kpp: string = "", public kppBranch: string = "", 
              public accountId: string = "", public type: number = 0, public chief: boolean = false) { }
}

export interface OrganozationModel {
  id: number,
  type: number,
  inn: string,
  ogrn: string

}

export interface OrgCreateModel {
  accountId?: number,
  type?: number;
  inn?: string;
  ogrn?: string;
  address?: string;
  contacts: Contacts;
  kpp?: string;
  kppBranch?: string;
  userOrgPosition?: string;
  chief: boolean;
}


export interface OrganizationListFilter {
  search?: string;
  count: number;
  offset: number;
  type?: number;

  createFrom?: string;
  createBefore?: string;
  updateFrom?: string;
  updateBefore?: string;
  types?: number[];
  internalStatusCode?: string[];
  isAuto?: boolean;

}

export interface OrganizationUpdateModel {
  organizationId: number;
  address?: string;
  contacts?: Contacts;
}

export interface Contacts {
  email?: string;
  phone?: string;
}

export enum PermissionPropertyNameEnum {
  Phone = "Рабочий телефон",
  Email = "Рабочая почта",
  Position = "Должность в компании",
  Chief = "Руководитель организации"
}

export interface PermissionProperty {
  name?: PermissionPropertyNameEnum;
  value?: string;
  booleanValue?: boolean;
}

export interface OrganizationsModel {
  type?: number;
  inn?: string;
  kpp?: string;
  kppBranch?: string;
  ogrn?: string;
  address?: string;
  contacts: Contacts;

  organizationId: number;
  status: Status;
  fullName?: string;
  shortName?: string;

  createdAt?: string;
  updatedAt?: string;
  permissionProperties: PermissionProperty[];
  permissions?: Permission[];
  isAuto?: boolean;

  isCollapse: boolean;
}

export interface Status {
  internalCode?: string;
  internalName?: string;
  isLiquidated?: boolean;
}

export interface Status {
  internalCode?: string;
  internalName?: string;
  isLiquidated?: boolean;
}

export interface Permission {
  permissionId: number;
  isInherit?: boolean;
  clientRoles: ClientRoles;
}



export interface OrganizationListPaginationResponse {
  data: OrganizationsModel[];
  pagination: Pagination;
}

export interface Pagination {
  total: number;
  count: number;
  offset: number;
}

export interface PermissionOrganization {
  Organization?: OrganizationsModel;
  isInherit?: boolean;
  role?: RoleModel;
}