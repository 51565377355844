import {IdentityDocumentTypeEnum} from "./identity-document-type.enum";

export default class IdentityDocumentTypeConverter {
  public static convert(docType: IdentityDocumentTypeEnum): string {
    if (docType === IdentityDocumentTypeEnum.RussianPassport) {
      return 'Паспорт гражданина РФ'
    }
    return '';
  }
}
