import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProfileService } from "../../core/services/profile.service";
import { Filter4HistoryStatus, HistoryStatusList } from '../../core/models/HistoryStatusModel';
import { TranslocoService } from '@ngneat/transloco';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { getStatusClass } from 'src/app/core/models/account-status.model';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';

@Component({
  selector: 'statusHistory',
  templateUrl: './statusHistory.component.html',
  styleUrls: ['./statusHistory.component.scss']
})
export class StatusHistoryComponent implements OnInit {
  public readonly minPageSize = 10;
  readonly defaultPageSizeOptions = [10, 30, 50, 100]; pageIdx = 0;
  pageSize = this.minPageSize;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  total = 0;
  editedby = false;

  displayedColumns: string[] = [];
  historyStatus: HistoryStatusList[] = [];

    
  constructor(
    @Inject(MAT_DIALOG_DATA) public isStranger: boolean, 
    private profileService: ProfileService, 
    private translocoService: TranslocoService, 
    configService: ConfigService
    ) {
    this.editedby = configService.customization.getOptionBool(CodeEnum.STATUS_HISTORY_EDITED_BY);
    this.isStranger = !!isStranger;

    if (this.isStranger || !this.editedby) {
      this.displayedColumns = ['status', 'data', 'comment'];
    }
    else {
      this.displayedColumns = ['status', 'data', 'izm', 'comment'];
    }
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe((evt) => {
      const { pageIndex: pageIdx, pageSize } = evt;
      this.pageIdx = pageIdx;
      this.pageSize = pageSize;
    });
  }
  
  getStatusClass(s: number): string {
    return getStatusClass(s);
  }

  updateData() {
    let filter: Filter4HistoryStatus = {
      offset: this.pageIdx * this.pageSize,
      count: this.pageSize
    };
    if (this.isStranger) {
      this.profileService.getStrangerHistoryStatus(filter).subscribe(response => {
        this.historyStatus = response;
        this.paginator.length = response.length;
        this.total = response.length;
      });
    }
    else {
      this.profileService.getHistoryStatus(filter).subscribe(response => {
        this.historyStatus = response.data;
        this.paginator.length = response.pagination.count;
        this.total = response.pagination.total;
      });
    }
  }

  getData(evt: PageEvent) {
    this.pageIdx = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.updateData();
  }

  getActiveLang() {
    return this.translocoService.getActiveLang();
  }
}