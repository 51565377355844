import { Component, OnDestroy } from '@angular/core';
import { ProfileService } from "../../core/services/profile.service";
import { Location } from '@angular/common';
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { MatDialog } from "@angular/material/dialog";
import { StatusHistoryComponent } from "../statusHistory/statusHistory.component";
import { IRegisterStrangerDTO, IStrangerOrganization, IStrangerPersonalData, IStrangerVehicle, IStrangerVehicleOwner } from '../../core/models/RegisterStrangerRequest';
import { StrangersType, StrangersOwnerType } from "../../core/shared/enums/strangersType";
import { CountriesResponse } from '../../core/models/country';
import AccountStatus, { getStatusClass } from "../../core/models/account-status.model";
import { TranslocoService } from '@ngneat/transloco';
import { SharedService } from 'src/app/core/services/shared.service';
import IStrangersType from 'src/app/modules/auth/models/interfaces/IStrangersType';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { CardService } from 'src/app/core/services/card.service';
import { AccountStatusEnum } from 'src/app/core/models/account-status.enum';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { Customization } from 'src/app/core/shared/customization-config';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';
import { MASKS } from 'src/app/core/models/masks.const';
import { ERR_MSG } from 'src/app/core/models/error-messages.const';
import { RegistrationService } from 'src/app/modules/auth/services/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatePhoneDialogComponent } from 'src/app/modules/auth/components/modals/activate-phone-dialog/activate-phone-dialog.component';
import { OkCancelDialogComponent } from '../ok-cancel-dialog/ok-cancel-dialog.component';
import { deepCopy } from 'src/app/core/shared/shared';
import { StrangerProfileInfo } from 'src/app/core/models/strangerProfile';
import { Clipboard } from '@angular/cdk/clipboard';

const RUSSIAN_COUNTRY_CODE_ID = 643;

@Component({
  selector: 'app-profileStrangers',
  templateUrl: './profileStrangers.component.html',
  styleUrls: ['./profileStrangers.component.scss']
})
export class ProfileStrangersComponent implements OnDestroy {
  private subs: Subscription = new Subscription();
  showStrangersVehicles = false;
  personalData: IStrangerPersonalData = {} as IStrangerPersonalData;
  organization: IStrangerOrganization = {} as IStrangerOrganization;
  owner: IStrangerVehicleOwner = {} as IStrangerVehicleOwner;
  vehicleData: IStrangerVehicle = { owner: this.owner } as IStrangerVehicle;

  isShowAttention = true;
  MASKS = MASKS;
  showForeignOrgPersonal: boolean;
  showForeignOrgChief: boolean;
  showForeignOrgInn: boolean;
  showForeignPersonalInn: boolean;
  CodeEnum = CodeEnum;
  strangersType = StrangersType;
  strangersOwnerType = StrangersOwnerType;
  typesUser: IStrangersType[];
  strangersOwnerTypes: IStrangersType[];
  countries: CountriesResponse[] = [];
  errorMessage = "";
  ERR_MSG = ERR_MSG;
  typeUserTS: IStrangersType | null = null;
  isMatch = true;
  serverValidationErrors: string[] = [];
  emailStatus = AccountStatusEnum.AwaitingEmailConfirmation;
  notificationMessage = "";
  isSuccess = false;
  isSaveSuccess = false;
  isPhoneConfirmed = false;
  needConfirmPhone = false;
  strangerInitData: StrangerProfileInfo = {} as StrangerProfileInfo;
  accType = "";
  accStatusEnum = AccountStatusEnum;
  accStatus: AccountStatus = {} as AccountStatus;
  customization: Customization;

  constructor(
    public profileService: ProfileService,
    private registrationService: RegistrationService,
    public sharedService: SharedService,
    private _location: Location,
    private legacyDialog: MatLegacyDialog,
    private dialog: MatDialog,
    private configService: ConfigService,
    private cardService: CardService,
    private clipboard: Clipboard,
    private translocoService: TranslocoService
  ) {
    this.typesUser = this.sharedService.getStrangersTypes();
    this.getCountries();
    this.showForeignOrgPersonal = configService.customization.getOptionBool(CodeEnum.FOREIGN_ORG_PERSONAL);
    this.showForeignOrgInn = configService.customization.getOptionBool(CodeEnum.FOREIGN_ORG_INN);
    this.showForeignPersonalInn = configService.customization.getOptionBool(CodeEnum.FOREIGN_PERSONAL_INN);
    this.showForeignOrgChief = configService.customization.getOptionBool(CodeEnum.FOREIGN_ORG_CHIEF);

    this.needConfirmPhone = configService.customization.getOptionBool(CodeEnum.NEED_CONFIRM_PHONE)
    this.showStrangersVehicles = configService.customization.getOptionBool(CodeEnum.FOREIGN_VEHICLES);
    this.typesUser = sharedService.getStrangersTypes();
    this.strangersOwnerTypes = sharedService.getStrangersOwnerTypes();
    this.customization = configService.customization;

    this.updateData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getCountries() {
    this.registrationService.getCountry().subscribe((countries) => {
      this.countries = countries;
    });
  }


  onConfirmPhoneClick() {
    this.subs.add(this.dialog.open(ActivatePhoneDialogComponent, {
      data: { accountId: this.strangerInitData.id, phone: this.personalData.phone ?? this.organization.phone, accountType: "stranger" },
      autoFocus: false,
      disableClose: true
    }).afterClosed().subscribe(isSuccess => {
      if (isSuccess) {
        this.updateData();
      }
      console.info('isSuccess = ', isSuccess);
    }));
  }


  public updateData() {
    this.serverValidationErrors = [];
    this.profileService.getStranger().subscribe(async data => {
      this.strangerInitData = deepCopy(data.data);
      console.log("updateData = ", data);

      this.personalData = data.data.personalData ?? {} as IStrangerPersonalData;
      this.organization = data.data.organization ?? {} as IStrangerOrganization;
      this.owner = data.data.vehicleData?.owner;
      if (!!data.data.vehicleData) {
        this.vehicleData = data.data.vehicleData;
        this.typeUserTS = this.strangersOwnerTypes.find(x => x.id === data.data.vehicleData.ownerType) ?? null;
      }

      this.isMatch = this.vehicleData?.ownerType == StrangersOwnerType.APPLICANT ?? false;
      this.emailStatus = data.data.emailStatus;
      this.isPhoneConfirmed = data.data.isPhoneConfirmed;
      this.accStatus = new AccountStatus(data.actualStatus.statusId, data.actualStatus.name, data.actualStatus.nameEn);
      this.accType = data.data.type;

      this.updateNotificationMessage();
    });
  }

  updateNotificationMessage() {
    if ((!this.isPhoneConfirmed && this.needConfirmPhone) || this.emailStatus != AccountStatusEnum.EmailConfirmed) {
      this.notificationMessage = this.translocoService.translate('confirmSuccessInfo.descriptionHello');
      if (this.emailStatus != AccountStatusEnum.EmailConfirmed) {
        this.notificationMessage += "\r\n" + this.translocoService.translate('confirmSuccessInfo.descriptionEmail');
      }
      if (!this.isPhoneConfirmed && this.needConfirmPhone) {
        this.notificationMessage += "\r\n" + this.translocoService.translate('confirmSuccessInfo.descriptionPhone');
      }
    }
    else this.notificationMessage = '';
  }

  getStatusClass(s: number): string {
    return getStatusClass(s);
  }

  onBack() {
    this._location.back();
  }

  getTypeStrangerName(type: string | undefined) {
    if (!!type) {
      var findType = this.typesUser.find(x => x.id == type);
      if (!!findType) {
        return this.getActiveLang() == "ru" ? findType?.name : findType?.inEnglish;
      }
    }
    return "";
  }

  getActiveLang() {
    return this.translocoService.getActiveLang();
  }

  public copyClipboard(text: string | number) {
    this.clipboard.copy(text + '');
  }

  getStatusHistory() {
    this.legacyDialog.open(StatusHistoryComponent, {
      data: true,
      panelClass: "l"
    });
  }

  sendConfirmMail() {
    this.cardService
      .sendActivateMail(true)
      .subscribe((res) => {
        this.updateNotificationMessage();
      });
  }

  onBeforeInputPhoneNumber(evt: InputEvent) {
    const inputValue = (<HTMLTextAreaElement>evt.target).value;
    let isPlusExist = !!inputValue && inputValue.includes('+');

    if (evt.inputType !== 'deleteContentBackward' && /[^0-9+]/.test(evt.data || '') || (isPlusExist && evt.data === '+')) {
      evt.preventDefault();
      return;
    }
  }

  checkStatusForSave() {
    if (
      this.accStatus?.status === AccountStatusEnum.AwaitingEmailConfirmation
      || this.accStatus?.status === AccountStatusEnum.EmailConfirmed
      || this.accStatus?.status === AccountStatusEnum.MailNotConfirmed
      || this.accStatus?.status === AccountStatusEnum.Approved
      || this.accStatus?.status === AccountStatusEnum.AwaitingPhoneConfirmation
      || this.accStatus?.status === AccountStatusEnum.PhoneConfirmed) {
      return true;
    }

    // если подтверждение учетки локальное, то разрешить редактирование
    if (this.configService.customization.getOptionBool(CodeEnum.FOREIGN_LOCAL) &&
      (this.accStatus?.status === AccountStatusEnum.ConfirmationCanceled
        || this.accStatus?.status === AccountStatusEnum.FinalConfirmationDenied)) {
      return true;
    }

    return false;
  }

  saveClick() {
    if (!this.canSave()) {
      return;
    }
    if (this.isPhoneChanged() || this.isMailChanged()) {
      let fields = "";
      if (this.isMailChanged()) fields = this.translocoService.translate('profileStrangers.email');
      if (this.isPhoneChanged()) fields += (!!fields ? ", " : "") + this.translocoService.translate('profileStrangers.phoneNumber');

      this.subs.add(this.dialog.open(OkCancelDialogComponent, {
        data: {
          title: this.translocoService.translate('attention'),
          text: this.translocoService.translate('profileStrangers.changeFieldsNeedConfirmAttention', { fields: fields })
        }
      }).afterClosed().subscribe((isOk: any) => {
        if (isOk) {
          this.submit();
        }
      }));
    }
    else {
      this.submit();
    }
  }

  //почта подтверждена и отредактировали
  isMailChanged(): boolean {
    if (this.emailStatus == AccountStatusEnum.EmailConfirmed) {
      if (this.strangerInitData.personalData?.email != this.personalData?.email || this.strangerInitData.organization?.email != this.organization?.email) {
        return true;
      }
    }
    return false;
  }

  //телефон подтвержден и отредактировали
  isPhoneChanged(): boolean {
    if (this.needConfirmPhone && this.isPhoneConfirmed) {
      if (this.strangerInitData.personalData?.phone != this.personalData?.phone
        || this.strangerInitData.organization?.phone != this.organization?.phone) {
        return true;
      }
    }
    return false;
  }

  submit() {
    this.serverValidationErrors = [];
    let userData: IRegisterStrangerDTO = {
      type: this.accType!,
      language: this.getActiveLang(),
      organization: this.organization,
      personalData: this.personalData,
      vehicleData: this.vehicleData
    } as IRegisterStrangerDTO;

    // Сведения о транспортном средстве и владельце
    if (this.showStrangersVehicles && (this.isMatch && this.accType == StrangersType.PERSON)) {
      userData.vehicleData!.owner = {
        address: this.personalData?.address!,
        countryId: this.personalData?.countryId!,
        firstname: this.personalData?.firstname!,
        surname: this.personalData?.surname!,
        patronymic: this.personalData?.patronymic!
      } as IStrangerVehicleOwner;
      userData.vehicleData!.ownerType = StrangersOwnerType.APPLICANT;
    }
    else if (this.showStrangersVehicles && (this.isMatch && this.accType == StrangersType.ORGANIZATION)) {
      userData.vehicleData!.owner = {
        address: this.organization?.address!,
        countryId: this.organization?.countryId!,
        fullName: this.organization?.fullName!,
        olf: this.organization?.olf!
      } as IStrangerVehicleOwner;
      userData.vehicleData!.ownerType = StrangersOwnerType.APPLICANT;
    }
    else if (this.showStrangersVehicles && (this.accType == StrangersType.PERSON || this.accType == StrangersType.ORGANIZATION)) {
      userData.vehicleData!.ownerType = this.typeUserTS?.id!;
    }
    else {
      userData.vehicleData = null;
    }

    if (this.accType == StrangersType.PERSON || this.accType == StrangersType.PERSONRUS) {
      userData.login = this.personalData?.email!;
      userData.personalData!.phone = this.personalData.phone?.replace('+', '');
      userData.organization = null;
    }
    if (this.accType == StrangersType.ORGANIZATION || this.accType == StrangersType.ORGANIZATIONRUS) {
      userData.login = this.organization?.email!;
      userData.organization!.phone = this.organization.phone?.replace('+', '');
      if (!this.showForeignOrgPersonal) userData.personalData = null;
      else userData.personalData!.phone = this.personalData.phone?.replace('+', '');
    }

    console.log('update data = ', userData);

    this.errorMessage = "";
    this.registrationService.updateStranger(userData).subscribe(
      (response) => {
        if (response) {
          if (response.error == 'user_exist') {
            this.errorMessage = this.translocoService.translate('confirmSuccessInfo.descriptionEmailExist');
          }
          else if (!!response.error) {
            this.errorMessage = response.error;
          }
          else {
            this.updateData();
            this.isSaveSuccess = true;
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.log('error: ', err);
        if (err.status === 400) {
          let error = "";
          for (var prop in err.error) {
            error += err.error[prop].errorMessage + "\r\n";
          }
          this.serverValidationErrors = [error];
        } else {
          this.serverValidationErrors = [err.error];
        }
      });
  }

  canSave() {
    if (!this.isPersonalData()
      || !this.isVehicleData()
      || !this.isOrganizationData()
      || !this.isOwnerData()
    )
      return false;

    return true;
  }

  isPersonalData() {
    if ((this.accType == StrangersType.ORGANIZATION || this.accType == StrangersType.ORGANIZATIONRUS) && !this.showForeignOrgPersonal) {
      return true;
    }

    if (this.personalData.firstname == "" || this.personalData.surname == "" || this.personalData.email == "" ||
      this.personalData.address == "" || this.personalData.phone == "" || !this.personalData.countryId ||
      !this.sharedService.isEmailRegular(this.personalData.email))
      return false;

    if (this.accType == StrangersType.PERSON) {
      if (this.personalData.taxAuthority == "")
        return false;
      if (!this.isMatch && !this.isOwnerData())
        return false;
    }

    if (this.accType == StrangersType.PERSON && this.showForeignPersonalInn && !this.sharedService.isValidForeignInn(this.personalData.inn)) {
      return false;
    }
    if (this.accType == StrangersType.PERSONRUS && this.personalData.inn.length != 12) {
      return false;
    }

    return true;
  }

  isOrganizationData() {
    if (this.accType == StrangersType.PERSON || this.accType == StrangersType.PERSONRUS) {
      return true;
    }

    if (this.organization.fullName == "" || this.organization.olf == "" || this.organization.email == "" ||
      this.organization.address == "" || this.organization.phone == "" || !this.organization.countryId ||
      !this.sharedService.isEmailRegular(this.organization.email))
      return false;

    if (this.accType == StrangersType.ORGANIZATION) {
      if (this.organization.taxAuthority == "")
        return false;
      if (!this.isMatch && !this.isOwnerData())
        return false;
    }

    if (this.accType == StrangersType.ORGANIZATIONRUS && this.organization.inn.length != 10) {
      return false;
    }

    if (this.accType == StrangersType.ORGANIZATION && this.showForeignOrgInn && !this.sharedService.isValidForeignInn(this.organization.inn)) {
      return false;
    }

    return true;
  }

  // транспортное средство заполнено
  isVehicleData() {
    if (!this.showStrangersVehicles)
      return true;

    if (this.accType == StrangersType.PERSONRUS || this.accType == StrangersType.ORGANIZATIONRUS)
      return true;

    if (this.vehicleData.brand != "" && this.vehicleData.model != "" &&
      this.vehicleData.srn != "" && this.vehicleData.vin != "" && !!this.vehicleData.countryId)
      return true;
    else return false;
  }

  // проверка данных по собственнику ТС
  isOwnerData() {
    if (!this.showStrangersVehicles)
      return true;

    // если владелец совпадает или это русский
    if (this.isMatch || this.accType == StrangersType.PERSONRUS || this.accType == StrangersType.ORGANIZATIONRUS) return true;

    if (this.owner.address == "" || !this.owner.countryId) return false;
    if (this.typeUserTS?.id == StrangersOwnerType.PERSON && (this.owner.firstname != "" && this.owner.surname != "")) return true;
    if (this.typeUserTS?.id == StrangersOwnerType.LEGAL && (this.owner.fullName != "" && this.owner.olf != "")) return true;
    return false;
  }

  // фильтруем список стран и сортируем в зависимости от языка(для русских только одна страна RUSSIAN_COUNTRY_CODE_ID)
  getCountriesList(list: CountriesResponse[], filterText: string): CountriesResponse[] {
    if (this.accType == StrangersType.PERSONRUS || this.accType == StrangersType.ORGANIZATIONRUS) {
      return list.filter(x => x.id == RUSSIAN_COUNTRY_CODE_ID);
    }

    let isRu = this.getActiveLang() == 'ru';
    return list.filter(x => x.id != RUSSIAN_COUNTRY_CODE_ID && (x.name.toLocaleLowerCase().includes(filterText.toLowerCase()) || x.inEnglish.toLocaleLowerCase().includes(filterText.toLowerCase())))
      .sort((one, two) => ((isRu ? one.name < two.name : one.inEnglish < two.inEnglish) ? -1 : 1));
  }

  isDisableEdit() {
    return !this.checkStatusForSave();
  }
}
