<section class="system-redirect-page">
  <div class="row g-0">
    <div *ngIf="isLoading" class="col-12">
      <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
    </div>
  </div>

  <div class="system-redirect-links" *ngIf="!isLoading">
    <div class="system-redirect-links-item" [attr.data-test]="'systemRedirect_btn-system-redirect-' + i"
         *ngFor="let system of systems; let i = index" (click)="redirect(system)">

      <img class="system-redirect-links-item-img" [src]="img[system.id]" alt="">
      <svg-icon key="dropdownRightSmall" class="filled xl icon-redirect-click"></svg-icon>
      <h1 class="system-redirect-links-item-title landing">{{getName(system)}}</h1>
      <p class="system-redirect-links-item-text">{{getDescription(system)}}</p>

    </div>
  </div>
</section>
