import { Component, Input } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger, LegacyMenuPositionY as MenuPositionY } from '@angular/material/legacy-menu';

/*
* Tooltip где можно кроме текста еще и html отображать
*/
@Component({
  selector: 'app-zit-auto-open-menu',
  templateUrl: './zit-auto-open-menu.component.html',
  styleUrls: ['./zit-auto-open-menu.component.scss'],
})
export class ZitAutoOpenMenuComponent {
  @Input() menuPositionY: MenuPositionY = 'below'; //'above' | 'below'
  @Input() menuStyle: {
    [klass: string]: any;
  } | null = null; // стили для всплывашки
  timedOutCloser: any;

  constructor() { }

  mouseEnter(trigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 2000);
  }
}
