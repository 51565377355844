import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ERR_MSG } from "../../core/models/error-messages.const";
import { Observable, Subscription } from "rxjs";
import { genderList } from "../../core/shared/gender-list.const";
import { CardService } from "../../core/services/card.service";
import ICode, { AccountType, RecordModel, SharedService } from 'src/app/core/services/shared.service';
import { ActivatedRoute } from "@angular/router";
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { POINTED_DATE_FORMATS } from "../../core/models/pointed-date-formats.const";
import { AccountTypeEnum } from "../../core/shared/enums/account-type.enum";
import { AddDocDialogComponent } from "../add-doc-dialog/add-doc-dialog.component";
import { RemoveDocDialogComponent } from "../remove-doc-dialog/remove-doc-dialog.component";
import { ConfigService } from "../../modules/client-config/services/config.service";
import { MASKS } from "../../core/models/masks.const";
import { RoleModel } from '../../modules/organizations/models/SystemModel';
import Userinfo from 'src/app/core/models/userinfo/Userinfo';
import { RefusalOfRegistrationComponent } from '../card/refusalOfRegistration/RefusalOfRegistration.component';
import { Vehicle } from './models/userInfoModel';
import AccountStatus, { getStatusClass } from '../../core/models/account-status.model';
import { AccountStatusEnum } from "../../core/models/account-status.enum";
import { IChangeUserInfo } from "../card/models/userInfoModel";
import { DeleteRecordModalComponent } from '../deleteRecordModal/deleteRecordModal.component';
import IFileMeta from 'src/app/core/models/card/IFileMeta';
import { StatusHistoryComponent } from '../statusHistory/statusHistory.component';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { Clipboard } from '@angular/cdk/clipboard';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';

interface Memory {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dob: string;
  mobPhone: string;
  email: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  docType: number;
  series: string;
  num: string;
  issueDate: string;
  code: string;
  issuer: string;
  birthplace: string;
  citizenship: string;
  snils: string;
  inn: string;
}

interface DeleteData {
  title: string;
  text: string;
}

export default class ShowBlock {
  address = true;
  identityDocument = true;
  otherDocuments = true;
  eDocCopy = true;
  vehicles = true;
  constructor() { }
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: POINTED_DATE_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru'
    }
  ]
})
export class CardComponent implements OnInit {

  accStatusEnum = AccountStatusEnum;
  showBlock: ShowBlock = new ShowBlock();

  private subs: Subscription = new Subscription();
  isLoading = true;
  isAccountConfirmationBtnActive = false;
  userUid = '';
  maxDate = new Date();
  maxBirthDate = new Date();
  form;
  ERR_MSG = ERR_MSG;
  MASKS = MASKS;
  orgTypeEnum = OrgTypeEnum;
  accTypeEnum = AccountTypeEnum;
  _accTypes: AccountType[];
  _accType: AccountType;
  orgAndLegalForms$: Observable<ICode[]>;
  genderList = genderList;
  data: any = null;
  vehicles: Vehicle[] = [];
  isEsiaUser = false;
  roles: RoleModel[] = [];
  addedRoles: RoleModel[] = [];
  deletedRoles: RoleModel[] = [];
  memory: Memory | null = null;
  displayedColumns: string[] = [
    'docType',
    'fileName',
    'size',
    'uploadDate',
    'actions'
  ];
  ds: IFileMeta[] = [];
  isUploadedFiles = false;
  accStatus: AccountStatus | null = null;
  accRole: RecordModel | null = null;
  isApproved = false;
  isActivated = false;
  changeAccountDataDate: string | null = null;

  showProfileDeleteDocumentBtn = false;
  showProfileVehiclesTable = false;
  showProfileAddDocumentBtn = false;
  showProfileDocumentsTable = false;
  
  fakeSys = true;

  constructor(
    private _location: Location,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private cardService: CardService,
    private sharedService: SharedService,
    private documentsService: DocumentsService,
    private clipboard: Clipboard,
    public configService: ConfigService
  ) {
    this._accTypes = this.sharedService.getAccountTypes();
    this._accType = this._accTypes[0];
    this.orgAndLegalForms$ = this.sharedService.getOrgAndLegalForms();
    this.form = this.cardService.form;
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - 18);
    
    this.showProfileDocumentsTable = this.configService.customization.getOptionBool(CodeEnum.DOC);
    this.showProfileAddDocumentBtn = this.configService.customization.getOptionBool(CodeEnum.ADD_DOC);
    this.showProfileDeleteDocumentBtn = this.configService.customization.getOptionBool(CodeEnum.DEL_DOC);
    this.showProfileVehiclesTable = this.configService.customization.getOptionBool(CodeEnum.VEHICLES);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {

    this.cardService.getUserinfo(this.userUid).subscribe(async data => {
      console.info('cardService.getUserinfo', data);
      const accountStatus = data.accountStatus;
      const additionalPersonalData = data.additionalPersonalData;
      const userinfo = data.userInfo;
      const isActivated = data.isActivated;
      const isApproved = data.isApproved;

      userinfo.personalData = {
        ...userinfo.personalData,
        ...additionalPersonalData,
        mobPhone: userinfo.personalData.mobPhone.replace(/^\+7/, '')
      };
      const pd = userinfo.personalData;

      if(userinfo.identityDocument.issueDate)
      { 
        const issueDate = new Date (userinfo.identityDocument.issueDate);

        if (issueDate.getFullYear() === 1)
          userinfo.identityDocument.issueDate = null;
      }

      this.vehicles = userinfo.personalData.vehicles;

      const accType = pd.accType;

      this.documentsService.getFilesMeta().subscribe(filesMeta => {
        this.setFilesMeta(filesMeta);
      });

      this.isActivated = isActivated;
      this.isApproved = isApproved;
      this.data = data;

      if (accountStatus != undefined)
        this.accStatus = new AccountStatus(accountStatus.statusId, accountStatus.name);

      this.isEsiaUser = Number(userinfo.personalData.accType) === AccountTypeEnum.Esia;

      if (this.isEsiaUser) {
        (this.form.get('personalData') as UntypedFormGroup)?.addControl('esiaGroup', this.fb.control({ value: '', disabled: true }));
      }

      this.form.get('personalData.accType')?.disable();

      if (Number(accType) !== AccountTypeEnum.Esia) {
        this.form.get('orgData')?.disable();
      }
      if (Number(accType) === AccountTypeEnum.Esia) {
        this.form.disable();
      }


      this._accType = this._accTypes.find(x => x.id == Number(userinfo.personalData.accType)) ?? this._accTypes[0];
      userinfo.personalData.accType = this._accTypes.find(x => x.id == Number(userinfo.personalData.accType)) ?? this._accTypes[0];

      this.form.patchValue(userinfo);
      if (!this.form.valid) {
        this.form.markAllAsTouched();
      }

    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.form.get('file')?.setValue(null);
    this.form.get('fileSource')?.setValue(null);
  }

  getStatusClass(s: number): string {
    return getStatusClass(s);
  }

  onBack() {
    this._location.back();
  }

  isRemovedAccount(): boolean {
    return this.accStatus?.status == AccountStatusEnum.AccountDeleted;
  }

  onDeleteAccount(accountId: string) {
    let data: DeleteData = {
      title: 'Подтвердить изменения?',
      text: 'Уважаемый пользователь, ваши действия приведут к необратимому удалению учетной записи'
    }

    this._openDialog(data, result => {
      if (result) {
        this._onDelete(accountId);
      }
    });
  }

  private _openDialog(data: DeleteData, delegate: (stmt: boolean) => void): void {
    const ref = this.dialog.open(DeleteRecordModalComponent, { data });
    ref.afterClosed().toPromise()
      .then(delegate);
  }

  private _onDelete(accountId: string) {
    this.cardService.deleteAccount(accountId)
      .subscribe(data => {
        if (data.isAccountRemoved) {
          window.location.href = `/auth/signin`;
        }
      });
  }

  onViewFile(item: IFileMeta) {
    if (item.isUploaded) {
      const { accountId, id } = item;
      this.documentsService.getFile(id)
        .subscribe(res => {
          const file = res.body;
          const fileURL = URL.createObjectURL(file as Blob);
          window.open(fileURL);
        });
    }
  }

  onRemoveFileClick(evt: Event, item: IFileMeta) {
    const ref = this.dialog.open(RemoveDocDialogComponent, {
      data: { type: item.docType, name: item.fileName },
      autoFocus: false
    });

    const s = ref.afterClosed().subscribe(r => {
      if (r) {
        if (item.isUploaded) {
          const fileId = item.id;
          this.documentsService.deleteFile(fileId)
            .subscribe(_ => {
              this.ds = this.ds.filter(x => x.id != fileId);
            });
        } else {
          this.removeNotUploadedFile(item);
        }
      }
    });
    this.subs.add(s);
  }

  private removeNotUploadedFile(item: IFileMeta) {
    const filesMeta = this.ds;
    const fmIdx = filesMeta.indexOf(item);
    const files = this.form.value.files;
    const NotUploadedFiles = filesMeta.filter(x => !x.isUploaded);
    const nufIdx = NotUploadedFiles.indexOf(item);
    this.form.get('file')?.setValue(null);
    filesMeta.splice(fmIdx, 1);
    files.splice(nufIdx, 1);
    this.ds = [...filesMeta];
    this.form.get('files')?.setValue(files);
  }

  updateAllowed() {
    var check = this.form.get('personalData.firstName')?.value !== this.memory?.firstName ||
      this.form.get('personalData.middleName')?.value !== this.memory?.middleName ||
      this.form.get('personalData.lastName')?.value !== this.memory?.lastName ||
      this.form.get('personalData.gender')?.value !== this.memory?.gender ||
      this.form.get('personalData.dob')?.value.substr(0, 10) !== this.memory?.dob ||
      this.form.get('personalData.mobPhone')?.value !== this.memory?.mobPhone ||
      this.form.get('personalData.email')?.value !== this.memory?.email ||
      this.form.get('registrationAddress.city')?.value !== this.memory?.city ||
      this.form.get('registrationAddress.street')?.value !== this.memory?.street ||
      this.form.get('registrationAddress.house')?.value !== this.memory?.house ||
      this.form.get('registrationAddress.apartment')?.value !== this.memory?.apartment ||
      this.form.get('identityDocument.docType.id')?.value !== this.memory?.docType ||
      this.form.get('identityDocument.series')?.value !== this.memory?.series ||
      this.form.get('identityDocument.num')?.value !== this.memory?.num ||
      this.form.get('identityDocument.issueDate')?.value !== this.memory?.issueDate ||
      this.form.get('identityDocument.code')?.value !== this.memory?.code ||
      this.form.get('identityDocument.issuer')?.value !== this.memory?.issuer ||
      this.form.get('identityDocument.birthplace')?.value !== this.memory?.birthplace ||
      this.form.get('identityDocument.citizenship')?.value !== this.memory?.citizenship ||
      this.form.get('otherDocuments.inn')?.value !== this.memory?.inn ||
      this.form.get('otherDocuments.snils')?.value !== this.memory?.snils ||
      this.addedRoles.length > 0 ||
      this.deletedRoles.length > 0;

    return check;
  }

  onSubmit() {
    const dataRequest = this.getSaveData();
    this.cardService.createChangeAccountDataRequest(dataRequest)
      .subscribe(async response => {
        console.info("createChangeAccountDataRequest ---response", response);

        if(!!response)
          this.loadData();

        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
      });

  }

  private getSaveData(): IChangeUserInfo {
    const rawVal = this.form.getRawValue();
    let data: IChangeUserInfo = {
      accountId: +this.userUid,
      profileUserinfo: {
        personalData: {
          email: rawVal.personalData.email,
          accType: rawVal.personalData.accType.id,
          lastName: rawVal.personalData.lastName,
          firstName: rawVal.personalData.firstName,
          middleName: rawVal.personalData.middleName,
          gender: rawVal.personalData.gender,
          dob: rawVal.personalData.dob,
          mobPhone: rawVal.personalData.mobPhone,
          vehicles: []
        },
        registrationAddress: rawVal.registrationAddress,
        identityDocument: {
          type: rawVal.identityDocument.docType.id,
          series: rawVal.identityDocument.series,
          number: rawVal.identityDocument.number,
          issuedBy: rawVal.identityDocument.issuedBy,
          issueDate: rawVal.identityDocument.issueDate,
          issueId: rawVal.identityDocument.issueId,
          birthplace: rawVal.identityDocument.birthplace,
          citizenship: rawVal.identityDocument.citizenship,
        },
        otherDocuments: rawVal.otherDocuments,
      }
    };

    console.info("changed data---------", data);
    return data;
  }

  private setFilesMeta(filesMeta: IFileMeta[]) {
    if (filesMeta) {
      let files = filesMeta.filter(f => !!f.isAdmin == false)

      this.ds = [...files];
      this.isUploadedFiles = true;
    }
  }


  refusalOfRegistration() {
    const dialogRef = this.dialog.open(RefusalOfRegistrationComponent, {
      data: { accauntId: this.userUid }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined) {

      }
    })
  }

  getStatusHistory() {
    this.dialog.open(StatusHistoryComponent, {
      data: false,
      panelClass: "l"
    });
  }

  onAddDocClick() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(AddDocDialogComponent, {
      autoFocus: false
    });

    const s = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const { fileMeta, file } = data;

        var formData = new FormData();
        //formData.append('accountId', this.userUid); //TODO только админка
        formData.append('name', fileMeta.fileName);
        formData.append('extension', fileMeta.fileName.split('.').pop());
        formData.append('size', file.size);
        formData.append('Type', fileMeta.docType.id);
        formData.append('file', file);

        this.documentsService.uploadFile(formData).subscribe(fileId => {
          fileMeta.id = fileId;
          fileMeta.isUploaded = true;
          fileMeta.accountId = this.userUid;

          this.ds = [...this.ds, fileMeta];
          this.form.patchValue({
            files: [...this.form.value.files, file]
          });
          this.form.controls.files.markAsTouched();
          this.form.controls.files.markAsDirty();
        });
      }
    })
    this.subs.add(s);
  }

  sendConfirmMail() {
    this.cardService
      .sendActivateMail(false)
      .subscribe((res) => {
        if (res?.success) {
          this.accStatus = new AccountStatus(res.accountStatus.id, res.accountStatus.name);
        }
      });
  }

  public copyIdentifier() {
    this.clipboard.copy(this.data.additionalPersonalData.identifier);
  }

}
