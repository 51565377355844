import PersonalData from "../userinfo/PersonalData";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { USERINFO_FORM_CONTROL_VALIDATORS } from "../userinfo/userinfo-form-control-validators.const";
import { CodeEnum } from "../../shared/enums/codeEnum";
import { ConfigService } from "src/app/modules/client-config/services/config.service";

export default class CardPersonalDataModel extends PersonalData {
  identifier;
  lastLogin;
  role;
  constructor(fb: UntypedFormBuilder, configService: ConfigService) {
    let checkRuEmailDomain = configService.customization.getOptionBool(CodeEnum.EMAIL_CHECK_RU_DOMAIN);

    super(fb);
    this.email = fb.control({value: '', disabled: false}, checkRuEmailDomain ? USERINFO_FORM_CONTROL_VALIDATORS.emailRu : USERINFO_FORM_CONTROL_VALIDATORS.email);
    this.identifier = fb.control({value: '', disabled: true});
    this.lastLogin = fb.control({value: '', disabled: true});
    this.role = fb.control({value: '', disabled: true});
    this.accType = fb.control({value: '', disabled: true}, Validators.required);
  }
}
