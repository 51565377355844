import { Component } from '@angular/core';

@Component({
  selector: 'app-header-maxi',
  templateUrl: './header-maxi.component.html',
  styleUrls: ['./header-maxi.component.scss'],
})
export class HeaderMaxiComponent {
  constructor() { }
}
