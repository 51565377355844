<div *ngIf="!isLoading" class="page-norights">
  <h1 class="landing">{{'norights.title' | transloco}}</h1>

  <ng-container [ngTemplateOutlet]="!this.authService.isAuthenticated() || (isMailConfirmed && isPhoneConfirmed) ? noRoles : notConfirmed" else notConfirmed>
  </ng-container>

  <!-- Подтвержденный пользователь, но нет ролей -->
  <ng-template #noRoles>
    <h5>{{'norights.input4user' | transloco}}</h5>
    <div class="text">
      <p class="mb-0 translate">{{'norights.text1' | transloco}}</p>
      <p class="translate">{{'norights.text2' | transloco}}</p>
      <div class="d-flex">
        <svg-icon key="document" class="me-3"></svg-icon>
        <a [attr.data-test]="'norights_link-instrukciya-gis-ehpd'" class="w-100 link translate text-decoration-none"
          href="https://mintrans.gov.ru/storage/app/media/instruktsiyapodobsotrudnvgruppyesiadlyadostupagisepddlya.pdf">{{'norights.textLink' |
          transloco}}</a>
      </div>
    </div>
  </ng-template>

  <!-- У пользователя не подтверждены почта/телефон -->
  <ng-template #notConfirmed>
    <h4>{{'dearUser' | transloco}}!</h4>
    <p class="translate">{{'norights.not_confirm_title' | transloco}}</p>
    <ul>
      <li *ngIf="!isPhoneConfirmed" class="translate mb-2">{{'norights.not_confirm_phone' | transloco}}</li>
      <li *ngIf="!isMailConfirmed" class="translate">{{'norights.not_confirm_email' | transloco}}</li>
    </ul>
    <p class="translate">{{'norights.not_confirm_footer' | transloco}}</p>
  </ng-template>


  <app-contacts-info></app-contacts-info>


  <button *ngIf="!isMailConfirmed || !isPhoneConfirmed" class="primary xl w-100 mt-3 logout-btn" disableRipple
    [routerLink]="['/profile']" data-test="norights_btn-logout">{{'norights.not_confirm_button' | transloco}}</button>
  <button *ngIf="isMailConfirmed && isPhoneConfirmed" class="primary xl w-100 mt-3 logout-btn" disableRipple
    [routerLink]="['/logout']" data-test="norights_btn-logout">{{'norights.button_auto' | transloco}}</button>
</div>


<div *ngIf="isLoading" class="page-norights  bg-transparent w-100">
  <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
</div>
