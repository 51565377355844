<div class="dialog-title">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <button class="close-btn" mat-dialog-close></button>
</div>

<mat-dialog-content>
  <span class="text-justify">{{data.text}}</span>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="secondary xl me-2" mat-stroked-button disableRipple mat-dialog-close>Отменить</button>
  <button class="primary xl" mat-raised-button disableRipple [mat-dialog-close]="true">Подтвердить</button>
</mat-dialog-actions>