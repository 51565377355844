import { Component, OnInit , Inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';


@Component({
  selector: 'RefusalOfRegistration',
  templateUrl: './RefusalOfRegistration.component.html',
  styleUrls: ['./RefusalOfRegistration.component.scss']
})
export class RefusalOfRegistrationComponent implements OnInit {
  private readonly _matDialogRef: MatDialogRef<RefusalOfRegistrationComponent>;
  public textAreaForm: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<RefusalOfRegistrationComponent>,private fb : UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: string){
    this._matDialogRef = dialogRef;
    this.textAreaForm = fb.group({
      textArea: ""
    });
  }

  ngOnInit(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '976px';
    this._matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
  }

  sendMail(){}

}