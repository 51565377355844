export interface IAppsettingsConfig {
  backendUrl: string;
  isProduction: boolean;
  enableServiceWorker: boolean;
  // Возможные варианты темы: blue/grey
  theme: string,
  customization: CustomizationConfig;
  // количество ретраев на получение данных с госуслуг
  esiaRetryMaxCount: number;
  // Количество раз, сколько можно ввести неправильный пароль
  inputWrongPasswordMaxCount: number;
  // Количество раз, сколько можно ввести неправильную капчу
  inputWrongCaptchaMaxCount: number;
  // Время, в течение которого нужно показывать пользователю капчу (мин)
  captchaLoginExpire: number,
  // доступные языки интерфейс IPropertiesByLang(с переменными зависимыми от языка)
  availableLangs: any;
  // доступные типы Иностранцев "PERSON","ORGANIZATION","PERSONRUS","ORGANIZATIONRUS"
  availableStrangerTypes: string[];
  //время жизни сессии, в течении которого можно позвонить пока телефон не устарел(по умолчанию 120 секунд)
  flashcallExpireSeconds: number;
}
export class AppsettingsConfig implements IAppsettingsConfig {
  backendUrl: string = '';
  isProduction: boolean = false;
  enableServiceWorker: boolean = false;
  // Возможные варианты темы: blue/grey
  theme: string = 'blue';
  customization: CustomizationConfig = {} as CustomizationConfig;

  // количество ретраев на получение данных с госуслуг
  esiaRetryMaxCount: number = 3;
  // Количество раз, сколько можно ввести неправильный пароль
  inputWrongPasswordMaxCount: number = 3;
  // Количество раз, сколько можно ввести неправильную капчу
  inputWrongCaptchaMaxCount: number = 3;
  // Время, в течение которого нужно показывать пользователю капчу (мин)
  captchaLoginExpire: number = 480
  // доступные языки интерфейс IPropertiesByLang(с переменными зависимыми от языка)
  availableLangs: any = {};
  // доступные типы Иностранцев "PERSON","ORGANIZATION","PERSONRUS","ORGANIZATIONRUS"
  availableStrangerTypes: string[] = [];
  //время жизни сессии, в течении которого можно позвонить пока телефон не устарел(по умолчанию 120 секунд)
  flashcallExpireSeconds: number = 120;
}
export interface CustomizationConfig {
  // Скрывать на странице входа логин и пароль(мнемоники ИС)
  showForeignSignInPage_ClientMnemonics: string[];
  // Скрывать на странице входа кнопку войти через Госуслуги(мнемоники ИС)
  showEsiaSignInPage_ClientMnemonics: string[];
  // Показывать кнопку смены языка Ru/En (мнемоники ИС)
  showLocalization_ClientMnemonics: string[];
  // В футере отображать кнопку "Вход для специалистов"(мнемоники ИС)
  showSpecialistSignInPage_ClientMnemonics: string[];
  // Если не авторизованы - редиректить на посторонний сайт(по умолчанию на страницу входа на шлюзе)
  needRedirectOutsideUrlWhenUnauthorized_RedirectUrl: string;
}
