import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from '@angular/core';


@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = 'Записей на странице: ';
    this.nextPageLabel = 'Вперед';
    this.previousPageLabel = 'Назад';
    this.firstPageLabel = 'Первая страница';
    this.lastPageLabel = 'Последняя страница';
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    length = Math.max(length, 0);

    if (length === 0 || pageSize === 0) {
      return `0 из ${length}`;
    }

    if (pageSize === length) {
      return `${pageSize} из ${length}`;
    }

    const startIndex = page * pageSize;
    const endIndex = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} из ${length}`;
  }
}
