import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../modules/client-config/services/config.service";
import {LocalizeContacts} from "../../core/shared/localize-config";

@Component({
  selector: 'app-server-error-page',
  templateUrl: './server-error-page.component.html',
  styleUrls: ['./server-error-page.component.scss']
})

export class ServerErrorPageComponent implements OnInit {
  contacts: LocalizeContacts;
  code = "";
  error = "";
  message = "";
  errorDict = [
    {id: "404", error: 'Ресурс не найден/Not Found', message: 'Документ не существует. Возможно некорректная ссылка.'},
    {id: "410", error: 'Ресурс недоступен/Gone', message: 'Авторизационный ключ "oAuthKey" просрочен!. Временно нет доступа к ресурсу.'},
    {
      id: "500",
      error: 'Внутренняя ошибка сервера',
      message: 'Сервер столкнулся с непредвиденным условием, которое не позволяет ему выполнить запрос.'
    },
    {
      id: "503",
      error: 'Служба недоступна/Service Unavailable',
      message: 'Возникла ошибка из-за временной перегрузки или отключения на техническое обслуживание сервера.'
    },
    {
      id: "504",
      error: 'Время прохождения через межсетевой шлюз истекло/Gateway Timeout',
      message: 'Время прохождения через межсетевой шлюз истекло'
    }
  ];

  constructor(
    public configService: ConfigService,
    private route: ActivatedRoute,
  ) {
    this.contacts = configService.localizeContacts;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: string; }) => {
      this.code = params['code'];
      this.error = params['error'];
      this.message = params['message'];

      if (!!this.code && !this.error) {
        this.error = this.errorDict.find(x => x.id == this.code)?.error || "";
      }
      if (!!this.code && !this.message) {
        this.message = this.errorDict.find(x => x.id == this.code)?.message || "";
      }
    });
  }

}
