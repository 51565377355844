import { Component } from '@angular/core';
import { LocalizeContacts } from 'src/app/core/shared/localize-config';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';

@Component({
  selector: 'app-contacts-info',
  templateUrl: './contacts-info.component.html',
  styleUrls: ['./contacts-info.component.scss']
})
export class ContactsInfoComponent {
  contacts: LocalizeContacts;

  constructor(
    configService: ConfigService
  ) {
    this.contacts = configService.localizeContacts;
  }
}
