import {AbstractControl, ValidationErrors, Validators} from "@angular/forms";

export const emailRuRegexp = /^[\w._-]*[+]?[\w._-]+@[а-яА-Я\w._-]+\.([('р')]+[('ф')]|[('r')]+[('u')])$/;
export default class CustomValidators {
  static snilsLen(control: AbstractControl): ValidationErrors | null {
    return snilsLenValidator(control);
  }

  static ogrnLen(control: AbstractControl): ValidationErrors | null {
    return ogrnLenValidator(control);
  }

  static commonInnLen(control: AbstractControl): ValidationErrors | null {
    return commonInnLenValidator(control);
  }

  static passportSeriesLen(control: AbstractControl): ValidationErrors | null {
    return passportSeriesLenValidator(control);
  }

  static passportNumLen(control: AbstractControl): ValidationErrors | null {
    return passportNumLenValidator(control);
  }

  static passportCodeLen(control: AbstractControl): ValidationErrors | null {
    return passportCodeLenValidator(control);
  }

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    return emailRuValidator(control);
  }

  static mobPhoneLen(control: AbstractControl): ValidationErrors | null {
    return mobPhoneLenValidator(control);
  }

  static pwd(control: AbstractControl): ValidationErrors | null {
    return pwdValidator(control);
  }

  // (?=.*[0-9]) - строка содержит хотя бы одно число;
  // (?=.*[!@#$%^&*]) - строка содержит хотя бы один спецсимвол;
  // (?=.*[a-z]) - строка содержит хотя бы одну латинскую букву в нижнем регистре;
  // (?=.*[A-Z]) - строка содержит хотя бы одну латинскую букву в верхнем регистре;

  static pwdLength(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) return null;

    if(control.value.length < 7) {
      return {'pwdLowercase': {'error': 'Минимальная длина строки 8 символов'}};
    }
    if(control.value.length > 32){
      return {'pwdLowercase': {'error': 'Максимальная длина строки 32 символа'}};
    }
    return null;
  }
  static pwdNumbers(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) return null;

    var regexp = new RegExp("(?=.*[0-9])");
    let res = regexp.test(control.value);
    return res ? null : {'pwdLowercase': {'error' : 'Строка должна содержать хотя бы одно число'}};
  }
  static pwdLowercase(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) return null;

    var regexp = new RegExp("(?=.*[a-z])");
    let res = regexp.test(control.value);
    return res ? null : {'pwdLowercase': {'error' : 'Строка должна содержать хотя бы одну латинскую букву в нижнем регистре'}};
  }
  static pwdUppercase(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) return null;

    var regexp = new RegExp("(?=.*[A-Z])");
    let res = regexp.test(control.value);
    return res ? null : {'pwdLowercase': {'error' : 'Строка должна содержать хотя бы одну латинскую букву в верхнем регистре'}};
  }
  static pwdSymbol(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) return null;

    var regexp = new RegExp("(?=.*[!@#$%^&*])");
    let res = regexp.test(control.value);
    return res ? null : {'pwdLowercase': {'error' : 'Строка должна содержать хотя бы один спецсимвол'}};
  }
  static onlyNum(control: AbstractControl): ValidationErrors | null {
    return Validators.pattern("^[0-9]*$")(control);
  }

  static personInnLen(control: AbstractControl): ValidationErrors | null {
    return personInnLenValidator(control);
  }

  static legalEntityInnLen(control: AbstractControl): ValidationErrors | null {
    return legalEntityInnLenValidator(control);
  }

  static kppLen(control: AbstractControl): ValidationErrors | null {
    return kppLenValidator(control);
  }
}

export function personInnLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredInnLen = 12;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredInnLen ? null : {'innLen': {requiredInnLen, 'actualLength': control.value.length}};
}

export function legalEntityInnLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredInnLen = 10;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredInnLen ? null : {'innLen': {requiredInnLen, 'actualLength': control.value.length}};
}

export function commonInnLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLegalEntityInnLen = 10;
  const requiredIndividualInnLen = 12;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredLegalEntityInnLen || control.value.length === requiredIndividualInnLen ? null : {'innLen': {requiredLegalEntityInnLen, requiredIndividualInnLen, 'actualLength': control.value.length}};
}
export function passportSeriesLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 4
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredLength ? null : {'passportSeriesLen': {requiredLength, 'actualLength': control.value.length}};
}

export function passportNumLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 6
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredLength ? null : {'passportNumLen': {requiredLength, 'actualLength': control.value.length}};
}

export function passportCodeLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 6
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredLength ? null : {'passportCodeLen': {requiredLength, 'actualLength': control.value.length}};
}

export function snilsLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 11
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === requiredLength ? null : {'snilsLen': {requiredLength, 'actualLength': control.value.length}};
}

export function emailRuValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  return emailRuRegexp.test(control.value) ? null : {emailRu: true};
}

export function ogrnLenValidator(control: AbstractControl): ValidationErrors|null {
  const ogrnRequiredLen = 13;
  const ogrnipRequiredLen = 15;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === ogrnRequiredLen || control.value.length === ogrnipRequiredLen  ? null : {'ogrnLen': {ogrnRequiredLen, ogrnipRequiredLen, 'actualLength': control.value.length}};
}

export function pwd2Validator(control: AbstractControl): ValidationErrors | null {
  const pwd = control.get('pwd');
  const confirmPwd = control.get('verifyPwd');
  return pwd?.value === confirmPwd?.value ? null : { passwordsNotMatch: true };
}
export function pwdValidator(control: AbstractControl): ValidationErrors | null {
  const pwd = control.get('password');
  const confirmPwd = control.get('verifyPwd');
  return pwd?.value === confirmPwd?.value ? null : { passwordsNotMatch: true };
}
export function mobPhoneLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 10;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === 10 ? null : {'mobPhoneLen': {requiredLength, 'actualLength': control.value.length}};
}

export function kppLenValidator(control: AbstractControl): ValidationErrors|null {
  const requiredLength = 9;
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return control.value.length === 9 ? null : {'kppLen': {requiredLength, 'actualLength': control.value.length}};
}

function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}
