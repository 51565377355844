import { Component, Inject, OnInit } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'delete-record-dialog',
  templateUrl: './deleteRecordModal.component.html',
  styleUrls: ['./deleteRecordModal.component.scss']
})


export class DeleteRecordModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, text: string}) { }

  ngOnInit(): void {
  }

}