import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import { POINTED_DATE_FORMATS } from '../../core/models/pointed-date-formats.const';
import {AuthService} from "../../modules/auth/services/auth.service";
import 'moment/locale/ru';

@Component({
  selector: 'app-profileMain',
  templateUrl: './profileMain.component.html',
  styleUrls: ['./profileMain.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: POINTED_DATE_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru'
    }
  ],
})
export class ProfileMainComponent implements AfterViewInit {
  constructor(private authService: AuthService) {
    
  }

  ngAfterViewInit() { 
    this.isStranger();
  }
  
  isStranger(): boolean{
    let showStranger = this.authService.GetUserAccountType() == "stranger";
    return showStranger;
  }


}
