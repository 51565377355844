import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NorightsComponent } from './norights/norights.component';
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import { TranslocoRootModule } from './../../transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import { IconsLibraryModule } from '@platform/platform-zit-material';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    TranslocoRootModule,
    SharedModule,
    MatProgressSpinnerModule,
    IconsLibraryModule
  ],
  exports: [
    NorightsComponent
  ],
  declarations: [
    NorightsComponent
  ]
})
export class AuthConfigModule {}
