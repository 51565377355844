<div class="container-page">

  <div class="row g-0">
    <h3 class="mt-5 text-center fw-bolder">
      Выход из информационной системы
    </h3>
    <div *ngIf="isLoading" class="col-12">
      <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
    </div>
  </div>
</div>