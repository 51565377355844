import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../modules/client-config/services/config.service";
import {LocalizeContacts} from "../../core/shared/localize-config";
import { OAuthService } from 'src/app/modules/auth/services/oauth.service';

@Component({
  selector: 'app-esia-error-page',
  templateUrl: './esia-error-page.component.html',
  styleUrls: ['./esia-error-page.component.scss']
})

export class EsiaErrorPageComponent implements OnInit {
  contacts: LocalizeContacts;
  message = "";

  constructor(
    public configService: ConfigService,
    private route: ActivatedRoute,
    public oauthService: OAuthService,
  ) {
    this.contacts = configService.localizeContacts;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: string; }) => {
      this.message = params['message'];
    });
  }

}
