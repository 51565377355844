import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import IFileMeta from '../models/card/IFileMeta';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  backendUrl;

  constructor(
    private http: HttpClient,
    private appsettings: AppsettingsConfig
  )
  {
    this.backendUrl = this.appsettings.backendUrl;
  }

  uploadFile(model: FormData): Observable<number> {
    return this.http.post<number>(`${this.backendUrl}/documents/upload`, model);
  }

  deleteFile(fileId: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.backendUrl}/documents/delete`, fileId);
  }

  getFilesMeta(): Observable<IFileMeta[]> {
    return this.http.get<IFileMeta[]>(`${this.backendUrl}/documents/list`);
  }

  getFile(fileId: number) {
    let params = new HttpParams();
    params = params.append("fileId", fileId);
    return this.http.get(`${this.backendUrl}/documents/download`, {
      params,
      observe: 'response',
      responseType: "blob"
    });
  }

}
