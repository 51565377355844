import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  isLoading = true;

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const queryParamMap = this.route.snapshot.queryParamMap;
    let redirectUri = queryParamMap.get('redirect_uri');

    if(!redirectUri)
      redirectUri = '';

    this.logout(redirectUri);
  }

  // Выход из ИС
  logout(redirectUri = '') {
    this.authService.logout(redirectUri);
  }
}
