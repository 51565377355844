<div class="container-page">

  <div class="row g-0">
    <h3 class="mt-5 text-center fw-bolder">
      Идет выход из информационных систем
    </h3>
    <p class="text-center mb-0">Это может занять некоторое время</p>
    <p class="text-center">Не закрывайте страницу</p>

    <div *ngIf="!isLoading" class="col-12">
      <div class="m-auto mt-5" style="max-width: 640px;">
          <div *ngFor='let url of urls; let i = index;' class="m-2">
            <mat-spinner *ngIf="!url.isLogouted" [diameter]="19" class="me-3 d-inline-block"></mat-spinner>
            <mat-icon *ngIf="!!url.isLogouted" svgIcon="custom-icon-success" class="me-3"></mat-icon>
            {{url.fullName}}
          </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="col-12">
      <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
    </div>
  </div>
</div>
