import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ActivateAccountErrorComponent } from '../components/activate-account-error/activate-account-error.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import IAuthorizationLoginResponse from '../models/response/IAuthorizationLoginResponse';
import { AppsettingsConfig } from '../../client-config/models/client-config';
import { DNTCaptchaBase } from '../components/capture/dnt-captcha/dnt-captcha-base';
import { SharedService } from 'src/app/core/services/shared.service';

export interface callbackError {
  (errMessage: string): void;
}

export interface callbackSuccess {
  (res: IAuthorizationLoginResponse): void;
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private appsettings: AppsettingsConfig,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  getAuthorizeFornData(username: string, password: string, isStranger: boolean | undefined, oauthKey: string, clientCode: string, captcha?: DNTCaptchaBase) {
    var formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('isStranger', (isStranger ?? false).toString());
    formData.append('oauthKey', oauthKey);
    formData.append('clientCode', clientCode);

    if (!!captcha) {
      formData.append('DNTCaptchaText', captcha.DNTCaptchaText);
      formData.append('DNTCaptchaToken', captcha.DNTCaptchaToken);
      formData.append('DNTCaptchaInputText', captcha.DNTCaptchaInputText);
    }

    return formData;
  }

  /**
   * Если авторизованы(пароль и логин не важны), редиректим пользователя по oAuth дальше на ИС(если ключ не просрочился), иначе редиректим на главную страницу шлюза
   * @returns пользователь был автоматически авторизован?
   */
  autoLoginAndRedirectAuthorizedUser(): Observable<boolean> {
    const oAuthKey = this.route.snapshot.queryParamMap.get('OauthKey');
    if (!!oAuthKey && this.authService.isAuthenticated()) {
      let isStranger: boolean | undefined;

      return this.authService
        .authorizeOauth(this.getAuthorizeFornData('', '', isStranger, oAuthKey, this.route.snapshot.queryParams?.client))
        .pipe(
          map((res: IAuthorizationLoginResponse) => {
            if (!!res.redirectUrl) {
              window.location.href = res.redirectUrl;
            } else {
              throw Error('Не вернулся урл для редиректа');
            }
            return true;
          }),
          catchError(() => {
            return of(false);
          })
        );
    }
    return of(false);
  }

  /**
   * Нажали войти через ЕСИА, редиректим на госуслуги получение авторизационного кода
   */
  redirectToEsiaAuthCodePage() {
    const oAuthKey = this.route.snapshot.queryParamMap.get('OauthKey') ?? '';
    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    this.authService.getEsiaUrl(oAuthKey, client).subscribe((res) => {
      if (!!res.esiaUrl) {
        window.location.href = res.esiaUrl;
      } else {
        throw Error(
          'Url на получение авторизационного кода госулуг не вернулся с бэка'
        );
      }
    });
  }

  //
  /**
   * Если пришла ошибка, при подтверждении учётки по почте, открыть диалог с её выводом
   * @returns условие, что ошибка реально пришла с бэка
   */
  openAccountConfirmErrorDialog(): boolean {
    const isConfirmError =
      this.route.snapshot.queryParamMap.get('confirm_error') == 'true';
    if (isConfirmError) {
      this.dialog.open(ActivateAccountErrorComponent, {
        scrollStrategy: new NoopScrollStrategy(),
        position: {
          top: '196px',
        },
      });
    }
    return isConfirmError;
  }

  /**
   * Если в урле пришла ссылка на логаут, редиректим на логаут
   */
  redirectIfComeFromLogout() {
    const logoutRedirectUri = this.route.snapshot.queryParamMap.get(
      'logout_redirect_url'
    );
    if (!!logoutRedirectUri && logoutRedirectUri.indexOf('auth/signin') == -1) {
      window.location.href = logoutRedirectUri;
      return true;
    }
    return false;
  }

  /**
   * Авторизуемся и если по oAuth, то редиректим на ИС
   * @param username почта - логин пользователя
   * @param password пароль пользователя
   * @param errCallback если не смогли войти, выполнить эту функцию
   */
  loginSpecialist(
    username: string,
    password: string,
    isStranger: boolean,
    captcha: DNTCaptchaBase,
    successCallback: callbackSuccess,
    errCallback: callbackError
  ) {
    let oauthKey = this.route.snapshot.queryParams?.OauthKey;
    let loginSub = !!oauthKey
      ? this.authService.authorizeOauth(this.getAuthorizeFornData(username, password, isStranger, oauthKey, this.route.snapshot.queryParams?.client, captcha))
      : this.authService.authorizeLocal(username, password, isStranger, this.route.snapshot.queryParams?.client);
    loginSub.subscribe(
      (res: IAuthorizationLoginResponse) => {
        successCallback(res);
        window.location.href = res.redirectUrl ?? '/';
      },
      (_err) => errCallback(this.sharedService.buildErrors(_err.error))
    );
  }
  /**
   * Если не авторизованы и не по oauth(пока не знаем ИС) и задана ссылка на внешний сайт для входа пользователя, то редиректим
   */
  checkNeedRedirectOutsideUrlWhenUnauthorized(): void {
    const oAuthKey = this.route.snapshot.queryParamMap.get('OauthKey');
    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    let redirectUrl =
      this.appsettings.customization
        .needRedirectOutsideUrlWhenUnauthorized_RedirectUrl;

    if (redirectUrl != '' && !this.authService.isAuthenticated()) {
      if ((!oAuthKey || client == '' || !this.checkClientCanSingIn())
      ) {
        window.location.href = redirectUrl;
      }
    }
  }
  /**
   * Проверить, что ИС имеет хотя бы один вид авторизаций
   */
  public checkClientCanSingIn(): boolean {
    let client = this.route.snapshot.queryParamMap.get('client') ?? '';
    if (this.isClientCanSpecialistSingIn(client)
      || this.isClientCanForeignSingIn(client)
      || this.isClientCanESIASingIn(client)
    ) {
      return true;
    }
    return false;
  }
  /**
   * Показывать на странице входа: блок логин и пароль(используется для специалиста/иностранца+русского)
   */
  public showLoginPasswordBlock() {
    // если специалист, то показывать
    if (this.isSpecialist()) return true;

    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    return this.isClientCanForeignSingIn(client);
  }
  /**
   * Показывать на странице входа: кнопку войти через Госуслуги(если выбрали вход для специалиста, то скрываем кнопку)
   */
  public showEsiaButtonBlock() {
    if (this.isSpecialist()) return false;

    const client = this.route.snapshot.queryParamMap.get('client') ?? '';
    return this.isClientCanESIASingIn(client);
  }
  /**
   * Текущий пользователь специалист(а не иностранец на странице входа)
   */
  public isSpecialist() {
    const isSpecialist = this.route.snapshot.queryParamMap.get('is_specialist') ?? '';
    return isSpecialist == 'true';// TODO && isClientCanSpecialistSingIn(client)
  }
  /**
   * Проверить, что для входа в ИС доступен иностранцу(и русскому) вход по логину и паролю
   */
  public isClientCanForeignSingIn(client: string): boolean {
    return (
      this.appsettings.customization.showForeignSignInPage_ClientMnemonics.lastIndexOf(
        client
      ) != -1
    );
  }
  /**
   * Проверить, что для входа в ИС доступен вход через Госуслуги
   */
  public isClientCanESIASingIn(client: string): boolean {
    return (
      this.appsettings.customization.showEsiaSignInPage_ClientMnemonics.lastIndexOf(
        client
      ) != -1
    );
  }
  /**
  * Проверить, что для входа в ИС доступен специалисту вход по логину и паролю
  */
  public isClientCanSpecialistSingIn(client: string): boolean {
    return (
      this.appsettings.customization.showSpecialistSignInPage_ClientMnemonics.lastIndexOf(
        client
      ) != -1
    );
  }
}
