export enum StrangersType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
  PERSONRUS = 'PERSONRUS',
  ORGANIZATIONRUS = 'ORGANIZATIONRUS'
}
export enum StrangersOwnerType {
  PERSON = 'PERSON',
  APPLICANT = 'APPLICANT',
  LEGAL = 'LEGAL',
}