import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CookiesService, LANGUAGE_COOKIE } from '../../../../../core/services/cookies.service';
import { I18nService } from 'src/app/modules/auth/services/i18n.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-i18n',
  templateUrl: './i18n.component.html',
  styleUrls: ['./i18n.component.scss'],
})
export class I18nComponent {
  isVisible: boolean;
  constructor(
    private translocoService: TranslocoService,
    private cookieService: CookiesService,
    private i18nService: I18nService,
    router: Router,
  ) {
    this.initLanguage();

    this.isVisible = this.i18nService.canShowLocalizationButton();
   
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe(event => {
      this.isVisible = this.i18nService.canShowLocalizationButton();
      this.checkForResetLanguage((event as NavigationEnd).url);
    });
  }

  initLanguage() {
    let cookieLang = this.cookieService.getCookie(LANGUAGE_COOKIE);
    if (!cookieLang) {
      this.cookieService.saveCookie(LANGUAGE_COOKIE, this.activeLanguage());
    }
    else {
      this.translocoService.setActiveLang(cookieLang);
    }
  }

  // если на странице не нужен язык, сбросим на русский
  checkForResetLanguage(currentUrl: string) {
    if (
      currentUrl == '/auth/registration' ||
      currentUrl.indexOf('/auth/specialists-signin') != -1
    ) {
      this.setActiveLanguage('ru')
    }
  }

  activeLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  setActiveLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
    this.cookieService.saveCookie(LANGUAGE_COOKIE, lang);
  }

  getAvailableLangs(): string[] {
    return this.translocoService.getAvailableLangs() as string[];
  }
}
