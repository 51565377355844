<div mat-dialog-title>
  <h2>{{data.title}}</h2>
  <button (click)="onCloseClick(false)" class="close-btn ms-auto" data-test="ok-cancel-dialog_btn-app-close"></button>
</div>

<div mat-dialog-content class="p-3">
  <span class="mainText">{{data.text}}</span>
</div>

<div mat-dialog-content class="p-3">
  <button (click)="onCloseClick(true)" data-test="ok-cancel-dialog_btn-confirm" class="primary me-3 ms-auto">
    {{'confirm' | transloco}}</button>
  <button class="secondary" (click)="onCloseClick(false)"  mat-stroked-button disableRipple
    data-test="ok-cancel-dialog_btn-cancel">{{'cancel' | transloco}}</button>
</div>
