/**
 * Enum кодов для кастомизации функционала и интерфейса проекта. См класс @Customization
 */
export enum CodeEnum {
  /**
   * Добавление организации в профиле пользователя
   */
  ADD_ORG = 'add-org',
  /**
   * Удаление организации в профиле пользователя
   */
  DEL_ORG = 'del-org',
  /**
   * Редактирование организации в профиле пользователя
   */
  EDIT_ORG = 'edit-org',
  /**
   * Добавления документа в профиле пользователя
   */
  ADD_DOC = 'add-doc',
  /**
   * Удаления документа в профиле пользователя
   */
  DEL_DOC = 'del-doc',
  /**
   * Отображать раздел Документы в профиле пользователя
   */
  DOC = 'doc',
  /**
   * Отображать раздел Транспорт в профиле пользователя
   */
  VEHICLES = 'vehicles',
  /**
   * Отображать раздел Транспорт в профиле иностранного пользователя
   */
  FOREIGN_VEHICLES = 'foreign-vehicles',
  /**
   * Отображать страницу Срок действия ссылки на активацию учетной записи истек
   */
  MAIL_EXPIRED = 'mail-expired',
  /**
   * Отображать страницу Регистрации специалиста
   */
  REG_SPECIALIST = 'reg-specialist',
  /**
   * Отображать страницу Регистрации иностранца/русского
   */
  REG_FOREIGN = 'reg-foreign',
  /**
   * Отображать страницу информационного окна 'Аккаунт создан', которая открывается после успешной регистрации
   */
  REG_SUCCESS = 'reg-success',
  /**
   * Отображать страницу 'Восстановление пароля'
   */
  RESTORE_PWD = 'restore-pwd',
  /**
   * Отображать страницу 'Ссылка на восстановление пароля устарела'
   */
  RESTORE_PWD_EXPIRED = 'restore-pwd-expired',
  /**
   * Подтверждение иностранца проводить локально в админке, а не через очереди с внешней интеграцией
   */
  FOREIGN_LOCAL = 'foreign-local',
  /**
   * Включать раздел 'Персональных данных' у Иностранной организации
   */
  FOREIGN_ORG_PERSONAL = 'foreign-org-personal',
  /**
   * Включать 'Налоговый номер'(ИНН) у Иностранной организации
   */
  FOREIGN_ORG_INN = 'foreign-org-inn',
  /**
   * Включать 'Налоговый номер'(ИНН) у Иностранной организации
   */
  FOREIGN_PERSONAL_INN = 'foreign-personal-inn',
  /**
   * Включать 'Руководитель'(Признак первого лица) у Иностранной организации
   */
  FOREIGN_ORG_CHIEF = 'foreign-org-chief',
  /**
   * Включать обязательную активацию телефона
   */
  NEED_CONFIRM_PHONE = 'need-confirm-phone',
  /**
   * FlashCall. Время жизни сессии, в течении которого можно позвонить пока телефон не устарел(по умолчанию 120 секунд)
   */
  FLASHCALL_EXPIRE_SECONDS = 'flashcall-expire-seconds',
  /**
   * Тема. Возможные варианты темы: blue или grey
   */
  THEME = 'theme',
  /**
   * Пропускать на ИС неподтвержденных пользователей
   */
  SIGNIN_UNTRUSTED_USERS_OAUTH = 'signin-untrusted-users-oauth',
  /**
   * Капча. Время жизни маркера доступа, для капчи, в минутах
   */
  CAPTCHA_TEST_TOKEN_EXPIRE_MINUTES = 'captcha-test-token-expire-minutes',
  /**
   * Капча. Количество раз, сколько можно ввести неправильную капчу на фронте
   */
  CAPTCHA_WRONG_COUNT_FRONT = 'captcha-wrong-count-front',
  /**
   * Каптча. Время, в течение которого нужно показывать пользователю капчу (минуты)
   */
  CAPTCHA_WRONG_EXPIRE_MINUTES_FRONT = 'captcha-wrong-expire-minutes-front',
  /**
   * Капча. Количество раз, сколько можно ввести неправильный пароль на фронте, после чего появится капча
   */
  PASSWORD_WRONG_COUNT_FRONT = 'password-wrong-count-front',
  /**
   * Проверка электронной почты на русский домен
   */
  EMAIL_CHECK_RU_DOMAIN = 'email-check-ru-domain',
  /**
   * Количество ретраев на получение данных с госуслуг
   */
  ESIA_RETRY_COUNT   = 'esia-retry-count',
  /**
   * Отображать колонку Кто изменил в профиле в окне История изменения статуса
   */
  STATUS_HISTORY_EDITED_BY = 'status-history-edited-by',
  /**
   * отображать страницу 'Ввода нового пароля' после подтверждения через почту
   */
  CONFIRM_PWD = 'confirm-pwd',
}
