<div class="mini-wrapper">
  <div class="sub-wrapper">
    <h1 class="landing mb-4 text-center">{{'esiaError.title' | transloco}}</h1>
    <div class="mt-3 mb-4">
      <div>{{'esiaError.description' | transloco}}</div>
    </div>



    <app-contacts-info></app-contacts-info>



    <div class="row g-3 mt-2">
      <div class="col-6">
        <button class="primary xl w-100" mat-button (click)="oauthService.redirectToEsiaAuthCodePage()">
          Попробовать ещё раз
        </button>
      </div>
      <div class="col-6">
        <button class="primary xl w-100" disableRipple mat-button [routerLink]="['/logout']">
          На главную 
        </button>
      </div>
    </div>

  </div>
</div>