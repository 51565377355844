import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-remove-doc-dialog',
  templateUrl: './remove-doc-dialog.component.html',
  styleUrls: ['./remove-doc-dialog.component.scss']
})
export class RemoveDocDialogComponent implements OnInit {
  type;
  name = '';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const {type, name} = data;
    this.type = type;
    this.name = name;
  }

  ngOnInit(): void {
  }

}
