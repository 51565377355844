import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss']
})
export class CheckStatusComponent implements OnInit {

  @Input() name = '';
  @Input() data: any;
  isNotVerified = true;
  text = '';

  constructor() { }

  ngOnInit(): void {
    const obj = this.data.variances[this.name];
    const text = obj?.val;
    this.isNotVerified = obj?.isNotVerified;
    this.text = text ? text : '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.data.isFirstChange()) {
      const data = changes.data.currentValue;
      const obj = data.variances[this.name];
      const text = obj?.val;
      this.isNotVerified = obj?.isNotVerified;
      this.text = text ? text : '';
    }
  }
}
