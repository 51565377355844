import { Component, Input } from '@angular/core';
import { AlignItems } from '../../sharedTypes/alignItems';

@Component({
  selector: 'app-flex-item',
  templateUrl: './flex-item.component.html',
  styleUrls: ['./flex-item.component.scss']
})
export class FlexItemComponent {
  @Input() basis = 'auto';
  @Input() grow = 0;
  @Input() shrink = 0;
  @Input() height = 'auto';

  @Input() isAlignSelfStart = false;
  @Input() isAlignSelfEnd = false;
  @Input() isAlignSelfCenter = false;
  @Input() isAlignSelfStretch = false;
  @Input() isAlignSelfBaseline = false;

  get alignSelf(): AlignItems {
    return this.isAlignSelfStart
      ? AlignItems.start
      : this.isAlignSelfEnd
      ? AlignItems.end
      : this.isAlignSelfCenter
      ? AlignItems.center
      : this.isAlignSelfBaseline
      ? AlignItems.baseline
      : AlignItems.stretch;
  }
  get classes(): string[] {
    const result: Array<string | null> = ['flex-item'];
    result.push(
      this.alignSelf === AlignItems.start
        ? 'align-self-start'
        : this.alignSelf === AlignItems.end
        ? 'align-self-end'
        : this.alignSelf === AlignItems.center
        ? 'align-self-center'
        : this.alignSelf === AlignItems.baseline
        ? 'align-self-baseline'
        : this.alignSelf === AlignItems.stretch
        ? 'align-self-stretch'
        : null
    );
    return result.filter((e) => e != null) as string[];
  }

  get style(): Record<string, string | number> {
    return {
      'height': this.height,
      'flex-basis': this.basis,
      'flex-grow': this.grow,
      'flex-shrink': this.shrink
    };
  }
}
