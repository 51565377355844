import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { OAuthService } from '../../services/oauth.service';
import { CookiesService, ESIARETRY_COUNT_COOKIE } from 'src/app/core/services/cookies.service';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';

@Component({
  selector: 'app-esia-token-and-scope',
  templateUrl: './esia-token-and-scope.component.html',
  styleUrls: ['./esia-token-and-scope.component.scss']
})
export class EsiaTokenAndScopeComponent implements OnInit {
  isLoading = true;
  code = "";
  state = "";
  client = "";
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public oauthService: OAuthService,
    private cookieService: CookiesService,
    private router: Router,
    private configService: ConfigService) {
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: string; }) => {
      this.code = params['code'];
      this.state = params['state'];
      this.client = params['client'];
      this.getUserTokenAndScope();
    });
  }


  getUserTokenAndScope() {
    this.isLoading = true;
    this.authService.getUserTokenAndScope(this.code, this.state, this.client)
      .subscribe(response => {
        if (response.statusCode == 200) {
          window.location.href = response.url;
        }
        else if (response.statusCode == 777 && !!this.state) {
          if (!this.isSendRetry()) {
            this.router.navigate(['/esia-error'], { queryParamsHandling: 'merge', queryParams: { OauthKey: this.state } });
          }
        }
        else {
          const params: NavigationExtras = {
            queryParams: {
              code: response.statusCode,
              error: response.error,
              message: response.message
            },
          };
          this.router.navigate(['/server-error'], params);
        }

        this.isLoading = false;
      });
  }

  // Если включен ретрай, для повторных попыток получения данных с госуслуг,
  // то делаем повторный запрос на Госуслуги
  // Попытки сохраняем в куках. При удаче и при исчерпывании, скидываем попытки.
  isSendRetry(): boolean {
    var retryCount = this.cookieService.getCookie(ESIARETRY_COUNT_COOKIE) ?? 0;
    var esiaRetryMaxCount = this.configService.customization.getOptionNumber(CodeEnum.ESIA_RETRY_COUNT);

    if (+retryCount < esiaRetryMaxCount) {
      //TODO: логгировать посылку ретрая!
      // увеличиваем счётчик попыток
      this.cookieService.saveCookie(ESIARETRY_COUNT_COOKIE, (+retryCount + 1).toString());
      // пробуем повторно получить данные с госусулуг
      this.oauthService.redirectToEsiaAuthCodePage();
      return true;
    }

    // сбрасываем счётчик
    this.cookieService.removeCookie(ESIARETRY_COUNT_COOKIE);
    return false;
  }

}
