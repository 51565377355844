import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';
import { AppsettingsConfig } from '../../client-config/models/client-config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private appsettings: AppsettingsConfig,
    private router: Router,
    private location: Location
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated()) {
      if (this.location.path().indexOf('/systemRedirect') >= 0 &&
        this.appsettings.customization.needRedirectOutsideUrlWhenUnauthorized_RedirectUrl.indexOf('/systemRedirect') >= 0) {
        return true;
      }
      else if (
        this.location.path().indexOf('/auth/signin') === -1 &&
        this.location.path().indexOf('/specialists-signin') === -1
      ) {
        this.router.navigate(['/auth/signin']);
      }

      return false;
    }

    return true;
  }
}
