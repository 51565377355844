import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import CustomValidators from "../custom-validators.model";
import {IdentityDocumentTypeEnum} from "./identity-document-type.enum";
import IdentityDocumentTypeConverter from "./identity-document-type.converter";

export default class IdentityDocument {
  docType = new UntypedFormGroup({
    id: new UntypedFormControl(IdentityDocumentTypeEnum.RussianPassport),
    name: new UntypedFormControl({
      value: IdentityDocumentTypeConverter.convert(IdentityDocumentTypeEnum.RussianPassport),
      disabled: true
    })
  }, Validators.required)
  series = ['', [Validators.required, CustomValidators.onlyNum, CustomValidators.passportSeriesLen]]
  number = ['', [Validators.required, CustomValidators.onlyNum, CustomValidators.passportNumLen]]
  issuedBy = ['', Validators.required]
  issueDate = ['', Validators.required]
  issueId = ['', [Validators.required, CustomValidators.onlyNum, CustomValidators.passportCodeLen]]
  birthplace = ['', Validators.required]
  citizenship = ['', Validators.required]
}
