<div class="dialog-title">
  <h2>{{title}}</h2>
  <button data-test="addOrganization2User_btn-close" class="close-btn" mat-dialog-close></button>
</div>

<div *ngIf="isShowNotification" [ngClass]="isSuccess ? 'notification success' : 'notification error'">
  <svg-icon [key]="isSuccess ? 'statusOk':'errors'"></svg-icon>
  <div class="body">{{message}}</div>
  <a (click)="isShowNotification=false;"><svg-icon key="greyClose"></svg-icon></a>
</div>

<mat-dialog-content>
  <div class="row g-3 w-100">

    <div class="d-flex flex-row">
      <mat-slide-toggle data-test="addOrganization2User-toggle-is-chief" class="w-auto b-text" [(ngModel)]="organization.chief">
        <div>Руководитель организации</div>
      </mat-slide-toggle>
      <app-zit-auto-open-menu class="ms-2 ps-0">
        <svg-icon trigger key="question" class="s"></svg-icon>
        <div content>
          <p>Сведения о том, является ли сотрудник руководителем организации</p>
        </div>
      </app-zit-auto-open-menu>
    </div>

    <mat-form-field class="col-6" appearance="fill" [class.mat-form-field-invalid]="organization.userOrgPosition==''">
      <mat-label>Должность пользователя в организации *</mat-label>
      <input data-test="addOrganization2User_input-organization-userOrgPosition" matInput
        [(ngModel)]="organization.userOrgPosition">
      <mat-hint class="mat-error" *ngIf="organization.userOrgPosition==''">{{obligatory}}</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6" appearance="fill"
      [class.mat-form-field-invalid]="organization.inn=='' || (organization.inn != '' && organization.inn.length < 10)">
      <mat-label>ИНН *</mat-label>
      <input data-test="addOrganization2User_input-organization-inn" matInput [(ngModel)]="organization.inn"
        [disabled]="filter.status?.internalCode == organizationType.VERIFIED" [matAutocomplete]="autoOrg"
        (ngModelChange)="doFilter()" (keypress)="inputFilter($event)" maxlength="10">
      <mat-autocomplete data-test="addOrganization2User_auto-inn" [panelWidth]="735" #autoOrg="matAutocomplete"
        (optionSelected)="onSelectedAutoInn($event)">
        <mat-option *ngIf="isAutocompleteLoading" class="is-loading">Загрузка...</mat-option>
        <ng-container *ngIf="!isAutocompleteLoading">
          <mat-option [attr.data-test]="'addOrganization2User_auto-inn-option-' + i"
            *ngFor="let org of organizations; let i = index" [value]="org.inn">
            <span><b>{{org.inn}}</b> {{org.fullName}}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-hint class="mat-error" *ngIf="organization.inn==''">{{obligatory}}</mat-hint>
      <mat-hint class="mat-error" *ngIf="organization.inn != '' && organization.inn.length < 10"> Введите 10
        цифр ИНН </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6" appearance="fill" [class.mat-form-field-invalid]="
      organization.phone=='' || (organization.phone !='' && organization.phone.length < 10)">
      <mat-label>Телефон пользователя в организации *</mat-label>
      <input data-test=" addOrganization2User_input-organization-phone" matInput [(ngModel)]="organization.phone"
        [mask]="MASKS.mobPhone">
      <mat-hint class="mat-error" *ngIf="organization.phone==''">{{obligatory}}</mat-hint>
      <mat-hint class="mat-error" *ngIf="organization.phone!= '' &&  organization.phone.length < 10">Введите корректный
        номер телефона</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6" appearance="fill" [class.mat-form-field-invalid]="
    organization.email =='' || (organization.email != '' && !sharedService.isEmailRegular(organization.email))">
      <mat-label>Электронная почта в организации *</mat-label>
      <input data-test="addOrganization2User_input-organization-email" matInput [(ngModel)]="organization.email">
      <mat-hint class="mat-error" *ngIf="organization.email==''">{{obligatory}}</mat-hint>
      <mat-hint class="mat-error" *ngIf="sharedService.checkRuEmailDomain && organization.email != '' && !sharedService.isEmailRegular(organization.email)">
        {{ERR_MSG.emailRu}} </mat-hint>
      <mat-hint class="mat-error" *ngIf="!sharedService.checkRuEmailDomain && organization.email != '' && !sharedService.isEmailRegular(organization.email)">
        {{ERR_MSG.email}} </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6" appearance="fill" [class.mat-form-field-invalid]="
    organization.kpp == '' || (organization.kpp !='' &&  organization.kpp.length < 9)"
      *ngIf="filter.type !== orgTypeEnum.Business && filter.type !== orgTypeEnum.LegalBranch">
      <mat-label>КПП *</mat-label>
      <input data-test="addOrganization2User_input-organization-kpp" matInput [(ngModel)]="organization.kpp"
        [mask]="MASKS.kpp" [disabled]="filter.status?.internalCode == organizationType.VERIFIED">
      <mat-hint class="mat-error" *ngIf="organization.kpp == ''">{{obligatory}}</mat-hint>
      <mat-hint class="mat-error" *ngIf="organization.kpp !='' &&  organization.kpp.length < 9"> Введите 9
        знаков КПП </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-6" appearance="fill" [class.mat-form-field-invalid]="
    organization.kppBranch == '' || (organization.kppBranch !='' &&  organization.kppBranch.length < 9)"
      *ngIf="filter.type === orgTypeEnum.LegalBranch">
      <mat-label>КПП филиала *</mat-label>
      <input data-test="addOrganization2User_input-organization-kppBranch" matInput [(ngModel)]="organization.kppBranch"
        [mask]="MASKS.kpp" [disabled]="filter.status?.internalCode == organizationType.VERIFIED">
      <mat-hint class="mat-error" *ngIf="organization.kppBranch==''">{{obligatory}}</mat-hint>
      <mat-hint class="mat-error" *ngIf="organization.kppBranch != '' && organization.kppBranch.length < 9"> Введите
        9 знаков КПП филиала </mat-hint>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button data-test="addOrganization2User_btn-cancel" class="secondary me-3" mat-stroked-button disableRipple
    [mat-dialog-close]="false">Отменить</button>
  <button data-test="addOrganization2User_btn-add" class="primary" disableRipple
    (click)="onSave()">{{titleButton}}</button>
</mat-dialog-actions>
