<div class="dialog-title">
  <h4 mat-dialog-title>Вы действительно хотите удалить документ?</h4>
  <button data-test="remove-doc-dialog_btn-close" class="close-btn" mat-dialog-close></button>
</div>

<mat-dialog-content>
  <div class="mb-3">
    <div class="file-info-title">Тип документа:</div>
    <div>{{type.name}}</div>
  </div>
  <div>
    <div class="file-info-title">Название файла:</div>
    <div>{{name}}</div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button data-test="remove-doc-dialog_btn-cancel"  class="secondary xl me-2" mat-stroked-button disableRipple [mat-dialog-close]="false">Отменить</button>
  <button data-test="remove-doc-dialog_btn-confirm" class="primary xl" disableRipple [mat-dialog-close]="true">Подтвердить</button>
</mat-dialog-actions>
