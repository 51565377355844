import { NgModule } from '@angular/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './app.paginator';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [MatSelectModule, FormsModule, CommonModule, MatIconModule],
    exports: [PaginatorComponent],
    declarations: [PaginatorComponent]
})
export class PaginatorModule { }
