import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import RegistrationForms, { IRegOrganization } from '../models/registrationForms';
import { DNTCaptchaBase } from '../components/capture/dnt-captcha/dnt-captcha-base';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { CountriesResponse } from '../../../core/models/country';
import { IRegisterStrangerDTO, IResponseRegister } from '../../../core/models/RegisterStrangerRequest';
import { AppsettingsConfig } from '../../client-config/models/client-config';
import { IResponse } from 'src/app/core/models/IResponse';
import { ConfigService } from '../../client-config/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  form;
  backendUrl;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private appsettings: AppsettingsConfig,
    configService: ConfigService,
  ) {
    this.form = this.fb.group(new RegistrationForms(fb, configService));
    this.backendUrl = appsettings.backendUrl;
  }

  getAutocompleteOrgs(
    inn: number,
    orgType: OrgTypeEnum,
    maxCount: number
  ): Observable<IRegOrganization> {
    return this.http.get<IRegOrganization>(
      `${this.backendUrl}/settings/organization/autocomplete?inn=${inn}&orgType=${orgType}&count=${maxCount}`
    );
  }
  registerNew(data: FormData): Observable<any> {
    return this.http.post(`${this.backendUrl}/account/register`, data);
  }

  checkSum(userinfo: any): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post(`${this.backendUrl}/account/checkSum`, userinfo, {
      headers: headers,
      withCredentials: true,
      observe: 'response',
      /* For CORS */
    });
  }

  // соберем данные со всех форм для регистрации пользователя
  getFormData(form: UntypedFormGroup, captcha: DNTCaptchaBase): FormData {
    const rawVal = this.form.getRawValue();
    const formData = new FormData();
    formData.append(
      'RegistrationData',
      JSON.stringify({
        userInfo: {
          personalData: {
            email: rawVal.personalData.email,
            lastName: rawVal.personalData.surname,
            firstName: rawVal.personalData.name,
            middleName: rawVal.personalData.patronymic,
            gender: rawVal.personalData.gender,
            dob: rawVal.personalData.birthdate,
            mobPhone: rawVal.personalData.phoneNumber,
          },
          identityDocument: {
            ...rawVal.personalData.identityDocument,
            issueDate: rawVal.personalData.identityDocument.issueDate,
            type: rawVal.personalData.identityDocument.docType.id,
          },
          registrationAddress: {
            ...rawVal.personalData.registrationAddress,
          },
          otherDocuments: {
            inn: rawVal.personalData.inn,
            snils: rawVal.personalData.snils,
          },
        },
        Organizations: rawVal.organizations,
        serviceData: {
          ...rawVal.serviceData,
          login: rawVal.personalData.email,
        },
      })
    );
    formData.append('DNTCaptchaText', captcha.DNTCaptchaText);
    formData.append('DNTCaptchaToken', captcha.DNTCaptchaToken);
    formData.append('DNTCaptchaInputText', captcha.DNTCaptchaInputText);
    console.info(formData);
    return formData;
  }

  getCountry(): Observable<CountriesResponse[]> {
    return this.http.post<CountriesResponse[]>(`${this.backendUrl}/shared/countries/list`, null);
  }

  registrationStranger(data: FormData): Observable<IResponseRegister> {
    return this.http.post<IResponseRegister>(`${this.backendUrl}/account/stranger/register`, data);
  }

  updateStranger(data: IRegisterStrangerDTO): Observable<IResponse> {
    return this.http.post<IResponse>(`${this.backendUrl}/stranger/update`, data);
  }
}
