<footer class="footer">
  <div class="footer-links">
    <a [attr.data-test]="'app-footer_link-go-to-mintrans-gov-ru'" href="https://mintrans.gov.ru/" target="_blank">©
      2021-{{copyrightYear}} {{ 'footer.copyRights' | transloco }}</a>
    <a [attr.data-test]="'app-footer_link-footer-rules-personal'" [href]="getRulesPersonalUrl()"
      target="_blank">{{'footer.rulesPersonal'| transloco }}</a>
  </div>
  <div class="footer-links">
    <a [attr.data-test]="'app-footer_link-go-to-auth-signin'" *ngIf="showSpecialistButton()"
      [routerLink]="['/auth/signin']" [queryParams]="{is_specialist: true}" queryParamsHandling="merge">
      {{'footer.specialist' | transloco}}
    </a>
  </div>
</footer>