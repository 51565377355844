import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutWithNavComponent } from './layout-with-nav/layout-with-nav.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppFooterModule } from './app-footer/app-footer.module';
import { GridModule } from '../grid/grid.module';
import { MaterialModule } from '../material.module';
import { HeaderMaxiComponent } from './app-header/header/header-maxi.component';
import { I18nComponent } from './i18n/i18n.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { HeaderMiniComponent } from './app-header/headerMini/header-mini.component';
import { UserCardComponent } from './app-header/components/user-card/user-card.component';
import { IconsLibraryModule } from '@platform/platform-zit-material';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [LayoutWithNavComponent, HeaderMaxiComponent, I18nComponent, HeaderMiniComponent, UserCardComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    AppFooterModule,
    GridModule,
    MaterialModule,
    TranslocoRootModule,
    RouterModule,
    IconsLibraryModule,
  ],
  exports: [LayoutWithNavComponent],
})
export class LayoutModule {}
