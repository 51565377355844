import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LocalizeConfiguration,
  LocalizeContacts,
} from '../../../core/shared/localize-config';
import { AppsettingsConfig } from '../models/client-config';
import { Customization, ICustomization } from 'src/app/core/shared/customization-config';


@Injectable()
export class ConfigService {
  public localizeContacts: LocalizeContacts = {} as LocalizeContacts;
  public localizeConfig: LocalizeConfiguration = {} as LocalizeConfiguration;
  public customization: Customization = new Customization([]);


  constructor(
    private httpClient: HttpClient,
    private appsettingsConfig: AppsettingsConfig
  ) { }

  public async loadClientConfig() {
    return await this.loadLocalizeConfig();
  }

  async loadLocalizeConfig() {
    await this.httpClient
      .get<LocalizeContacts>(
        `${this.appsettingsConfig.backendUrl}/shared/localize-contacts`
      )
      .toPromise()
      .then(async (conf) => {
        this.localizeContacts = conf;
        console.log('localize-contacts', this.localizeContacts);
      });

    await this.httpClient
      .get<LocalizeConfiguration>(
        `${this.appsettingsConfig.backendUrl}/shared/localize-configuration`
      )
      .toPromise()
      .then(async (conf) => {
        this.localizeConfig = conf;
        console.log('localize', this.localizeConfig);
      });
    return await this.httpClient
      .get<ICustomization[]>(
        `${this.appsettingsConfig.backendUrl}/shared/customization-configuration`
      )
      .toPromise()
      .then(async (options) => {
        this.customization = new Customization(options);
        console.log('customization', this.customization);
      });
  }
}
