<div class="mini-wrapper">
  <div class="sub-wrapper">
    <h1>{{'confirmSuccessInfo.title' | transloco}}</h1>
    <div class="mt-3 mb-4">
      <div class="translate">{{'confirmSuccessInfo.descriptionHello' | transloco}}</div>
      <div class="translate">{{'confirmSuccessInfo.descriptionEmail' | transloco}}</div>
      <div *ngIf="needConfirmPhone" class="translate">{{'confirmSuccessInfo.descriptionPhone' | transloco}}</div>
      <div class="translate">{{'confirmSuccessInfo.descriptionConfirm' | transloco}}</div>
    </div>
    <div class="mt-2 d-flex">
      <button [routerLink]="['/logout']" class="secondary xl me-3 ms-auto" disableRipple mat-button
        data-test="confirm-success-info_btn-goto-profile">
        <svg-icon key="arrowLeft" class=""></svg-icon>
        {{'confirmSuccessInfo.goTo' | transloco}}
      </button>
    </div>
  </div>
</div>
